export class Zone {
  constructor(public id: string = '',
              public name: string = '',
              public cityId: string = null,
              public coordinates: any[] = [],
              public assigned_inspectors: any[] = []) {
  }

  assignToObject(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.coordinates = json.coordinates;

  }

  static fromJson(json: any): Zone {
    let zone: Zone;
    zone = new Zone();
    zone.assignToObject(json);
    return zone;
  }


  static fromJsonArray(json: any): Zone[] {
    console.log(json)
    return json.map(j => {
      return this.fromJson(j)
    })
  }


}
