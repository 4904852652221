import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InspectorStatus } from '../../Helpers/Utils';
import { DialogRef, ModalComponent, CloseGuard } from 'angular2-modal';
import { BSModalContext } from 'angular2-modal/plugins/bootstrap';
import { CustomModalContext } from '../refuse-registration-modal/refuse-registration-modal.component';


@Component({
	selector: 'app-refuse-payment-modal',
	templateUrl: './refuse-payment-modal.component.html',
	styleUrls: ['./refuse-payment-modal.component.scss']
})
export class RefusePaymentModalComponent {
	context: CustomModalContext
	public reason: string = ""
	public label: string = ""

	constructor(public dialog: DialogRef<CustomModalContext>) {
		this.context = dialog.context;
		this.label = this.context.data
	}

	close() {
		this.dialog.close(false)
	}
	confirm() {
		this.dialog.close(this.reason)

	}
}
