import { Component  } from '@angular/core';
import { LoaderService } from './Ui/loader.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app works!';
  loading: boolean;

  constructor(
	   private loaderService: LoaderService) {
     this.loading = false;
     
   }

   ngOnInit() {
	   this.loaderService.loaderStatus.subscribe((val: boolean) => {
		   this.loading = val;
	   });
   }
}
