import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs/Rx";
import { RegistrationsMock } from "../../Mock/Registrations";
import { Registration } from "../../../Models/Registration";
import { RegistrationStatus } from "../../../Helpers/Utils";
import {
  NetworkingService,
  Endpoints
} from "../../Networking/networking.service";
import { environment } from "environments/environment";

@Injectable()
export class RegistrationService {
  constructor(private networking: NetworkingService) {}

  getRegistrationsList(
    state,
    pageNumber: number = 0,
    sort = 0,
    sortDescending = true
  ): Observable<any> {
    let data = {
      pageNumber: pageNumber,
      pageSize: 30,
      state: state,
      sortby: sort,
      isToSortDescending: sortDescending
    };

    return this.networking
      .get(
        "inspectors/registrations/search",
        this.networking.getAuthenticatedHeader(),
        "application/json",
        data
      )
      .map(data => {
        let registrations = Registration.fromJsonArray(data.data);
        return {
          registrations: registrations,
          meta: Registration.getListMeta(data)
        };
      });
  }

  getSingleRegistration(id: Observable<string>): Observable<Registration> {
    return id.flatMap(id => this.getRegistrationById(id, false));
  }

  getRegistrationById(
    id: string,
    isToBypassCache: boolean = true
  ): Observable<Registration> {
    return this.networking
      .get(
        "inspectors/" + id + "?isToBypassCache=" + isToBypassCache,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .combineLatest(
        this.getRegistrationPictures(id, 0),
        this.getRegistrationPictures(id, 1),
        this.getRegistrationPictures(id, 2),
        (inspector, pic1, pic2, pic3) => {
          let registration = Registration.fromJson(inspector.data);
          registration.face_pic = pic3;
          registration.document_front = pic1;
          registration.document_back = pic2;
          return registration;
        }
      );
  }

  updateNumber(
    phoneNumber: string,
    countryPrefix: string,
    inspectorId: string
  ) {
    return this.networking
      .put(
        "inspectors/" +
          inspectorId +
          "/phoneNumber?phoneNumber=" +
          phoneNumber +
          "&countryPrefix=" +
          countryPrefix,
        {},
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  startingPoint(
    inspectorId: string,
    address: string,
    lat: string,
    lng: string
  ) {
    return this.networking
      .put(
        "inspectors/" +
          inspectorId +
          "/startingPoint?address=" +
          address +
          "&lat=" +
          lat +
          "&lng=" +
          lng,
        {},
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  getRegistrationPictures(id: string, type: number) {
    return this.networking
      .get(
        "inspectors/" + id + "/Idphoto/" + type,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map((data: any) => {
        return data.data;
      })
      .catch(error => {
        if (error.status === 404) return Observable.of("");
        else return Observable.throw(error);
      });
  }

  acceptRegistration(id: string): Observable<any> {
    return this.networking
      .post(
        "inspectors/registrations/accept?registrationId=" + id,
        {},
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  rejectRegistration(id: string, description: string): Observable<any> {
    return this.networking
      .post(
        "inspectors/registrations/reject?registrationId=" +
          id +
          "&rejectionReason=" +
          description,
        {},
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  requestEditRegistration(id: string, description: string): Observable<any> {
    return this.networking
      .post(
        "inspectors/registrations/refuse?registrationId=" +
          id +
          "&refuseReason=" +
          description,
        {},
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  requestRegistrationAbort(id: string): Observable<any> {
    return this.networking
      .delete(
        "inspectors/registrations/abort?registrationId=" + id,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  requestRegistrationReset(id: string): Observable<any> {
    return this.networking
      .put(
        "inspectors/registrations/reset?registrationId=" + id,
        {},
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  resendConfirmationEmail(email: string): Observable<any> {
    return this.networking
      .post(
        "/api/Account/resendConfirmationEmail?Email=" + email + "&UserType=1",
        {},
        [],
        "application/json",
        Endpoints.authapi
      )
      .map(data => {
        return data;
      });
  }
}
