export class Company {
	constructor(
		public id : string = "...",
		public company_name : string = "...",
		public main_account: string = "...",
		public main_account_id : string = "",
		public phone: string = "...",
		public email: string = "...",
		public stats : any = {
			"total_reports" : 0,
			"accepted_reports" : 0,
			"expired_reports" : 0,
			"rejected_reports" : 0
		},
		public logo: string = ""
	) {}

	 assignToObject(json: any) {
		this.id = json.company_id;
		this.company_name = json.company_name;
		this.main_account = json.main_account;
		this.main_account_id = json.main_account_id;
		this.phone = json.phone;
		this.email = json.email;
		this.logo = json.logo_url
	}

	static fromJson(json:any) : Company {
		let company = new Company();
		company.assignToObject(json);
		return company;
	}


	static fromJsonArray(json:any) : Company[] {
		return json.map(j => {
			return this.fromJson(j)
		})
	}


}
