import { Component, OnInit, Output } from '@angular/core';
import { DialogRef, ModalComponent, CloseGuard } from 'angular2-modal';
import { BSModalContext } from 'angular2-modal/plugins/bootstrap';
import * as moment from 'moment';
import { BusinessEvent } from '../../Models/BusinessEvent';
import { LoaderService } from '../../Ui/loader.service';

export class EventModalContext extends BSModalContext {
	public data: BusinessEvent;
}

@Component({
	selector: 'app-event-modal',
	templateUrl: './event-detail-modal.component.html',
	styleUrls: ['./event-detail-modal.component.scss']
})

export class EventDetailModalComponent implements ModalComponent<EventModalContext> {
	context: EventModalContext;
	public jsonData : any;
  public id: string;
  public name: string;

	constructor(public dialog: DialogRef<EventModalContext>, private loader: LoaderService) {
    this.context = dialog.context;
    this.jsonData = this.context.data.eventData;
    this.id = this.context.data.id;
    this.name = this.context.data.parsedEventName;
	}

	close() {
		this.dialog.close(false);
	}

}
