import { Component, OnInit, OnDestroy } from '@angular/core';
import { Registration } from '../../Models/Registration';
import { Subscription, Observable } from 'rxjs/Rx';
import { LoaderService } from '../../Ui/loader.service';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../../Services/List/Registration/registration.service';
import { ModalService } from '../../Helpers/modal.service';
import { UserRole, RegistrationStatus } from '../../Helpers/Utils';
import { RefuseRegistrationModalComponent } from '../../Components/refuse-registration-modal/refuse-registration-modal.component';
import { Modal, BSModalContext } from 'angular2-modal/plugins/bootstrap';
import { Overlay, overlayConfigFactory } from 'angular2-modal';
import { Location } from '@angular/common';
import { DashboardService } from '../../Services/Dashboard/dashboard.service';
import {Router} from "@angular/router"
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {Address} from "ngx-google-places-autocomplete/objects/address";

declare var google: any;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {
  public registration: Registration = new Registration();
  public sub: Subscription = new Subscription();
  public description: string;
  public isEdit: boolean = false;
  public phoneNumberInput:string;
  public startingPointAddress:string;
  public registrationPhone:string;
  public lng:string = "";
  public lat:string = "";

  constructor(public router:Router, public dashboardService: DashboardService, private location: Location, public overlay: Overlay, public modal: Modal, private loading: LoaderService, private registrationService: RegistrationService, private route: ActivatedRoute, private modalService: ModalService) {
    this.getSingleRegistrationData();
  }

  private getParams(route: any): Observable<any[]> {
    return Observable.combineLatest(route.params, route.parent.params, (param1: any, param2: any) => {
      let param = Object.assign({}, param1, param2)
      return param
    });
  }

  private get id() {
    return this.getParams(this.route).map(params => params['id'])
  }

  public get currentStatus() {
    return RegistrationStatus.getRegistrationStatusString(this.registration.current_state);
  }

  getSingleRegistrationData() {
    this.loading.displayLoader(true);
    this.sub.add(this.registrationService.getSingleRegistration(this.id)
      .subscribe(
        model => {
          this.loading.displayLoader(false)
          this.registration = model;
          console.log(this.registration);
          this.startingPointAddress = this.registration.starting_point_address;
          this.registrationPhone = this.registration.phone;
          //console.log(model);
        }, err => {
          this.loading.displayLoader(false)
          let error = err.json().data
          if (error.is_business_error) {
            this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
          } else {
            this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
          }
        }
      ));
  }

  openPic(url, title) {
    this.modalService.imgModal(url, title, true);
  }

  public get userRole() {
    return UserRole.getUserRoleString(this.registration.role);
  }

  public get viewReason() {
    if (this.registration.refusal_reason) {
      return true
    }
    return false
  }

  acceptRegistation() {
    this.modalService.confirmDialog("Conferma", "Confermando questa registrazione l’utente sarà ufficialmente registrato nel nostro sistema, assicurati dell’esattezza dei suoi dati.", () => {
      this.loading.displayLoader(true);
      this.sub.add(this.registrationService.acceptRegistration(this.registration.id)
        .finally(() => {
          this.loading.displayLoader(false);
        })
        .subscribe(date => {
          //this.modalService.simpleModal("Successo", "L'utente è stato approvato con successo")
          this.location.back();
        }, err => {
          let error = err.json().data
          if (error.is_business_error) {
            this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
          } else {
            this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
          }
          console.log(err)
        }))
    })
  }

  modify(){
    this.isEdit = true;
    this.phoneNumberInput = this.registrationPhone;
  }

  isEditCheck(){
    return this.isEdit;
  }

  update(){
    this.isEdit = false;

     
      this.loading.displayLoader(true);

      let phoneNumber = this.phoneNumberInput.replace("+39","");
      phoneNumber=phoneNumber.trim();


    if(this.lat){
      this.sub.add(this.registrationService.startingPoint(this.registration.id, this.startingPointAddress, this.lat, this.lng)
      .subscribe(date => {
       //  this.location.back();
      }, err => {
        let error = err.json().data
        if (error.is_business_error) {
          this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
        } else {
          this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
        }
      }))
    }

    this.sub.add(this.registrationService.updateNumber(phoneNumber,"%2B39",this.registration.id)
        .finally(() => {
          this.loading.displayLoader(false);
          this.registrationPhone = "+39"+phoneNumber;
        })
        .subscribe(date => {
         //  this.location.back();
        }, err => {
          let error = err.json().data
          if (error.is_business_error) {
            this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
          } else {
            this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
          }
        }))
  
  }

  rejectRegistration() {
    this.modal.open(RefuseRegistrationModalComponent, overlayConfigFactory({ data: "Sei sicuro di voler rifiutare la registrazione di questo utente?" }, BSModalContext))
      .then(resultPromise => {
        resultPromise.result.then(result => {
          if (result) {
            this.loading.displayLoader(true);
            this.sub.add(this.registrationService.rejectRegistration(this.registration.id, result)
              .finally(() => {
                this.loading.displayLoader(false);
              })
              .subscribe(date => {
                //this.modalService.simpleModal("Successo", "La registrazione è stata rifiutata con successo")
                this.location.back();
              }, err => {
                let error = err.json().data
                if (error.is_business_error) {
                  this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
                } else {
                  this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
                }
              }))
          }
        });
      });
  }


  public requestEdit() {
    this.modal.open(RefuseRegistrationModalComponent, overlayConfigFactory({ data: "Verrà inviata un’email con le modifiche da apportare alla registrazione, una volta effettuata la modifica la registrazione sarà visualizzabile dalla sezione “In pending”" }, BSModalContext))
      .then(resultPromise => {
        resultPromise.result.then(result => {
          if (result) {
            this.loading.displayLoader(true);
            this.sub.add(this.registrationService.requestEditRegistration(this.registration.id, result)
              .finally(() => {
                this.loading.displayLoader(false);
              })
              .subscribe(date => {
                //this.modalService.simpleModal("Successo", "Operazione eseguita con successo")
                this.location.back();
              }, err => {
                let error = err.json().data
                if (error.is_business_error) {
                  this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
                } else {
                  this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
                }
              }))
          }
        });
      });
  }

  public resendConfirmationEmail() {
    this.modalService.confirmDialog("Conferma", "Verrà reinviata la mail di conferma indirizzo all'utente selezionato. Procedere?", () => {
      this.loading.displayLoader(true);
      this.sub.add(this.registrationService.resendConfirmationEmail(this.registration.email)
        .finally(() => {
          this.loading.displayLoader(false);
        })
        .subscribe(date => {
          this.modalService.simpleModal("Successo", "La mail di conferma è stata inviata con successo.<br/> Ricordare all'utente di verificare anche tra lo spam")
          this.location.back();
        }, err => {
          this.modalService.simpleModal(`Si è verificato un errore (${err.status})`, `Risposta del server: ${err.statusText}`)
        }))
    });
  }

  public resetRegistration() {
    this.modalService.confirmDialog("Azzerare stato registrazione utente?", "Proseguendo verrà 'azzerato' lo stato della registrazione (come a seguito di una richiesta di modifica registrazione) e l'utente dovà di nuovo compilare il form di registrazione ed inviare le foto. Procedere?", () => {
      this.loading.displayLoader(true);
      this.sub.add(this.registrationService.requestRegistrationReset(this.registration.id)
        .finally(() => {
          this.loading.displayLoader(false);
        })
        .subscribe(date => {
          this.location.back();
        }, err => {
          let error = err.json().data
          if (error.is_business_error) {
            this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
          } else {
            this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
          }
        }))
    });
  }

  public abortRegistration() {
    this.modalService.confirmDialog("Eliminare definitivamente registrazione utente?", "La registrazione utente e tutti i suoi dati verranno eliminati dal sistema (come se non si fosse mai registrato).<br/><br/>Il processo è definitivo.<br/>Proseguire?", () => {
      this.loading.displayLoader(true);
      this.sub.add(this.registrationService.requestRegistrationAbort(this.registration.id)
        .finally(() => {
          this.loading.displayLoader(false);
        })
        .subscribe(date => {
          this.location.back();
        }, err => {
          let error = err.json().data
          if (error.is_business_error) {
            this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
          } else {
            this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
          }
        }))
    });
  }

  public get labelStatusColor() {
    if (this.registration.current_state == RegistrationStatus.Accepted) {
      return "#5cb85c";
    }
    return "inherit";
  }


  public get currentStatusString() {
    switch (this.registration.current_state) {
      case (RegistrationStatus.Accepted):
        return "Accettata"
      default:
        return "Rifiutata"
    }
  }

  public get shouldSeeDetails() {
    return this.registration.current_state == RegistrationStatus.Accepted || this.registration.current_state == RegistrationStatus.Rejected;
  }

  public get shouldSeePhotos() {
    return this.registration.current_state != RegistrationStatus.WaitForEmailConfirmation
  }

  setAddress(event: Address) {
    this.startingPointAddress = event.formatted_address;
    this.lng = event.geometry.location.lng().toFixed(6);
		this.lat = event.geometry.location.lat().toFixed(6);
    
	}

  public getBreadcrumb(current_status) {
    switch (current_status) {
      case RegistrationStatus.ToApprove:
        return "/home/registrations/toapprove"
      case RegistrationStatus.InPending:
        return "/home/registrations/pending"
      case RegistrationStatus.Accepted:
        return "/home/registrations/accepted"
      case RegistrationStatus.Rejected:
        return "/home/registrations/rejected"
    }
  }

  ngOnInit() {
    //swallow
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
