import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { LoaderService } from '../../Ui/loader.service';
import { PaymentService } from '../../Services/List/Payment/payment.service';
import { ModalService } from '../../Helpers/modal.service';
import { Payment } from '../../Models/Payment';
import {ActivatedRoute} from '@angular/router';
import { PaymentStatus } from '../../Helpers/Utils';
import { DashboardService } from '../../Services/Dashboard/dashboard.service';
import { RefusePaymentModalComponent } from '../../Components/refuse-payment-modal/refuse-payment-modal.component';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
	public sub : Subscription = new Subscription();
	public payment : Payment = new Payment();

	constructor(public dashboardService: DashboardService, private loading: LoaderService, private paymentService : PaymentService, private route: ActivatedRoute, private modalService: ModalService) {
		this.getSinglePaymentData();
	}

	getSinglePaymentData() {
		this.loading.displayLoader(true);
		this.sub.add(this.paymentService.getSinglePayment(this.route.params.map(params => params['id']))
		.subscribe(
			model => {
				this.loading.displayLoader(false)
				this.payment = model;
				console.log(model);
			}, err => {
				this.loading.displayLoader(false)
				let error = err.json().data
				if(error.is_business_error) {
					this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
				} else {
					this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
				}
			}
		));
	}

	elaboratePayment(){
		if(this.payment.isToBeBooked)
		this.bookPayment();
		else
		this.acceptPayment();
	}

	acceptPayment() {
		this.modalService.confirmDialog("Conferma", "Sei sicuro di voler confermare questo pagamento?", () => {
			this.loading.displayLoader(true);
			this.sub.add(this.paymentService.acceptPayment(this.payment.id)
			.finally(() => {
				this.loading.displayLoader(false);
			})
			.subscribe(date => {
				this.modalService.simpleModal("Successo", "Il pagamento è stato autorizzato con successo")
				this.getSinglePaymentData();
			}, err => {
				let error = err.json().data
				if(error.is_business_error) {
					this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
				} else {
					this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
				}
			}))
		})
	}

	bookPayment() {
		this.modalService.confirmDialog("Conferma", "Vuoi prendere in carico questo pagamento?", () => {
			this.loading.displayLoader(true);
			this.sub.add(this.paymentService.bookPayment(this.payment.id)
			.finally(() => {
				this.loading.displayLoader(false);
			})
			.subscribe(date => {
				this.modalService.simpleModal("Successo", "Hai preso in carico la richiesta di pagamento. La troverai nella tua sezione in corso")
				this.getSinglePaymentData();
			}, err => {
				let error = err.json().data
				if(error.is_business_error) {
					this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
				} else {
					this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
				}
			}))
		})
	}

	rejectPayment() {
		this.modalService.modalWithComponent(RefusePaymentModalComponent,{}, result => {
			this.loading.displayLoader(true);
			this.sub.add(this.paymentService.rejectPayment(this.payment.id,result)
			.finally(() => {
				this.loading.displayLoader(false);
			})
			.subscribe(date => {
				this.modalService.simpleModal("Successo", "Il pagamento è stato rifiutato con successo")
				this.getSinglePaymentData();
			}, err => {
				this.modalService.simpleModal("Errore", "Si è verificato un errore")
			}))
		})
	}

	public get currentStatus() {
		return PaymentStatus.getRegistrationStatusString(this.payment.status);
	}

	public get isEditable() {
		if(this.payment.status == PaymentStatus.InPending || this.payment.status == PaymentStatus.ToApprove) {
			return true
		}
		return false
	}

	public getBreadcrumb(status) {
		switch (status) {
			case PaymentStatus.ToApprove:
			return "/home/payments/toapprove"
			case PaymentStatus.InPending:
			return "/home/payments/pending"
			case PaymentStatus.Accepted:
			return "/home/payments/accepted"
			case PaymentStatus.Rejected:
			return "/home/payments/rejected"
		}
	}


	ngOnInit() {
	}

}
