import { Injectable } from "@angular/core";
import { Summary } from "../../Models/Summary";
import { Observable, Observer } from "rxjs/Rx";
import { DashboardMock } from "../Mock/Dashboard";
import { NetworkingService, Endpoints } from "../Networking/networking.service";
import { Company } from "../../Models/Company";
import { Router } from "@angular/router";
import { Notification } from "../../Models/Notification";
import { Operator } from "../../Models/Operator";
import { Template } from "../../Models/Template";
import {
  PushNotificationService,
  PushNotificationOptions
} from "ngx-push-notifications";

@Injectable()
export class DashboardService {
  public summary: Summary = new Summary();
  public companyList: Company[] = [];
  public notificationSummary: Notification[] = [];
  public notificationToRead: number = 0;
  public operator: Operator = new Operator();
  public template: Template = new Template();

  constructor(
    private networking: NetworkingService,
    private pushNotificationComponent: PushNotificationService,
    private router: Router
  ) {}

  public loadSummary(): Observable<Summary> {
    return this.networking
      .get(
        "dashboard/summary",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        let summary = Summary.fromJson(data.data);
        this.summary = summary;
        return summary;
      });
  }

  public loadCompanyList(): Observable<Company[]> {
    return this.networking
      .get(
        "companies",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        let companyList = Company.fromJsonArray(data.data);
        this.companyList = companyList;
        this.companyList.sort(function(a, b) {
          if (a.company_name > b.company_name) return 1;
          if (a.company_name < b.company_name) return -1;
          return 0;
        });
        return companyList;
      });
  }

  addUserCompany(
    name: string,
    surname: string,
    phone: string,
    email: string,
    idCompany: string
  ) {
    var data = {
      FirstName: name,
      LastName: surname,
      phoneNumber: phone,
      Email: email
    };
    return this.networking
      .post(
        "companies/" + idCompany + "/operators",
        data,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data;
      });
  }

  deleteUserCompany(idUser: string, idCompany: string) {
    return this.networking
      .delete(
        "companies/" + idCompany + "/operators/" + idUser,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data;
      });
  }

  getNotificationSummary(): Observable<Notification[]> {
    return this.networking
      .get(
        "notifications/summaries",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        let notification = Notification.fromJsonArray(
          data.data.latest_notifications
        );
        this.notificationSummary = notification;

        notification.forEach(async item => {
          if (item.notificationType == 4 && !item.wasRead) {
            await this.setNotificationAsRead(item.id).finally(() => {
              this.notifyMe(
                item.targetID + " / " + item.notificationTitle,
                item.notificationText
              );
            });
          }
          //console.log(item.targetID +" / "+ item.notificationTitle,item.notificationText)
          //this.notifyMe(item.targetID +" / "+ item.notificationTitle,item.notificationText);
          //this.notifyMe(item.id +" / "+ item.notificationTitle,item.notificationText);
          //this.setNotificationAsRead(item.id);
        });

        return notification;
      });
  }

  notifyMe(title: string, description: string) {
    const options = new PushNotificationOptions();

    if (!("Notification" in window)) {
      alert("This browser does not support system notifications");
    } else if (this.pushNotificationComponent.isPermissionGranted) {
      options.icon = "/assets/img/favicon.png";
      options.body = description;
      options.sticky = true;
      this.pushNotificationComponent.create(title, options);

      this.pushNotificationComponent.create(title, options).subscribe(
        notif => {
          if (notif.event.type === "show") {
            setTimeout(() => {
              notif.notification.close();
            }, 3000);
          }
          if (notif.event.type === "click") {
            notif.notification.close();
          }
          if (notif.event.type === "close") {
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  redirectToUrl() {
    this.router.navigateByUrl("/home/territory/sauron");
  }

  changePassword(
    currentPassword: string,
    newPassword: string
  ): Observable<any> {
    var data = {
      oldPassword: currentPassword,
      newPassword: newPassword
    };

    return this.networking.post(
      "/api/Account/changePassword",
      data,
      this.networking.getAuthenticatedHeader(),
      "application/json",
      Endpoints.authapi
    );
  }

  operatorData(): Observable<Operator> {
    return this.networking
      .get("operators", this.networking.getAuthenticatedHeader())
      .map(data => {
        return Operator.fromJson(data.data);
      });
  }

  setNotificationAsRead(id: string) {
    return this.networking.put(
      "notifications/read/" + id,
      {},
      this.networking.getAuthenticatedHeader()
    );
  }

  setAllNotificationsAsRead() {
    return this.networking.put(
      "notifications/read/",
      {},
      this.networking.getAuthenticatedHeader()
    );
  }

  addUser(
    name: string,
    surname: string,
    phone: string,
    email: string,
    role: string
  ) {
    var data = {
      FirstName: name,
      LastName: surname,
      phoneNumber: phone,
      Email: email,
      GrantProfileId: role
    };

    return this.networking
      .post(
        "operators",
        data,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data;
      });
  }

  getInsoorerList(search: string) {
    return this.networking
      .get(
        "inspectors/search/" + search,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return Operator.fromJsonArray(data.data);
      });
  }

  getUsersList() {
    return this.networking
      .get(
        "operators/index",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return Operator.fromJsonArray(data.data);
      });
  }

  getTemplate(companyId: string) {
    return this.networking
      .get(
        "requests/templates?companyId=" + companyId,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return Template.fromJsonArray(data.data);
      });
  }

  getTemplateList(templateId: string) {
    return this.networking
      .get(
        "requests/templates?companyId=" + templateId,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return Template.fromJsonArray(data.data);
      });
  }

  getServiceList(companyId: string, templateId: string) {
    return this.networking
      .get(
        "requests/templates?companyId=" + companyId,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(serviceList => {
        return serviceList.data.filter(x => x.id == templateId)[0]
          .allowed_services;
      });
  }

  deleteUser(email: string) {
    return this.networking
      .delete(
        "operators/" + email,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data;
      });
  }

  public getRoleList() {
    return this.networking
      .get(
        "values/operatorProfiles",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  public updateUser(user: Operator) {
    return this.networking.put(
      `operators/changeGrantProfile?operatorId=${user.id}&newProfile=${user.profileType}`,
      {},
      this.networking.getAuthenticatedHeader(),
      "application/json"
    );
  }
}
