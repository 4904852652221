import { InspectorStatus, InspectionType } from "../Helpers/Utils";
import * as moment from "moment";

export class CityInspection {
  constructor(
    public id: string = "",
    public license_plate: string = "",
    public latitude: string = "",
    public longitude: string = "",
    public request_type = "",
    public is_scheduled: boolean = false,
    public validity_deadline_date: number = 0,
    public parsed_validity_deadline_date: string = "",
    public deadline_severity: number = 0,
    public address: string = ""
  ) {}

  static fromJson(json: any): CityInspection {
    let inspection = new CityInspection();
    inspection.id = json.id;
    inspection.license_plate = json.license_plate;
    inspection.latitude = json.latitude;
    inspection.longitude = json.longitude;
    inspection.request_type = json.request_type;
    inspection.is_scheduled = json.is_scheduled;
    inspection.parsed_validity_deadline_date = moment
      .unix(json.validity_deadline_date)
      .format("DD/MM/YYYY | HH:mm");
    inspection.validity_deadline_date = json.validity_deadline_date;
    inspection.deadline_severity = json.deadline_severity;
    inspection.address = json.address;
    return inspection;
  }

  static fromJsonArray(json: any): CityInspection[] {
    return json.map(j => {
      return this.fromJson(j);
    });
  }

  public get icon() {
    if (this.is_scheduled == true) {
      return "assets/schedulata.png";
    } else if (this.request_type == InspectionType.Assuntiva) {
      return "assets/assuntiva.png";
    } else {
      return "assets/crash.png";
    }
  }

  public get type() {
    return InspectionType.getInspectionTypeString(this.request_type);
  }

  public get timer() {
    let deadline = this.validity_deadline_date;
    let now = Math.round(new Date().getTime() / 1000);
    return moment.utc((deadline - now) * 1000).format("HH:mm:ss");
  }

  public get isPassed() {
    let deadline = this.validity_deadline_date;
    let now = Math.round(new Date().getTime() / 1000);
    return deadline < now;
  }
}
