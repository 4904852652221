import { Component, OnInit, OnDestroy } from '@angular/core';
import { TableStructure } from '../../../Helpers/TableStructure';
import { RegistrationStatus } from '../../../Helpers/Utils';
import { RegistrationService } from '../../../Services/List/Registration/registration.service';
import { Subscription } from 'rxjs/Rx';
import { LoaderService } from '../../../Ui/loader.service';

@Component({
  selector: 'app-registration-pending',
  templateUrl: './registration-pending.component.html',
  styleUrls: ['./registration-pending.component.scss']
})
export class RegistrationPendingComponent implements OnInit, OnDestroy{

	public tableStructure : any[] = TableStructure.Registrations;
    public items : any[] = [];
    public sub : Subscription = new Subscription();
    public meta : any = {};
	public currentPage: number = 0;
	public sortDescending: boolean = true;



	constructor(private loading: LoaderService, private registrationService: RegistrationService ) {
		this.getRegistrationList()
	}


	getRegistrationList(page: number = 0, sort = 2) {
		this.loading.displayLoader(true);
		this.sub.add(this.registrationService.getRegistrationsList(RegistrationStatus.InPending,page, sort, this.sortDescending)
		.finally(() => {
			this.loading.displayLoader(false);
		})
		.subscribe(data => {
			this.items = data.registrations;
			this.meta = data.meta;
			console.log(data.meta);
		}));
	}

	nextPage() {
		if(!this.meta.hasNext) return
		this.currentPage+=1
		this.getRegistrationList(this.currentPage)
	}

	prevPage() {
		if(!this.meta.hasPrevious) return
		this.currentPage-=1
		this.getRegistrationList(this.currentPage)
	}

	goToPage(page: number) {
		this.getRegistrationList(page)
	}


	sortBy(type: number) {
		this.sortDescending = !this.sortDescending
		this.getRegistrationList(this.currentPage, type)
	}


    ngOnInit() {

    }

    ngOnDestroy() {
  	  this.sub.unsubscribe();
    }
}
