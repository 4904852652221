import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Overlay, overlayConfigFactory } from 'angular2-modal';
import { Modal, BSModalContext } from 'angular2-modal/plugins/bootstrap';
import {Subscription} from 'rxjs/Subscription';
import { LoaderService } from '../../Ui/loader.service';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit, OnDestroy {
	@Input() structure: any[];
	@Input() items: any[];
	@Input() meta: any;
	@Input() state: number;
	@Input() address: number;
	@Input() detailUrl: string;
	@Input() pagination: boolean = true;
	@Output() pageNextEvent: EventEmitter<any> = new EventEmitter();
	@Output() pageBackEvent: EventEmitter<any> = new EventEmitter();
	@Output() goToPageEvent: EventEmitter<number> = new EventEmitter();
	@Output() itemClick: EventEmitter<string> = new EventEmitter();
	@Input() sortable: boolean = false;
	@Output() sortEvent: EventEmitter<number> = new EventEmitter();
	@Input() isAdjuster: boolean = false;
	@Input() isUser: boolean = false;

	public goToPage: number = 1;
	public sub: Subscription = new Subscription();

	constructor(public overlay: Overlay, public modal: Modal, private loaderService: LoaderService) {
	}

	routerLink(id: string, companyId:string) {
		if(companyId){
			if(this.detailUrl) {
				return "/home/company/"+companyId+"/inspection/"+id
			}
		} else if(id){
			if(this.detailUrl) {
				return this.detailUrl+id;
			}
		}
		
		return
	}

	itemClicked(id: string) {
		this.itemClick.emit(id)
	}

	ngOnInit() {
	}

	paginationNext() {
		this.pageNextEvent.emit()
	}

	paginationBack() {
		this.pageBackEvent.emit()
	}

	directToPage() {
		this.goToPageEvent.emit(this.goToPage -1)
	}

	sortBy(type: number) {
		this.sortEvent.emit(type)
	}

	recordsAvailable() {

	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}
}
