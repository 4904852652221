import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AuthService } from '../../Services/Authentication/auth.service';
import { ModalService } from '../../Helpers/modal.service';
import { Subscription } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { LoaderService } from '../../Ui/loader.service';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
	signupForm : FormGroup;
	private sub: Subscription = new Subscription();

  constructor(public formBuilder: FormBuilder, private authService: AuthService,  private modalService: ModalService, private router: Router, private loading : LoaderService) {
	  this.signupForm = formBuilder.group({
  		  name: ['', Validators.compose([Validators.required])],
  		  surname: ['', Validators.compose([Validators.required])],
  		  email: ['', Validators.compose([Validators.required, Validators.email])],
  		  password: ['', Validators.compose([Validators.required])],
  		  repeat: ['', Validators.compose([Validators.required])]
  	  }, {validator: this.checkPassword});
  }

  checkPassword(g:FormGroup) {
	  return g.get('password').value === g.get('repeat').value ? null : {'mismatch': true}
  }

  register() {
	  this.loading.displayLoader(true);
	  this.sub.add(this.authService.firstLogin(this.signupForm.value.name, this.signupForm.value.surname, this.signupForm.value.email, this.signupForm.value.password)
	  .finally(() => {
		  this.loading.displayLoader(false);
	  })
	  .subscribe(data => {
		  this.router.navigateByUrl('home');
	  }, err =>  {
      if(err.status === 403){
        var errorBody = JSON.parse(err._body).data;
        this.modalService.simpleModal("Si è verificato un errore ("+errorBody.error_code+")", "Si è verificato un errore durante il primo accesso: <br/><br/>"+errorBody.error_message)
      }else
		  this.modalService.simpleModal("Si è verificato un errore", "Si è verificato un errore durante il primo accesso:. <br/>Richiesta: " + err +"<br/>Risposta: " + err._body)
	  }));
  }

  ngOnDestroy() {
	  this.sub.unsubscribe();
  }

  ngOnInit() {
	  //swallow
  }


}
