import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs/Rx';
import { ActivatedRoute } from '@angular/router';
import { NetworkingService } from '../Networking/networking.service';
import { BusinessEvent, InspectionIdBasedBusinessEvent } from '../../Models/BusinessEvent';
import * as moment from 'moment';

@Injectable()
export class BusinessEventService {
  public currentId: string = ""
  constructor(private networking: NetworkingService, private route: ActivatedRoute) { }


  getInspectionEvents(id: Observable<string>): Observable<any> {
    return id.flatMap(id => {
      return this.networking.get('events/request/' + id, this.networking.getAuthenticatedHeader(), 'application/json')
        .map(data => {
          let events = BusinessEvent.fromJsonArray(data.data)
          return {
            "events": events,
            "meta": BusinessEvent.getListMeta(data.meta)
          }
        })
    })
  }

  getInspectorEvents(id: Observable<string>, fromDate:Date, toDate:Date, pageNumber:number, pageSize: number): Observable<any> {
    return id.flatMap(id => {
      let data = {
        "pageNumber": pageNumber,
        "pageSize": pageSize,
        "from": moment(fromDate).format("YYYY-MM-DD"),
        "to": moment(toDate).format("YYYY-MM-DD"),
      }

      return this.networking.get('events/inspector/' + id, this.networking.getAuthenticatedHeader(), 'application/json',data)
        .map(data => {
          let events = InspectionIdBasedBusinessEvent.fromJsonArray(data.data)
          return {
            "events": events,
            "meta": InspectionIdBasedBusinessEvent.getListMeta(data.meta)
          }
        })
    })
  }
}
