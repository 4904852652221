import {Injectable} from '@angular/core';
import {Modal, BSModalContext} from 'angular2-modal/plugins/bootstrap';
import {Overlay, overlayConfigFactory} from 'angular2-modal';

@Injectable()
export class ModalService {

  constructor(public overlay: Overlay, public modal: Modal) {
  }

  simpleModal(title: string, text: string) {
    this.modal.alert()
      .size('lg')
      .showClose(true)
      .title(title)
      .body(text)
      .open();
  }

  imgModal(url: string, title: string, isBase64: boolean) {
    let html
    if (url) {
      if (isBase64)
        html = `<img class='modalPicture' src="data:image/jpeg;base64,` + url + `">`
      else
        html = `<img class='modalPicture' src="` + url + `">`
    }
    else {
      html = `<h1 class="picError">Foto non disponibile</h1>`
    }

    this.modal.alert()
      .size('lg')
      .showClose(true)
      .title(title)
      .body(html)
      .open();
  }

  confirmDialog(title: string, text: string, completion: () => void, okString: string = 'Conferma', cancelString: string = 'Annulla', showClose: boolean = true, isBlocking: boolean = false) {
    this.modal.confirm()
      .size('lg')
      .isBlocking(isBlocking)
      .showClose(showClose)
      .keyboard(27)
      .title(title)
      .body(text)
      .okBtn(okString)
      .cancelBtn(cancelString)
      .open().then(result => {
      result.result.then(result => {
        completion();
      }, err => {
        //swallow
      });
    });
  }


  modalWithComponent(component, data, completion: (string) => void) {
    this.modal.open(component, overlayConfigFactory({data: data}, BSModalContext))
      .then(resultPromise => {
        resultPromise.result.then(result => {
          if (result) {
            console.log(result)
            completion(result)
          }
        }, err => {
          console.log('error')
        });
      })
  }


  errorModal(error) {
    if (error.is_business_error) {
      this.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`);
    } else {
      this.simpleModal(`Errore (${error.error_code})`, 'Si è verificato un errore');
    }
  }


}
