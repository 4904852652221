import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '../../Services/Dashboard/dashboard.service';
import { TableStructure } from '../../Helpers/TableStructure';
import { Subscription } from 'rxjs/Rx';
import { ActivatedRoute, Router } from '@angular/router'
import { CompanyService } from '../../Services/Company/company.service';

@Component({
	selector: 'app-company',
	templateUrl: './company.component.html',
	styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnDestroy, OnInit {
	public tableStructure : any[] = TableStructure.CompanyCredits;
	public sub : Subscription = new Subscription();
	public id : string = "";
	public menu: any;
	constructor(private dashboardService: DashboardService, private route: ActivatedRoute, private router: Router, private companyService: CompanyService) {}

	getCompanyID() {
		this.sub.add(this.route.params.subscribe(params => {
			this.id = params['id'];
			this.getInspectionsSummary()
		}));
	}

	private getInspectionsSummary() {
		this.sub.add(
			this.companyService.getInspectionsSummaryById(this.id)
			.subscribe(data => {
				this.createMenu(data);
			})
		)
	}

	ngOnInit() {
		this.getCompanyID();
	}


	ngOnDestroy() {
		this.sub.unsubscribe();
	}

	private createMenu(summary : any) {
		let menu = [
			{
				"title" : "Profilo",
				"router_link" : "/home/company/"+this.id+"/profile",
				"active_class" : "active",
				"unread" : null
			},
			{
				"title" : "Aperte",
				"router_link" : "/home/company/"+this.id+"/opened",
				"active_class" : "active",
				"unread" : summary.opened
			},
			{
				"title" : "In corso",
				"router_link" : "/home/company/"+this.id+"/pending",
				"active_class" : "active",
				"unread" : summary.ongoing
			},
			{
				"title" : "Da approvare",
				"router_link" : "/home/company/"+this.id+"/toapprove",
				"active_class" : "active",
				"unread" : summary.to_approve
			},
			{
				"title" : "Accettate",
				"router_link" : "/home/company/"+this.id+"/accepted",
				"active_class" : "active",
				"unread" : null
			},
			{
				"title" : "Rifiutate",
				"router_link" : "/home/company/"+this.id+"/rejected",
				"active_class" : "active",
				"unread" : null
			},
			{
				"title" : "Scadute",
				"router_link" : "/home/company/"+this.id+"/expired",
				"active_class" : "active",
				"unread" : null
			},
		/*	{
				"title" : "Schedulate",
				"router_link" : "/home/company/"+this.id+"/scheduled",
				"active_class" : "active",
				"unread" : summary.scheduled
			}
			{
				"title" : "Schedulate",
				"router_link" : "/home/company/"+this.id+"/scheduledPending",
				"active_class" : "active",
				"unread" : summary.scheduled
			}*/
		]
		this.menu = menu;
	}
}
