import {Injectable} from '@angular/core';
import {NetworkingService} from '../Networking/networking.service';
import {City} from '../../Models/City';
import {WorkHourZone} from '../../Models/WorkHourZone';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class ValuesService {

  constructor(private networking: NetworkingService) {
  }

  getCities() {
    return this.networking.get('values/servedCities', this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        return City.fromJsonArray(data.data);
      });
  }

  getWorkHourZones(): Observable<WorkHourZone[]> {
    return this.networking.get('values/workHourZones', this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        return WorkHourZone.fromJsonArray(data.data);
      });
  }


}
