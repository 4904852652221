import { Component, OnDestroy } from "@angular/core";
import { Subscription, Subject } from "rxjs/Rx";
import { Inspection } from "../../../Models/Inspection";
import { Schedule } from "../../../Models/Schedule";
import { ViewEncapsulation } from "@angular/core";
import { DashboardService } from "../../../Services/Dashboard/dashboard.service";
import { Photo } from "../../../Models/Photo";
import { ContactInfo } from "../../../Models/ContactInfo";
import { AdjusterCommitment } from "../../../Models/AdjusterCommitment";
import { Services } from "../../../Models/Services";
import { ServicesReview } from "../../../Models/ServicesReview";
import { Inspector } from "../../../Models/Inspector";
import { LoaderService } from "../../../Ui/loader.service";
import { CompanyService } from "../../../Services/Company/company.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  NetworkingService,
  Endpoints
} from "../../../Services/Networking/networking.service";
import {
  InspectionStatus,
  SummarizedInspectionStatus
} from "../../../Helpers/Utils";
import { Modal } from "angular2-modal/plugins/bootstrap";
import { ModalService } from "../../../Helpers/modal.service";
import { Location } from "@angular/common";
import { TableStructure } from "../../../Helpers/TableStructure";
import { BusinessEventService } from "../../../Services/BusinessEvents/business_event.service";
import { EventDetailModalComponent } from "../../../Components/event-detail-modal/event-detail-modal.component";
import { MapModalComponent } from "../../../Components/map/map.component";
import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation,
  NgxGalleryAction,
  NgxGalleryLayout
} from "ngx-gallery";
import * as FileSaver from "file-saver";
import { InspectorService } from "app/Services/Inspector/inspector.service";
import { ClaimAdjustersService } from "app/Services/ClaimAdjusters/ClaimAdjusters.service";
import { CompleterService, CompleterData } from "ng2-completer";

export interface service {
  idService: string;
}

export interface oService {
  Key(key: string): boolean;
}

export interface InsoorerNameSurname {
  nameSurname: string;
  surname: string;
  id: string;
}

@Component({
  selector: "app-inspection",
  templateUrl: "./inspection.component.html",
  styleUrls: ["./inspection.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class InspectionComponent implements OnDestroy {
  private sub: any = new Subscription();
  public inspection: Inspection = new Inspection();
  public inspectionID: string;
  public color: string;
  public abortReason: string;
  public ico: string;
  public price: string;
  public obbligatorio: string;
  public effettuato: string;
  public inspectorModel: Inspector;
  public n_foto_1: string;
  public n_foto_2: string;
  public n_foto_3: string;
  public n_foto_4: string;
  public n_foto_5: string;
  public n_foto_6: string;
  public n_foto_7: string;
  public n_foto_8: string;
  public n_foto_9: string;
  public n_foto_10: string;
  public n_foto_11: string;
  public n_foto_12: string;
  public n_foto_13: string;
  public n_foto_14: string;
  public n_foto_15: string;
  public n_foto_16: string;
  public n_foto_17: string;
  public n_foto_18: string;
  public n_foto_19: string;
  public n_foto_20: string;
  public n_foto_21: string;
  public n_foto_22: string;
  public n_foto_23: string;
  public n_foto_24: string;
  public inspector: string;
  public perito: string;
  public contact_info: ContactInfo;
  public adjuster_commitment: AdjusterCommitment;
  public fotoDescriptionO: string = "n.d.";
  public fotoDescriptionR: string = "n.d.";
  public latR: string = "n.d.";
  public longR: string = "n.d.";
  public latO: string = "n.d.";
  public longO: string = "n.d.";
  public fotoDescription1: string;
  public fotoDescription2: string;
  public fotoDescription3: string;
  public fotoDescription4: string;
  public fotoDescription5: string;
  public fotoDescription6: string;
  public fotoDescription7: string;
  public fotoDescription8: string;
  public fotoDescription9: string;
  public fotoDescription10: string;
  public fotoDescription11: string;
  public fotoDescription12: string;
  public fotoDescription13: string;
  public fotoDescription14: string;
  public fotoDescription15: string;
  public fotoDescription16: string;
  public fotoDescription17: string;
  public fotoDescription18: string;
  public fotoDescription19: string;
  public fotoDescription20: string;
  public fotoDescription21: string;
  public fotoDescription22: string;
  public fotoDescription23: string;
  public fotoDescription24: string;
  public manufacturer: string;
  public photos: Photo[];
  public totalPrice: number;
  public required_services: Services[];
  public optional_services: Services[];
  public abort_service: Services;
  public schedule: Schedule;
  public hasError: boolean;
  public isDisplay: boolean;
  public type: string;
  public reasonList: any[];
  public currentId: string = "";
  public companyId: string = "";
  public galleryOptions: NgxGalleryOptions[];
  public galleryImagesAbort_1: NgxGalleryImage[];
  public galleryImages_1: NgxGalleryImage[];
  public galleryImages_2: NgxGalleryImage[];
  public galleryImages_3: NgxGalleryImage[];
  public galleryImages_4: NgxGalleryImage[];
  public galleryImages_5: NgxGalleryImage[];
  public galleryImages_6: NgxGalleryImage[];
  public galleryImages_7: NgxGalleryImage[];
  public galleryImages_8: NgxGalleryImage[];
  public galleryImages_9: NgxGalleryImage[];
  public galleryImages_10: NgxGalleryImage[];
  public galleryImages_11: NgxGalleryImage[];
  public galleryImages_12: NgxGalleryImage[];
  public galleryImages_13: NgxGalleryImage[];
  public galleryImages_14: NgxGalleryImage[];
  public galleryImages_15: NgxGalleryImage[];
  public galleryImages_16: NgxGalleryImage[];
  public galleryImages_17: NgxGalleryImage[];
  public galleryImages_18: NgxGalleryImage[];
  public galleryImages_19: NgxGalleryImage[];
  public galleryImages_20: NgxGalleryImage[];
  public galleryImages_21: NgxGalleryImage[];
  public galleryImages_22: NgxGalleryImage[];
  public galleryImages_23: NgxGalleryImage[];
  public galleryImages_24: NgxGalleryImage[];
  public galleryAction: NgxGalleryAction[];
  public status: string;
  //public accepted:boolean = false;
  public isConfirm: boolean = false;
  public completedService: any[];
  public adjusterName: string;
  public name: string;
  public surname: string;
  public phone: string;
  public email: string = "n.d.";
  //public associatedReview : AssociatedReview[];
  public approvedServices = [];
  public acceptedServices: ServicesReview[];
  public currentPage: number = 0;
  public events: any[] = [];
  public isEdit: boolean = false;
  public isStart: boolean = true;
  public eventsListMeta: any = {};
  public tableStructure: any[] = TableStructure.InspectionEvents;
  public mapService: { [idService: string]: boolean } = {};
  public peritoAssigned: string;
  public searchStr: string = "";
  public searchStr2: string = "";
  public searchStrPerito: string = "";
  public searchStrPerito2: string = "";
  public textInsoorer: string = "";
  public dataService: CompleterData;
  public dataServicePerito: CompleterData;
  public searchData = [];
  public insoorerList: InsoorerNameSurname[];
  public peritoList: InsoorerNameSurname[];
  public data$ = new Subject();
  public dataPerito$ = new Subject();
  public idInspector: string = "";
  public idPerito: string = "";
  public nameInspector: string = "";
  public namePerito: string = "";
  public resultsFetched: boolean = false;
  public resultsFetchedPerito: boolean = false;
  public isFirstChar = false;
  public terms: any[] = [];
  public termsValue: any[] = [];
  public statusBtnAdd: boolean = true;
  public disabled: boolean = true;
  public requires_confirmation: boolean = true;

  constructor(
    private completerService: CompleterService,
    public claimAdjustersService: ClaimAdjustersService,
    public dashboardService: DashboardService,
    private _location: Location,
    private loaderService: LoaderService,
    private networking: NetworkingService,
    private inspectorService: InspectorService,
    private router: Router,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private modal: Modal,
    private modalService: ModalService,
    private eventService: BusinessEventService
  ) {
    this.loadData();
    this.getReasonList();
    this.getEvents(0);
    this.dataService = this.completerService.local(
      this.data$,
      "surname",
      "nameSurname"
    );
    this.dataServicePerito = this.completerService.local(
      this.dataPerito$,
      "surname",
      "nameSurname"
    );

    this.sub.add(
      this.route.params
        .map(params => params["code"])
        .subscribe(id => {
          this.currentId = id;
        })
    );
  }

  openNav() {
    document.getElementById("sidebar-dx").style.display = "block";
  }

  closeNav() {
    document.getElementById("sidebar-dx").style.display = "none";
  }

  loadData() {
    this.loaderService.displayLoader(true);
    this.sub.add(
      this.companyService
        .getInspectionViewModel(this.route.params.map(params => params["code"]))
        .subscribe(
          model => {
            this.loaderService.displayLoader(false);
            this.inspection = model.singleInspection;
            console.log(this.inspection);
            this.inspectionID = this.inspection.id;
            this.companyId = this.inspection.company_id;
            this.type =
              this.inspection.request_type === 0 ? "Assuntiva" : "Crash";

            this.color = model.color;
            this.manufacturer = model.manufacturer;
            this.abortReason = model.abort;
            this.photos = model.photo;
            this.contact_info = model.contact_info;
            this.adjuster_commitment = model.adjuster_commitment;

            if (this.adjuster_commitment) {
              this.adjusterName = this.adjuster_commitment.adjuster_full_name;
            }

            if (this.contact_info) {
              this.name = this.contact_info.first_name;
              this.surname = this.contact_info.last_name;

              if (this.contact_info.email) this.email = this.contact_info.email;

              this.phone = this.contact_info.phone_number;
            }

            this.totalPrice = this.inspection.company_cost;
            this.price = this.totalPrice.toString();

            if (
              model.associated_review &&
              model.associated_review.service_reviews
            )
              this.acceptedServices = model.associated_review.service_reviews;

            this.required_services = model.required_services;
            this.optional_services = model.optional_services;
            this.abort_service = this.inspection.abort_service;

            if (model.schedule && model.schedule.active_booking) {
              this.requires_confirmation = model.schedule.requires_confirmation;
              this.inspectorService
                .getInspectorId(model.schedule.active_booking.inspector_id)
                .subscribe(data => {
                  this.inspectorModel = data;
                  this.inspector =
                    this.inspectorModel.name +
                    " " +
                    this.inspectorModel.surname;
                  this.nameInspector =
                    this.inspectorModel.name +
                    " " +
                    this.inspectorModel.surname;
                });
            } else {
              this.inspector = this.inspection.inspector;
              this.nameInspector = this.inspection.inspector;
            }

            this.completedService = [];
            var counter: number = 0;

            for (let myService of this.required_services) {
              counter++;

              this.photoService(myService.id, counter);
              console.log(myService);
              if (this.isDone(myService.id, 0)) {
                this.completedService.push(counter);
              }
            }

            if (this.inspection.abort_reason_id && this.abort_service)
              this.photoService(this.abort_service.id, 1);

            counter = 14;

            for (let myService of this.optional_services) {
              counter++;
              this.photoService(myService.id, counter);

              if (this.isDone(myService.id, 0)) {
                if (this.isAccepted(myService.id)) {
                  this.totalPrice =
                    this.totalPrice + this.oServicePrice(myService.id);
                  this.price = this.totalPrice.toString();
                }

                this.mapService = { [myService.id]: false };

                this.completedService.push(counter);
              }
            }
          },
          err => {
            this.loaderService.displayLoader(false);
          }
        )
    );

    this.galleryOptions = [
      {
        width: "800px",
        height: "550px",
        layout: NgxGalleryLayout.ThumbnailsTop,
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: "370px",
        height: "430px",
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      }
    ];
  }

  modify() {
    this.isEdit = true;
    this.peritoAssigned = this.inspector;
  }

  isEditCheck() {
    return this.isEdit;
  }

  removePerito() {
    this.namePerito = "";
    this.idPerito = "";
    this.peritoAssigned = this.namePerito;
    document.getElementById("peritoContainer").style.visibility = "hidden";
  }

  unassignPerito() {
    this.loaderService.displayLoader(true);
    this.sub.add(
      this.claimAdjustersService
        .removeAssignment(this.inspectionID, this.inspection.company_id)
        .finally(() => {
          this.adjuster_commitment = null;
          this.loaderService.displayLoader(false);
          this.inspection.state = "6";
          //this.router.navigateByUrl("/home/company/"+this.inspection.company_id+"/inspection/"+this.inspectionID);
        })
        .subscribe(data => {
          //this.router.navigateByUrl("/home/company/"+this.inspection.company_id+"/inspection/"+this.inspectionID);
        })
    );
  }

  checkIfAssigned() {
    this.sub.add(
      this.companyService
        .getInspectionViewModel(this.route.params.map(params => params["code"]))
        .subscribe(model => {
          this.inspection = model.singleInspection;
          if (
            model.schedule &&
            model.schedule.active_booking &&
            model.schedule.active_booking.inspector_id
          )
            this.update(true);
          else this.update(false);
        })
    );
  }

  update(remove: boolean) {
    this.isEdit = false;

    this.modalService.confirmDialog(
      "Aggiorna l'insoorer a cui assegnare la rilevazione?",
      "Procedi con l'aggiornamento",
      () => {
        this.loaderService.displayLoader(true);

        if (remove && this.idInspector != "") {
          this.sub.add(
            this.companyService
              .removeAssignment(this.inspectionID, this.inspection.company_id)
              .finally(() => {})
              .subscribe(
                date => {
                  this.updateInsoorer();
                },
                err => {
                  let error = err.json().data;
                  if (error.is_business_error) {
                    this.modalService.simpleModal(
                      `Si è verificato un errore (${error.error_code})`,
                      `${error.error_message}`
                    );
                  } else {
                    this.modalService.simpleModal(
                      `Errore (${error.error_code})`,
                      "Si è verificato un errore"
                    );
                  }
                }
              )
          );
        } else if (!remove && this.idInspector != "") {
          if (this.idInspector) {
            this.sub.add(
              this.companyService
                .assign(
                  this.inspectionID,
                  this.inspection.company_id,
                  this.idInspector
                )
                .finally(() => {
                  this.loaderService.displayLoader(false);
                  this.inspector = this.nameInspector;
                })
                .subscribe(
                  date => {},
                  err => {
                    let error = err.json().data;
                    if (error.is_business_error) {
                      this.modalService.simpleModal(
                        `Si è verificato un errore (${error.error_code})`,
                        `${error.error_message}`
                      );
                    } else {
                      this.modalService.simpleModal(
                        `Errore (${error.error_code})`,
                        "Si è verificato un errore"
                      );
                    }
                  }
                )
            );
          }
        } else {
          this.sub.add(
            this.companyService
              .removeAssignment(this.inspectionID, this.inspection.company_id)
              .finally(() => {
                this.loaderService.displayLoader(false);
              })
              .subscribe(
                date => {
                  this.loaderService.displayLoader(false);
                },
                err => {
                  let error = err.json().data;
                  if (error.is_business_error) {
                    this.modalService.simpleModal(
                      `Si è verificato un errore (${error.error_code})`,
                      `${error.error_message}`
                    );
                  } else {
                    this.modalService.simpleModal(
                      `Errore (${error.error_code})`,
                      "Si è verificato un errore"
                    );
                  }
                }
              )
          );
        }
      }
    );
  }

  updateInsoorer() {
    if (this.idInspector) {
      this.sub.add(
        this.companyService
          .assign(
            this.inspectionID,
            this.inspection.company_id,
            this.idInspector
          )
          .finally(() => {
            this.loaderService.displayLoader(false);
            this.inspector = this.nameInspector;
          })
          .subscribe(
            date => {},
            err => {
              let error = err.json().data;
              if (error.is_business_error) {
                this.modalService.simpleModal(
                  `Si è verificato un errore (${error.error_code})`,
                  `${error.error_message}`
                );
              } else {
                this.modalService.simpleModal(
                  `Errore (${error.error_code})`,
                  "Si è verificato un errore"
                );
              }
            }
          )
      );
    }
  }

  updatePerito(perito: string) {
    if (perito) {
      this.sub.add(
        this.claimAdjustersService
          .assign(this.inspectionID, this.inspection.company_id, this.idPerito)
          .finally(() => {
            this.router.navigateByUrl("/home/territory/toclaim");
          })
          .subscribe(
            date => {},
            err => {
              let error = err.json().data;
              if (error.is_business_error) {
                this.modalService.simpleModal(
                  `Si è verificato un errore (${error.error_code})`,
                  `${error.error_message}`
                );
              } else {
                this.modalService.simpleModal(
                  `Errore (${error.error_code})`,
                  "Si è verificato un errore"
                );
              }
            }
          )
      );
    }
  }

  reset() {
    this.data$.next();
    this.searchStr2 = "";
    this.searchStr = "";
    document.getElementById("txtInsoorer").style.visibility = "visible";
    document.getElementById("txtInsoorer").focus();
    this.nameInspector = "";
    this.resultsFetched = false;
    this.disabled = true;
    this.statusBtnAdd = true;
    this.idInspector != "";
  }

  resetPerito() {
    this.dataPerito$.next();
    this.searchStrPerito2 = "";
    this.searchStrPerito = "";
    document.getElementById("txtPerito").style.visibility = "visible";
    document.getElementById("txtPerito").focus();
    this.resultsFetchedPerito = false;
    this.disabled = true;
    this.statusBtnAdd = true;
  }

  SearchPerito(event) {
    if (event.key == "Backspace") this.resetPerito();

    if (this.searchStrPerito.length > 2) {
      document.getElementById("txtPerito").style.visibility = "hidden";
      document.getElementById("txtAutocompletePerito").focus();
    }

    if (
      !this.resultsFetchedPerito &&
      event.key != "Backspace" &&
      this.searchStrPerito2.length > 0
    ) {
      this.claimAdjustersService.search(this.searchStrPerito).subscribe(
        data => {
          data.forEach(item => {
            if (item.first_name) {
              let perito: InsoorerNameSurname = {
                surname: item.last_name,
                nameSurname: item.last_name + " " + item.first_name,
                id: item.adjuster_id
              };
              if (perito) {
                this.peritoList.push(perito);
              }
            }
          });

          if (this.peritoList && this.peritoList.length > 0)
            this.dataPerito$.next(this.peritoList);
        },
        err => {}
      );

      this.resultsFetchedPerito = true;
      this.peritoList = [];
    }

    this.searchStrPerito2 = this.searchStrPerito;
  }

  SearchUsers(event) {
    if (event.key == "Backspace") this.reset();

    if (this.searchStr.length > 2) {
      document.getElementById("txtInsoorer").style.visibility = "hidden";
      document.getElementById("txtAutocomplete").focus();
    }

    this.searchStr2 = this.searchStr;

    if (
      !this.resultsFetched &&
      event.key != "Backspace" &&
      this.searchStr2.length > 0
    ) {
      this.dashboardService.getInsoorerList(this.searchStr).subscribe(
        data => {
          data.forEach(item => {
            if (item.first_name) {
              let insoorer: InsoorerNameSurname = {
                surname: item.last_name,
                nameSurname: item.last_name + " " + item.first_name,
                id: item.id
              };
              if (insoorer) {
                this.insoorerList.push(insoorer);
              }
            }
          });

          if (this.insoorerList && this.insoorerList.length > 0)
            this.data$.next(this.insoorerList);
        },
        err => {}
      );

      this.resultsFetched = true;
      this.insoorerList = [];
    }
    this.searchStr2 = this.searchStr;
  }

  selectUsers(event) {
    if (event && event.originalObject)
      this.idInspector = event.originalObject.id;
    this.nameInspector = event.originalObject.nameSurname;
    this.disabled = false;
    this.statusBtnAdd = false;
  }

  selectPerito(event) {
    if (event && event.originalObject) this.idPerito = event.originalObject.id;
    this.namePerito = event.originalObject.nameSurname;
    this.disabled = false;
    this.statusBtnAdd = false;
  }

  addPerito() {
    this.loaderService.displayLoader(true);

    this.updatePerito(this.idPerito);
  }

  isDisabled() {
    return this.disabled;
  }

  oServicePrice(idService) {
    let price = 0;

    for (let myService of this.optional_services) {
      if (myService.id == idService) price = myService.price;
    }

    return price;
  }

  isAccepted(idService) {
    let accepted: boolean = false;

    if (this.acceptedServices) {
      for (let myService of this.acceptedServices) {
        if (myService.service_id == idService && myService.is_accepted == true)
          accepted = true;
      }
    }

    return accepted;
  }

  isRequired(idService) {
    var required: boolean = false;

    for (let service of this.required_services) {
      if (service.id == idService) required = true;
    }

    return required;
  }

  acceptRefuse(idService, e) {
    if (e.target.checked) {
      if (idService == "0") {
        this.isConfirm = true;
      } else {
        let index = this.approvedServices.indexOf(idService);

        this.approvedServices.splice(index, 1);

        this.approvedServices.push({
          key: idService,
          value: true
        });

        this.totalPrice = this.totalPrice + this.oServicePrice(idService);
        this.price = this.totalPrice.toString();

        var obj = this.mapService;
        Object.assign(obj, { [idService]: true });
      }
    } else {
      if (idService == "0") {
        this.isConfirm = false;
      } else {
        let index = this.approvedServices.indexOf(idService);

        this.approvedServices.splice(index, 1);

        this.approvedServices.push({
          key: idService,
          value: false
        });

        this.totalPrice = this.totalPrice - this.oServicePrice(idService);
        this.price = this.totalPrice.toString();

        var obj = this.mapService;
        Object.assign(obj, { [idService]: false });
      }
    }
  }

  confirmInspection() {
    var OptionalServicesApproval: any[];
    var counter = 0;

    for (let myService of this.approvedServices) {
      if (counter == 0) OptionalServicesApproval = myService;
      else OptionalServicesApproval.push(myService);

      counter++;
    }

    var data;

    if (this.isConfirm || this.inspection.state == "10") {
      this.loaderService.displayLoader(true);

      data = JSON.stringify({ OptionalServicesApproval: this.mapService });

      this.sub.add(
        this.networking
          .post(
            "requests/" + this.companyId + "/" + this.inspectionID + "/accept",
            data,
            this.networking.getAuthenticatedHeader(),
            "application/json",
            Endpoints.default
          )
          .finally(() => {})
          .subscribe(
            data => {
              location.reload();
            },
            err => {
              let error = err.json().data;
              this.loaderService.displayLoader(false); // disable spinner

              if (error.is_business_error)
                this.modalService.simpleModal(
                  `Codice errore (${error.error_code})`,
                  `Tipo di errore: ${error.error_message}`
                );
              else
                this.modalService.simpleModal(
                  `ERRORE`,
                  `Errore generico del server`
                );
            }
          )
      );
    } else {
      data = { Reason: "Rilevazione rifiutata" };

      this.modalService.confirmDialog(
        "Rifiuta rillevazione",
        "Sei sicuro di voler rifiutare questa rilevazione?",
        () => {
          this.loaderService.displayLoader(true);

          this.sub.add(
            this.networking
              .post(
                "requests/" +
                  this.companyId +
                  "/" +
                  this.inspectionID +
                  "/refuse",
                data,
                this.networking.getAuthenticatedHeader(),
                "application/json",
                Endpoints.default
              )
              .finally(() => {})
              .subscribe(
                data => {
                  location.reload();
                },
                err => {
                  let error = err.json().data;
                  this.loaderService.displayLoader(false); // disable spinner

                  if (error.is_business_error)
                    this.modalService.simpleModal(
                      `Codice errore (${error.error_code})`,
                      `Tipo di errore: ${error.error_message}`
                    );
                  else
                    this.modalService.simpleModal(
                      `ERRORE`,
                      `Errore generico del server`
                    );
                }
              )
          );
        }
      );
    }
  }

  refuseInspection(reason: string) {
    let data = {
      Reason: reason
    };

    this.sub.add(
      this.networking
        .post(
          "requests/" + this.companyId + "/" + this.inspectionID + "/refuse",
          data,
          this.networking.getAuthenticatedHeader(),
          "application/json",
          Endpoints.default
        )
        .finally(() => {})
        .subscribe(
          data => {
            location.reload();
          },
          err => {
            this.modalService.simpleModal(
              "Si è verificato un errore",
              "Si è verificato un errore durante l'eliminazione dell'ispezione"
            );
          }
        )
    );
  }

  isDone(idService: string, numb: number = 0) {
    let isDone: boolean = false;
    if (numb == 0) {
      for (let myPhoto of this.photos) {
        if (myPhoto.service_id == idService)
          if (myPhoto.was_uploaded) {
            isDone = true;
          } else if (myPhoto.notShootedReasonId) {
            isDone = true;
          }
      }
    } else if (this.completedService.indexOf(numb) !== -1) {
      isDone = true;
    }
    return isDone;
  }

  onGalleryChange(e) {
    this.ico = e.image.alt + ".png";
    this.isStart = false;
  }

  wasRefused(state) {
    return (
      state === InspectionStatus.Rejected ||
      state === InspectionStatus.CanceledRefused
    );
  }

  canBeDownloaded(state) {
    return (
      state === InspectionStatus.Accepted ||
      state === InspectionStatus.ToApprove ||
      state === InspectionStatus.Claimed ||
      state === InspectionStatus.ToClaim ||
      state === InspectionStatus.Rejected
    );
  }

  canBeModified(state) {
    if (state == 12) return true;
    else false;
  }

  canBePeritoAssigned(state) {
    if (state == 6) return true;
    else false;
  }

  deletable() {
    if (this.inspection.state != "13" && this.inspection.state != "7")
      return true;
    else return false;
  }

  canDownloadPdf(state) {
    return state === InspectionStatus.Accepted;
  }

  public get currentStatus() {
    return InspectionStatus.getInspectionStatusString(this.inspection.state);
  }

  public get currentStatusSummarize() {
    return SummarizedInspectionStatus.getSummarizedInspectionStatusString(
      this.inspection.state
    );
  }

  public back() {
    this._location.back();
  }

  displayAbort() {
    if (
      this.inspection.abort_reason_id &&
      this.inspection.abort_service &&
      this.inspection.associated_inspection
    )
      return true;
    else return false;
  }

  displayDetail() {
    console.log(this.inspection.state);
    if (
      this.inspection.state == "4" ||
      this.inspection.state == "5" ||
      this.inspection.state == "6" ||
      this.inspection.state == "50" ||
      this.inspection.state == "51" ||
      this.inspection.state == "9" ||
      this.inspection.state == "3"
    )
      this.isDisplay = true;

    return this.isDisplay;
  }

  getReasonList() {
    this.sub.add(
      this.companyService.getReasonList().subscribe(
        data => {
          this.reasonList = data;
        },
        err => {
          let error = err.json().data;
          if (error.is_business_error) {
            this.modalService.simpleModal(
              `Si è verificato un errore (${error.error_code})`,
              `${error.error_message}`
            );
          } else {
            this.modalService.simpleModal(
              `Errore (${error.error_code})`,
              "Si è verificato un errore"
            );
          }
        }
      )
    );
  }

  manuallyActive() {
    this.loaderService.displayLoader(true);
    this.companyService
      .manuallyActivate(this.companyId, this.inspectionID)
      .subscribe(
        data => {
          location.reload();
        },
        err => {
          this.loaderService.displayLoader(false);
          this.modalService.simpleModal(`Errore`, "Si è verificato un errore");
        }
      );
  }

  getEvents(page: number) {
    this.sub.add(
      this.eventService
        .getInspectionEvents(this.route.params.map(params => params["code"]))
        .finally(() => {})
        .subscribe(data => {
          this.events = data.events;
          this.eventsListMeta = data.meta;
        })
    );
  }

  openMap() {
    this.loaderService.displayLoader(true);

    var data = JSON.stringify({
      lat: this.inspection.latitude,
      lng: this.inspection.longitude
    });

    this.modalService.modalWithComponent(MapModalComponent, data, null);
  }

  openDetail(event: any) {
    var eventDetail = this.events.find(e => e.id == event);
    this.modalService.modalWithComponent(
      EventDetailModalComponent,
      eventDetail,
      null
    );
  }

  photoService(serviceId: string, num: number) {
    eval("this.galleryImages_" + num + " = [];");
    var count = 0;
    for (let myPhoto of this.photos) {
      if (myPhoto.service_id == serviceId && myPhoto.was_uploaded) {
        if (count == 0) this.ico = myPhoto.name + ".png";
        let photoObj = {
          small: myPhoto.url,
          medium: myPhoto.url,
          big: myPhoto.url,
          alt: myPhoto.name
        };
        count++;
        eval("this.galleryImages_" + num).push(photoObj);
      } else if (
        myPhoto.service_id == serviceId &&
        myPhoto.notShootedReasonId
      ) {
        let photoObj = {
          small: "../assets/placeholder.png",
          medium: "../assets/placeholder.png",
          big: "../assets/placeholder.png",
          alt: "Foto non scattata"
        };
        eval("this.galleryImages_" + num).push(photoObj);
        count++;
      }
    }
  }

  getPhotoDetail(serviceId: string, e, type: string) {
    var photoName = "n.d.";
    var myPhotoDetail = "n.d.";

    if (e.image != undefined && e.image.alt != undefined)
      photoName = e.image.alt;
    else photoName = e;

    for (let myPhoto of this.photos) {
      if (
        myPhoto.name == photoName &&
        myPhoto.was_uploaded &&
        myPhoto.service_id == serviceId
      ) {
        switch (type) {
          case "description":
            /* if(myPhoto.notShootedReasonId){
              myPhotoDetail = "Foto non scattata";
            } else {
              myPhotoDetail =  myPhoto.description;
            }*/
            if (myPhoto.description) myPhotoDetail = myPhoto.description;

            break;
          case "lat":
            myPhotoDetail = myPhoto.latitude.toString();
            break;
          case "long":
            myPhotoDetail = myPhoto.longitude.toString();
            break;
        }
      }
    }

    return myPhotoDetail;
  }

  photoDetailR(serviceId: string, e) {
    var photoName = "";

    if (e.image != undefined && e.image.alt != undefined)
      photoName = e.image.alt;
    else photoName = e;

    for (let myPhoto of this.photos) {
      if (
        myPhoto.name == photoName &&
        myPhoto.was_uploaded &&
        myPhoto.service_id == serviceId
      ) {
        if (myPhoto.description) this.fotoDescriptionR = myPhoto.description;

        if (myPhoto.latitude) this.latR = myPhoto.latitude.toString();

        if (myPhoto.longitude) this.longR = myPhoto.longitude.toString();
      }
    }
  }

  photoDetailO(serviceId: string, e) {
    var photoName = "";

    if (e.image != undefined && e.image.alt != undefined)
      photoName = e.image.alt;
    else photoName = e;

    for (let myPhoto of this.photos) {
      if (
        myPhoto.name == photoName &&
        myPhoto.was_uploaded &&
        myPhoto.service_id == serviceId
      ) {
        if (myPhoto.description) this.fotoDescriptionO = myPhoto.description;

        if (myPhoto.latitude) this.latO = myPhoto.latitude.toString();

        if (myPhoto.longitude) this.longO = myPhoto.longitude.toString();
      }
    }
  }

  nextPage() {
    if (!this.eventsListMeta.hasNext) return;
    this.currentPage += 1;
    this.getEvents(this.currentPage);
  }

  prevPage() {
    if (!this.eventsListMeta.hasPrevious) return;
    this.currentPage -= 1;
    this.getEvents(this.currentPage);
  }

  goToPage(page: number) {
    this.getEvents(page);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteInspection(id: string) {
    this.modalService.confirmDialog(
      "Eliminazione ispezione",
      "Sei sicuro di voler eliminare questa ispezione?",
      () => {
        this.performDelete(id);
      }
    );
  }

  performDelete(id: string) {
    this.loaderService.displayLoader(true); // enable spinner
    this.sub.add(
      this.companyService
        .deleteInspection(id, this.inspection.company_id)
        .finally(() => {
          this.loaderService.displayLoader(false); // disable spinner
        })
        .subscribe(
          data => {
            //this.modalService.simpleModal('Ispezione rimossa', 'L\'ispezione è stata rimossa con successo');
            location.reload();
          },
          err => {
            let error = err.json().data;
            this.loaderService.displayLoader(false); // disable spinner

            if (error.is_business_error)
              this.modalService.simpleModal(
                `Codice errore (${error.error_code})`,
                `Tipo di errore: ${error.error_message}`
              );
            else
              this.modalService.simpleModal(
                `ERRORE`,
                `Errore generico del server`
              );
          }
        )
    );
  }

  downloadPhotos() {
    this.loaderService.displayLoader(true); // enable spinner

    this.companyService
      .downloadInspection(this.companyId, this.inspectionID)
      .subscribe(
        download => {
          if (download.data.uri !== undefined) {
            this.loaderService.displayLoader(false); // disable spinner
            window.location.href = download.data.uri;
          } else {
            this.modalService.simpleModal(
              "Errore",
              "Si è verificato un errore durante il download del pacchetto foto (nessun uri di ritorno)"
            );
          }
          this.loaderService.displayLoader(false); // disable spinner
        },
        err => {
          this.loaderService.displayLoader(false); // disable spinner

          let error = err.json().data;
          if (error.isBusinessError) {
            this.modalService.simpleModal("Errore", error.errorMessage);
          } else {
            this.modalService.simpleModal(
              "Errore (Id:" + error.errorCode + ")",
              "Errore durante il download del pacchetto foto"
            );
          }
        }
      );
  }

  downloadPdf() {
    this.loaderService.displayLoader(true); // enable spinner

    this.companyService
      .downloadInspectionReport(this.companyId, this.inspectionID)
      .subscribe(
        download => {
          if (download._body !== null) {
            FileSaver.saveAs(
              download._body,
              "report_" +
                this.inspection.vehicle_description.license_plate +
                "_" +
                this.inspectionID +
                ".pdf"
            );
          } else {
            this.modalService.simpleModal(
              "Errore",
              "Si è verificato un errore durante il download del pdf (nessun body di ritorno)"
            );
          }
          this.loaderService.displayLoader(false); // disable spinner
        },
        err => {
          this.loaderService.displayLoader(false); // disable spinner

          if (err.status === 500) {
            this.modalService.simpleModal(
              "Errore",
              "Si è verificato un errore: " + err.status + " " + err.statusText
            );
          } else {
            let error = err.json().data;
            if (error.isBusinessError) {
              this.modalService.simpleModal("Errore", error.errorMessage);
            } else {
              this.modalService.simpleModal(
                "Errore (Id:" + error.errorCode + ")",
                "Errore durante il download del pdf"
              );
            }
          }
        }
      );
  }
}
