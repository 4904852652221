import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from "@angular/core";
import { Router } from "@angular/router";
import { City } from "../../../Models/City";
import { Subscription, Observable } from "rxjs/Rx";
import { ValuesService } from "../../../Services/Values/values.service";
import { TableStructure } from "../../../Helpers/TableStructure";
import { CompanyService } from "../../../Services/Company/company.service";
import { LocationStrategy } from "@angular/common";
import { LoaderService } from "../../../Ui/loader.service";
import { TerritoryService } from "../../../Services/Territory/territory.service";
import { Inspector } from "../../../Models/Inspector";
import { ActivatedRoute } from "@angular/router";
import { CityInspectionSchedules } from "../../../Models/CityInspectionSchedules";
import * as moment from "moment";

@Component({
  selector: "app-schedules-unassigned",
  templateUrl: "./schedules-unassigned.component.html",
  styleUrls: ["./schedules-unassigned.component.scss"]
})
export class SchedulesUnassignedComponent implements OnInit {
  lat: number = 0;
  lng: number = 0;
  private map: any;

  zoom: number = 0;
  public tableStructure: any[] =
    TableStructure.CityInspectionScheduledNotAssigned;
  public items: any[] = [];
  public meta: any = {};
  public inspectors: Inspector[] = [];
  public inspections: CityInspectionSchedules[] = [];
  sub: Subscription = new Subscription();
  public cities: City[] = [];
  public style: any[] = [];
  public currentCity: string = "";
  public type: string;
  public id: string;
  public currentPage: number = 0;
  public dateFrom = "";
  public dateTo = "";
  public filterActive: boolean = false;

  @Output() applyFilters = new EventEmitter();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private loader: LoaderService,
    private companyService: CompanyService,
    private territoryService: TerritoryService,
    private route: ActivatedRoute,
    private valuesService: ValuesService,
    private router: Router,
    private url: LocationStrategy
  ) {}

  ngOnInit() {
    this.loader.displayLoader(true);
    this.getScheduled(0);
  }

  getScheduled(page = 0) {
    this.sub.add(
      this.companyService
        .getScheduledList(
          page,
          "",
          "",
          this.type,
          this.dateFrom,
          this.dateTo,
          "",
          ""
        )
        .subscribe(
          data => {
            this.loader.displayLoader(false);
            this.items = data.inspections;
            this.meta = data.meta;
          },
          err => {
            this.loader.displayLoader(false);
            let error = err.json().data;
          }
        )
    );
  }

  public get detail_url(): string {
    let url = "../inspection/";
    return url;
  }

  saveFiltersToQueryString(filters: any) {
    let currentRoute = this.url.path().split("?")[0];
    this.router.navigate([currentRoute], {
      queryParams: { date: filters.date }
    });
  }

  updateFilters(event) {
    this.dateFrom = event.dateFrom;
    this.dateTo = event.dateTo;
    this.getScheduled(this.currentPage);
  }

  nextPage() {
    if (!this.meta.hasNext) return;
    this.currentPage += 1;
    this.getScheduled(this.currentPage);
  }

  prevPage() {
    if (!this.meta.hasPrevious) return;
    this.currentPage -= 1;
    this.getScheduled(this.currentPage);
  }

  goToPage(page: number) {
    this.getScheduled(page);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
