import { Component, OnInit } from '@angular/core';
import { DialogRef, ModalComponent, CloseGuard } from 'angular2-modal';
import { LoaderService } from '../../Ui/loader.service';
import { BSModalContext } from 'angular2-modal/plugins/bootstrap';
import { BusinessEvent } from '../../Models/BusinessEvent';


export class MapModalContext extends BSModalContext {
  public data: BusinessEvent;
  
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapModalComponent implements  ModalComponent<MapModalContext> {

  context: MapModalContext;
  public lat: number;
  public lng: number;
  

  constructor(public dialog: DialogRef<MapModalContext>, private loader: LoaderService) {
    setTimeout(()=>{
      this.loader.displayLoader(false);
      this.context = dialog.context;
    var jsonMap = JSON.parse(this.context.data.toString());

    let mapProp = {
			center: new google.maps.LatLng(jsonMap.lat, jsonMap.lng),
			zoom: 14,
			mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    
		let icon = 'assets/assuntiva.png';
    let map = new google.maps.Map(document.getElementById("g-map"), mapProp);
   
		let marker = new google.maps.Marker({
			position: new google.maps.LatLng(jsonMap.lat, jsonMap.lng),
			map: map,
			icon: icon,
    });
    
    }, 500)
    
	}

  
  close() {
		this.dialog.close(false);
	}

}
