import * as moment from "moment";
import { RegistrationStatus } from "../Helpers/Utils";

export class Registration {
  constructor(
    public name: string = "...",
    public surname: string = "...",
    public email: string = "...",
    public registration_date: number = 0,
    public id: string = "...",
    public fiscal_code: string = "...",
    public nation: string = "...",
    public city: string = "...",
    public address: string = "...",
    public cap: string = "...",
    public vat: string = "...",
    public phone: string = "...",
    public paypal: string = "...",
    public role: string = "...",
    public affiliation_code: string = "...",
    public face_pic: string = "",
    public document_front: string = "",
    public document_back: string = "",
    public current_state: string = "",
    public starting_point_address: string = "",
    public registration_review: any = {}
  ) {}

  assignToObject(json: any) {
    this.name = json.name;
    this.surname = json.surname;
    this.email = json.email;
    this.registration_date = json.registration_date;
    this.id = json.id;
    this.fiscal_code = json.fiscal_code;
    this.nation = json.nation;
    this.address = json.address;
    this.city = json.city;
    this.cap = json.cap;
    this.starting_point_address = json.starting_point_address;
    this.vat = json.vat;
    this.phone = json.phone;
    this.paypal = json.paypal ? json.paypal : "ND";
    this.role = json.role;
    this.affiliation_code = json.affiliation_code
      ? json.affiliation_code
      : "ND";
    this.face_pic = json.photos ? json.photos.face_pic : null;
    this.document_front = json.photos ? json.photos.document_front : null;
    this.document_back = json.photos ? json.photos.document_back : null;
    this.current_state = json.current_state;
    this.registration_review = json.registration_review;
  }

  public get refusal_date() {
    return this.registration_review
      ? moment
          .unix(this.registration_review.review_date)
          .format("DD/MM/YYYY | HH:mm")
      : "";
  }

  public get refusal_reason() {
    return this.registration_review
      ? this.registration_review.refusal_reason
      : "";
  }

  public get reviewer_name() {
    return this.registration_review
      ? this.registration_review.reviewer_name
      : "";
  }

  static fromJson(json: any): Registration {
    let registration = new Registration();
    registration.assignToObject(json);
    return registration;
  }

  static fromJsonArray(json: any): Registration[] {
    return json.map(j => {
      return this.fromJson(j);
    });
  }

  public get button_name(): string {
    return "Visualizza";
  }

  public get parsed_date(): string {
    return moment.unix(this.registration_date).format("DD/MM/YYYY | HH:mm");
  }

  public get canAccept() {
    return this.current_state == RegistrationStatus.ToApprove;
  }

  public get isWipState() {
    return this.isWaitingForEmailConfirmation || this.isWaitingForPhotos;
  }

  public get isWaitingForEmailConfirmation() {
    return this.current_state == RegistrationStatus.WaitForEmailConfirmation;
  }

  public get isWaitingForPhotos() {
    return this.current_state == RegistrationStatus.WaitForPhotos;
  }

  public static getListMeta(json: any) {
    let meta = {
      zeroBasedPageIndex: json.meta.zeroBasedPageIndex,
      hasNext: json.meta.hasNext,
      hasPrevious: json.meta.hasPrevious,
      totalPages: json.meta.totalPages
    };
    return meta;
  }
}
