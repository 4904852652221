import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import { UserProfileService } from '../../../Services/User/user-profile.service';
import { Inspection } from '../../../Models/Inspection';
import { LoaderService } from '../../../Ui/loader.service';
import { ModalService } from '../../../Helpers/modal.service';
import { Inspector } from '../../../Models/Inspector';
import { TableStructure } from '../../../Helpers/TableStructure';
import { CompanyService } from '../../../Services/Company/company.service';
import { InspectionStatus } from '../../../Helpers/Utils';

@Component({
	selector: 'app-inspections',
	templateUrl: './inspections.component.html',
	styleUrls: ['./inspections.component.scss']
})
export class InspectionsComponent implements OnInit, OnDestroy {
	public tableStructure : any[] = TableStructure.Inspections;
	private sub: Subscription = new Subscription()
	public total_inspections: number = 0
	public aborted_inspections: number = 0
	public accepted_inspections: number = 0
  public refused_inspections: number = 0
  public expired_inspections: number =0
  public other_inspections: number =0
	public inspections: Inspection[] = []
	public inspector: Inspector = new Inspector()
	public meta : any = {};
	public sortDescending: boolean = true;
	public currentPage: number = 0;

	constructor(private companyService: CompanyService, private userProfileService: UserProfileService, private route: ActivatedRoute, private loader: LoaderService, private modal: ModalService) { }

	ngOnInit() {
		this.getUserStats()
		this.getInspectorData()
		this.getInspectionList()
	}


	private getUserStats() {
		this.loader.displayLoader(true)
		this.sub.add(
			this.userProfileService.getUserStats(this.route.parent.params.map(params => params['id']))
			.finally(() => {
				this.loader.displayLoader(true)
			})
			.subscribe(data => {
				this.total_inspections = data.total_inspections
				this.aborted_inspections = data.aborted_inspections
				this.accepted_inspections = data.accepted_inspections
        		this.refused_inspections = data.refused_inspections
        		this.expired_inspections = data.expired_inspections
        		this.other_inspections = data.other_inspections
			}, err => {
				console.log(err)
			})
		)
	}


	private getInspectorData() {
		this.loader.displayLoader(true)
		this.sub.add(
			this.userProfileService.getUser(this.route.parent.params.map(params => params['id']))
			.finally(()=>{
				this.loader.displayLoader(false)
			})
			.subscribe(data => {
				this.loader.displayLoader(false)
				console.log(data);
				this.inspector = data
			}, err => {
				this.loader.displayLoader(false)
				this.modal.simpleModal('Si è verificato un errore', 'Si è verificato un errore nel recuperare i dati dell\'utente')
				console.log(err)
			})
		)
	}


	nextPage() {
		if(!this.meta.hasNext) return
		this.currentPage+=1
		this.getInspectionList(this.currentPage)
	}

	prevPage() {
		if(!this.meta.hasPrevious) return
		this.currentPage-=1
		this.getInspectionList(this.currentPage)
	}

	goToPage(page: number) {
		this.getInspectionList(page)
	}

	sortBy(type: number) {
		this.sortDescending = !this.sortDescending
		this.getInspectionList(this.currentPage)
	}


	getInspectionList(page = 0) {
		this.loader.displayLoader(true);
		this.sub.add(this.userProfileService.getUserInspections(this.route.parent.params.map(params => params['id']), page, 30)
		.subscribe(
			data => {
				this.loader.displayLoader(false)
				this.inspections = data.inspections;
				this.meta = data.meta;
			}, err => {
				this.loader.displayLoader(false)
				this.modal.simpleModal('errore', 'Si è verificato un errore')
			}
		));
	}

	ngOnDestroy() {
		this.sub.unsubscribe()
	}

}
