import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../Services/Dashboard/dashboard.service';

@Component({
	selector: 'app-payment-list',
	templateUrl: './payment-list.component.html',
	styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit {
	public menu: any;
	constructor(private dashboardService: DashboardService) { }

	ngOnInit() {
		this.menu = this._menu
	}


	public get _menu() {
		let menu = [
			{
				"title" : "Da approvare",
				"router_link" : "/home/payments/toapprove",
				"active_class" : "active",
				"unread" : this.dashboardService.summary.user_payments.to_approve
			},
			{
				"title" : "In lavorazione",
				"router_link" : "/home/payments/pending",
				"active_class" : "active",
				"unread" : this.dashboardService.summary.user_payments.pending
			},
			{
				"title" : "Accettate",
				"router_link" : "/home/payments/accepted",
				"active_class" : "active",
				"unread" : null
			},
			{
				"title" : "Rifiutate",
				"router_link" : "/home/payments/rejected",
				"active_class" : "active",
				"unread" : null
			},
		]
		return menu;
	}

}
