///<reference path="../../../../../node_modules/@types/googlemaps/index.d.ts"/>
import {Component, OnInit, OnDestroy} from '@angular/core';
import {CityService} from '../../../Services/City/city.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ZoneService} from '../../../Services/Zone/zone.service';
import {InspectorService} from '../../../Services/Inspector/inspector.service';
import {ModalService} from '../../../Helpers/modal.service';
import {APP_SETTINGS} from '../../../app.settings';
import {MapsService} from '../../../Services/Maps/maps.service';
import * as cloneDeep from 'lodash/cloneDeep';
import {LoaderService} from '../../../Ui/loader.service';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs/Subject';
import {PolySnapper} from '../../../Services/Maps/polysnapper';
import {IDropdownSettings } from 'ng-multiselect-dropdown';
import * as turf from '@turf/turf';

//import poly = google.maps.geometry.poly;
import { MapCoordinate } from '../../../Models/MapCoordinate';

@Component({
  selector: 'app-city-detail',
  templateUrl: './city-detail.component.html',
  styleUrls: ['./city-detail.component.scss']
})
export class CityDetailComponent implements OnInit, OnDestroy {
  public ps = null; // polysnapper placeholder
  private ngUnsubscribe = new Subject();

  private drawingManager: any;
  private map: any;
  private inCreation = false;
  public ZONE_DEFAULT_COLOR = APP_SETTINGS.TERRITORY_MANAGEMENT.ZONE_DEFAULT_COLOR;
  public selectedInspectors = {};
  public allZones:any = [];
  public zones:any = [];
  public selectedItems:any = [];
  public CreateDepartmentOnStaffomatic:boolean = false;
  public ZoneName:string = "";
  public StaffomaticDepartments: any[] = [];
  public zonesStaffomatic: any[] = [];
  public zonesStaffomaticIdName: any[] = [];
  public dropdownSettings:IDropdownSettings = {};
  public city = {
    id: null,
    name: '',
    mapping_id: '',
    zones: []
  };
  
  public editingZone: any;

  public visibleZones = {};

  constructor(private cityService: CityService,
              private zoneService: ZoneService,
              private inspectorService: InspectorService,
              private route: ActivatedRoute,
              private router: Router,
              private modalService: ModalService,
              private mapsService: MapsService,
              private loaderService: LoaderService
              ) {
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Tutte',
      unSelectAllText: 'Nessuna',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
  
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadAllZones(){
    this.cityService.getZoneCoordinates().subscribe(zones => {
      zones.forEach(
        (zone) => {
          if(zone.id != 8) this.allZones.push(zone);
          
          // show zone by default
        }
      )
      this.showAllZones();
    })
  }

  getStaffomaticZones(id){
    this.cityService.getStaffomaticDepartments(id).subscribe(data=>{
      this.zonesStaffomatic = data;
    })
  }

  loadCity() {
    const cityId: number = +this.route.snapshot.params.id;
    this.loaderService.displayLoader(true);
    this.cityService.get(cityId).takeUntil(this.ngUnsubscribe).finally(() => this.loaderService.displayLoader(false)).subscribe(city => {
      this.getStaffomaticZones(city.external_id);
      console.log(city);
      city.zones.forEach(
        (zone) => {
          zone.coordinates = new google.maps.MVCArray<google.maps.LatLng>(
            zone.coordinates.map((coord) => new google.maps.LatLng(coord.lat, coord.lng)));
          // show zone by default
        }
      );
      this.city = city;
      this.updateZonesColors();
      this.showAllCityZones();
      this.mapsService.setMapCenter(city.mapping_id, this.map);
      //this.initPolysnapper();
    });
  }

  enablePolysnapper(zone = null) {
     const polygons = this.city.zones.map(
      (z) => {
        // exclude passed zone
        if (zone && (z.id === zone.id)) {
          return;
        }

        return new google.maps.Polygon({
          paths: z.coordinates,
          fillColor: z.color,
          strokeColor: z.color
        });
      }).filter(z => z);

    this.ps = PolySnapper(this,{
        marker: new google.maps.Marker(),
        threshold: 300,
        keyRequired: false,
        polygons: polygons,
        hidePOI: true,
        map: this.map,
        onEnabled: () => {
          const polygon = this.ps.polygon();
          polygon.setOptions({
            'strokeColor': this.ZONE_DEFAULT_COLOR,
            'fillColor': this.ZONE_DEFAULT_COLOR
          });
          if (zone) {
            const path = polygon.getPath();
            zone.coordinates.forEach(
              (c) => path.push(c)
            );
          }
        },
        onChange: () => {
          // sync editingZone coordinates
          const polygon = this.ps.polygon();
          this.editingZone.coordinates = cloneDeep(polygon.getPath());
        }
      }
    );
    this.ps.enable(); 
  }

  disablePolysnapper() {
    if (this.ps) {
      this.ps.polygon().setMap(null);
      this.ps.disable();
    }
  }

  updateZonesColors() {
    let colorIndex = 0;
    const ZONE_COLORS = APP_SETTINGS.TERRITORY_MANAGEMENT.ZONES_COLORS;
    const zoneColorsLenght = ZONE_COLORS.length;
    this.city.zones.forEach(
      (zone) => {
        if (colorIndex >= zoneColorsLenght) {
          colorIndex = 0;
        }
        zone.color = ZONE_COLORS[colorIndex++];
      }
    );
  }

  showAllZones() {
    this.allZones.forEach(
      (z) => this.showZone(z)
    );
  }

  showAllCityZones() {
    this.city.zones.forEach(
      (z) => this.showZone(z)
    );
  }

  showZone(zone) {
    zone = cloneDeep(zone);
    this.visibleZones[zone.id] = zone;
    if (this.editingZone.id === zone.id) {
      this.initEditingZone();
    }
  }

  hideZone(zone) {
    delete this.visibleZones[zone.id];
  }

  toggleZoneVisibility(zone) {
    if (this.visibleZones[zone.id]) {
      this.hideZone(zone);
    } else {
      this.showZone(zone);
    }
  }

  addInspectorToZone(inspector, zone) {
    this.inspectorService.zoneAssign(inspector.id, zone.id).takeUntil(this.ngUnsubscribe).subscribe(
      (data) => {
        zone.number_of_assigned_inspectors += 1;
        delete this.selectedInspectors[zone.id];
        this.modalService.simpleModal('Assegnazione effettuata',
          `L'utente ${inspector.fullname} è stato assegnato alla zona ${zone.name}.`);
      }, err => this.modalService.errorModal(err.json().data)
    );
  }

  editZone(zone) {
    const editingZone = cloneDeep(zone);
    this.setInCreation(false);
    this.hideZone(zone);
    this.editingZone.name = "";
    console.log(zone);
    this.editingZone = editingZone;
    this.enablePolysnapper(editingZone);
    this.selectedItems = zone.staffomatic_departments;
    //this.StaffomaticDepartments = zone.staffomatic_departments;
    this.StaffomaticDepartments = [];
    for (let myZone of zone.staffomatic_departments) {
      this.StaffomaticDepartments.push(myZone.id)
    }
    //this.ExternalId =this.zonesStaffomatic.filter(item => item.name == zone.name)[0].id;
  }

  deleteZone(zone) {
    this.zoneService.deleteConfirm(zone, () => {
      this.city.zones.splice(this.city.zones.indexOf(zone), 1);
      this.hideZone(zone);
      this.setInCreation(false);
    });
  }

  deleteCity(city): void {
    this.cityService.deleteConfirm(this.city, () => {
      this.router.navigate(['/home/territory/city']);
    });
  }

  coordsToPoly(coords) {
    const coordsArray = coords.getArray().map(
      (x) => {
        return [x.lng(), x.lat()];
      }
    ).filter(
      // ensure unique values
      (el, pos, arr) => arr.findIndex((v) => (el[0] === v[0]) && (el[1] === v[1])) === pos
    );
    coordsArray.push(coordsArray[0]);
    return turf.polygon(
      [coordsArray]
    );
  }

  coordinatesOverlaps(testZone) {
    const testZonePolygon = this.coordsToPoly(testZone.coordinates);

    // check if almost one point in coordinates is contained by an already existing polygon
    for (const zone of this.city.zones) {
      if (testZone.id === zone.id) {
        continue;
      }
      const zonePolygon = this.coordsToPoly(zone.coordinates);
      let intersection;
      try {
        intersection = turf.intersect(testZonePolygon, zonePolygon);
      } catch (err) {
        this.modalService.simpleModal('Attenzione', 'Il poligono in creazione non è valido.');
        throw err;
      }
      // if intersection is not null and returned geometry type is a Polygon
      // the two polygons overlaps
      if (intersection && intersection['geometry']['type'] === 'Polygon') {
        return true;
      }
    }
    return false;
  }

  zoneFormSubmit(form) {
    
    console.log("ZONA MODIFICATA",this.editingZone.id)
  /*    if (this.coordinatesOverlaps(this.editingZone)) {
      this.modalService.simpleModal('Attenzione', 'Uno o più lati del poligono si intersecano con un altro poligono.');
      return;
    } 
 */
    const coordinates = this.editingZone.coordinates
      .getArray()
      .map((x: google.maps.LatLng) => {
        return {lat: x.lat(), lng: x.lng()};
      }).filter(
        // ensure unique values
        (el, pos, arr) => arr.findIndex((v) => (el['lat'] === v['lat']) && (el['lng'] === v['lng'])) === pos
      );

    const data: any = {
      cityId: this.city.id,
      StaffomaticDepartments: this.StaffomaticDepartments,
      zoneName: this.editingZone.name,
      CreateDepartmentOnStaffomatic: this.CreateDepartmentOnStaffomatic,
      StaffomaticZoneName: this.editingZone.name,
    };
    this.loaderService.displayLoader(true);

    if (this.editingZone.id) {
      console.log("MODIFICA", this.StaffomaticDepartments);
      data['StaffomaticDepartments'] = this.StaffomaticDepartments;
      data['zoneId'] = this.editingZone.id;
      data['UpdatedPointCoordinates'] = coordinates;
      this.zoneService.edit(data)
        .takeUntil(this.ngUnsubscribe)
        .finally(() => this.loaderService.displayLoader(false))
        .subscribe(
          (zone) => {
            const originalZone = this.city.zones.find((i) => i.id === this.editingZone.id);
            this.loadCity();
            Object.assign(originalZone, this.editingZone);
            this.showZone(cloneDeep(this.editingZone));
            this.initEditingZone();
            this.selectedItems = [];
            this.StaffomaticDepartments = [];
            
          }, err => this.modalService.errorModal(err.json().data)
        );
    } else {
      var geoJsonCompliantCoordinates = this.createGeoJsonCompliantCoordinates(coordinates);

      data['StaffomaticDepartments'] = this.StaffomaticDepartments;
      data['PointCoordinates'] = geoJsonCompliantCoordinates;
      // add closing loop point
      coordinates.push(coordinates[0]);
      data['PointCoordinates'] = coordinates;
      this.zoneService.create(data)
        .takeUntil(this.ngUnsubscribe)
        .finally(() => this.loaderService.displayLoader(false))
        .subscribe(
          (zone) => {
            const newZone = {
              StaffomaticDepartments: this.StaffomaticDepartments,
              CreateDepartmentOnStaffomatic: this.CreateDepartmentOnStaffomatic,
              StaffomaticZoneName: zone.zone_name,
              id: parseInt(zone.zone_id, 10),
              city_id: parseInt(zone.city_id, 10),
              name: zone.zone_name,
              number_of_assigned_inspectors: 0,
              coordinates: cloneDeep(this.editingZone.coordinates)
            };
            this.city.zones.push(newZone);
            this.initEditingZone();
            this.setInCreation(false);
            this.updateZonesColors();
            this.showZone(newZone);
            this.activeCity(zone.city_id);
          }, err => this.modalService.errorModal(err.json().data)
        );
    }
  }

  activeCity(cityId:string){
    this.cityService.activate(cityId).subscribe(data=>{
      //this.setRealTime(cityId);
    })
  }

  setRealTime(cityId:string){
    this.cityService.setRealTime(cityId).subscribe(data=>{
    })
    
  }

  createGeoJsonCompliantCoordinates(coordinates: MapCoordinate[]): MapCoordinate[] {
    var firstPoint = coordinates[0];

    var isToClosePolygon = this.checkIfIsToClosePolygon(coordinates);
    let res: MapCoordinate[] = new Array();

    if (isToClosePolygon = true) {
      coordinates.shift();
      coordinates.reverse();
    }
    else{
      coordinates.pop();
      coordinates.shift();
      coordinates.reverse();
    }

    res.push(firstPoint);
    coordinates.forEach(element => {
      res.push(element);
    });
    res.push(firstPoint);

    return res;
  }

  checkIfIsToClosePolygon(coordinates: MapCoordinate[]): boolean {
    var lastCoords = coordinates[coordinates.length-1];
    var firstCoords = coordinates[0];

    return lastCoords.lat != firstCoords.lat && lastCoords.lng != firstCoords.lng;
  }

  mapReady(map: google.maps.Map) {
    this.loadAllZones();
    // set default map zoom
    
    map.setZoom(9);

    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        editable: true,
        fillColor: APP_SETTINGS.TERRITORY_MANAGEMENT.ZONE_DEFAULT_COLOR,
        strokeColor: APP_SETTINGS.TERRITORY_MANAGEMENT.ZONE_DEFAULT_COLOR
      }
    });
    // drawingManager.setMap(map);
    google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
      // extract array of coordinates
      this.editingZone.coordinates = polygon.getPaths().getAt(0);
      polygon.setMap(null);
      polygon = null;
      drawingManager.setMap(null);

    });
    this.drawingManager = drawingManager;
    this.map = map;
    this.initEditingZone();
    this.loadCity();
  }

  initEditingZone() {
    this.disablePolysnapper();
    this.editingZone = {
      id: null,
      name: '',
      coordinates: new google.maps.MVCArray<google.maps.LatLng>()
    };
  }

  setInCreation(inCreation: boolean) {
    const drawingManagerOptions: any = {};
    this.inCreation = inCreation;
    if (inCreation) {
      // drawingManagerOptions['drawingControl'] = true;
      // this.drawingManager.setMap(this.map);
      this.enablePolysnapper();
    } else {
      // drawingManagerOptions['drawingControl'] = false;
      // this.drawingManager.setMap(null);
      this.initEditingZone();
      this.disablePolysnapper();
    }
    this.drawingManager.setOptions(drawingManagerOptions);
  }

  onZoneNameChange($event, isStaffomatic:boolean=false) {
    //this.CreateDepartmentOnStaffomatic = false;
    console.log("PRIMA")
    console.log(this.StaffomaticDepartments)

    let index = this.StaffomaticDepartments.indexOf($event.id);

    if(index>-1) this.StaffomaticDepartments.splice(index,1);	
    else this.StaffomaticDepartments.push($event.id);

    if (this.StaffomaticDepartments.length && !this.inCreation) {    
      this.setInCreation(true);
    } else if(!this.inCreation || !this.StaffomaticDepartments.length) {
      this.setInCreation(false);
    }



    if(isStaffomatic){
      //this.ZoneName = this.zonesStaffomatic.filter(item => item.id == value)[0].name;
      if(!this.editingZone.name) this.editingZone.name = this.ZoneName;

    }  else {
      this.CreateDepartmentOnStaffomatic = true;
    }

    console.log("DOPO")
    console.log(this.StaffomaticDepartments)

    /* const value = $event.target.value;
    if (value.length && !this.inCreation) {
      this.setInCreation(true);
    } else if (!this.inCreation || !value.length) {
      this.setInCreation(false);
    } */
/* 
    console.log(value);

    if(isStaffomatic){
      this.ExternalId = value;
      this.ZoneName = this.zonesStaffomatic.filter(item => item.id == value)[0].name;
      this.editingZone.name = this.ZoneName;
    }  else {
      this.CreateDepartmentOnStaffomatic = true;
    } */
  }

  getStaffomaticZone(id):string{

    let myName = "";
    if(this.zonesStaffomatic.length && id){
      if(this.zonesStaffomatic.filter(item => item.id == id))
       myName = this.zonesStaffomatic.filter(item => item.id == id)[0].name;
      return myName;
    }
  }
}
