import { Component, OnInit, Output } from '@angular/core';
import { DialogRef, ModalComponent, CloseGuard } from 'angular2-modal';
import { BSModalContext } from 'angular2-modal/plugins/bootstrap';
import { LoaderService } from '../../../../Ui/loader.service';
import * as moment from 'moment';

export class CustomModalContext extends BSModalContext {
	public data: any;
	public reasonList: any[];
}

@Component({
	selector: 'app-single-photo-modal',
	templateUrl: './single-photo-modal.component.html',
	styleUrls: ['./single-photo-modal.component.scss']
})

export class SinglePhotoModalComponent implements ModalComponent<CustomModalContext> {
	context: CustomModalContext;
	public data : string;
	public latitude: number;
	public longitude: number;
	public dateTime: number;
	public url: string;
	public description: string;
	public name: string;
	public picAvailable: boolean = true;
	public notShootedReasonId: number;
	public reasonList: any[];
	public id: string = "";


	constructor(public dialog: DialogRef<CustomModalContext>, private loader: LoaderService) {
		this.context = dialog.context;
		this.latitude = this.context.data.latitude;
		this.longitude = this.context.data.longitude;
		this.dateTime = this.context.data.dateTime;
		this.url = this.context.data.url;
		this.description = this.context.data.description;
		this.name = this.context.data.name;
		this.notShootedReasonId = this.context.data.notShootedReasonId;
		this.reasonList = this.context.reasonList;
		this.id = this.context.data.id
	}

	close() {
		this.dialog.close(false);
	}

	public get inspection_date() {
		console.log(this.context.data.dateTime)
		return moment.unix(this.dateTime).format('DD/MM/YYYY')
	}

	public get inspection_time() {
		return moment.unix(this.dateTime).format('HH:mm:ss')
	}
	parseCoord(coord: number) {
		return coord.toFixed(4);
	}

	public get showPhoto() {
		if (this.name.indexOf("context") !=-1) {
			return false;
		}
		return true;
	}

	public get notShootedReason() {
		let self = this;
			return this.reasonList.filter(function(v) {
			    return v.id === self.notShootedReasonId; // Filter out the appropriate one
			})[0].value;
	}



}
