import {Component, OnDestroy, OnInit} from '@angular/core';
import {DashboardService} from '../../../Services/Dashboard/dashboard.service';
import {ActivatedRoute} from '@angular/router';
import {LoaderService} from '../../../Ui/loader.service';
import {UserProfileService} from '../../../Services/User/user-profile.service';
import {Inspector} from '../../../Models/Inspector';
import {Subject} from 'rxjs/Subject';
import {ValuesService} from '../../../Services/Values/values.service';
import {InspectorService} from '../../../Services/Inspector/inspector.service';
import {APP_SETTINGS} from '../../../app.settings';
import {environment} from '../../../../environments/environment';
import {CityService} from '../../../Services/City/city.service';
import {ModalService} from '../../../Helpers/modal.service';
import {MapsService} from '../../../Services/Maps/maps.service';

@Component({
  selector: 'app-user-zones',
  templateUrl: './user-zones.component.html',
  styleUrls: ['./user-zones.component.scss']
})
export class UserZonesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  private inspectorId;
  private map;

  public user: Inspector = new Inspector();
  public userCity;
  public assignedZones = [];
  public cities = [];
  public selectedCityZones = [];
  public zoneAssignmentForm;

  constructor(private dashboardService: DashboardService,
              private route: ActivatedRoute,
              private loader: LoaderService,
              private valuesService: ValuesService,
              private userProfileService: UserProfileService,
              private inspectorService: InspectorService,
              private cityService: CityService,
              private modalService: ModalService,
              private mapsService: MapsService) {
  }

  ngOnInit() {
    this.inspectorId = this.route.snapshot.parent.params.id;
    this.initZoneAssignmentForm();
    this.loadUser();
    this.loadCities();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initZoneAssignmentForm() {
    this.zoneAssignmentForm = {
      'city_id': '',
      'zone_id': ''
    };
  }

  public mapReady(map: google.maps.Map) {
    this.map = map;
    map.setZoom(environment.gmap_default_start_point.zoom);
    this.loadAssignedZones();
  }

  onCityChange($event) {
    const value = $event.target.value;
    this.loadCityZones(value);
  }

  onAssignmentFormSubmit($event) {
    this.inspectorService.zoneAssign(this.inspectorId, this.zoneAssignmentForm.zone_id).takeUntil(this.ngUnsubscribe).subscribe(
      (data) => {
        const zone = this.selectedCityZones.find((z) => z.id === parseInt(this.zoneAssignmentForm.zone_id, 10));
        const zoneName = zone.name;
        const city = this.cities.find((c) => c.id === parseInt(this.zoneAssignmentForm.city_id, 10));
        const cityName = city.name;
        const assignment = {
          zone_id: this.zoneAssignmentForm.zone_id,
          zone_name: zoneName,
          city_id: this.zoneAssignmentForm.city_id,
          city_name: cityName
        };
        this.assignedZones.push(assignment);
        this.updateAssignedZoneColors();
        this.modalService.simpleModal('Assegnazione effettuata',
          `L'utente ${this.user.first_name} ${this.user.last_name} è stato assegnato alla zona ${zoneName}.`);
        $event.form.reset();
        this.initZoneAssignmentForm();
      }, err => this.modalService.errorModal(err.json().data)
    );
  }

  private setMapCenter(cityId) {
    this.cityService.get(cityId)
      .takeUntil(this.ngUnsubscribe)
      .finally(() => {
        this.loader.displayLoader(false);
      })
      .subscribe(data => {
        this.mapsService.setMapCenter(data.mapping_id, this.map);
      });
  }

  private loadCities() {
    this.loader.displayLoader(true);
    this.valuesService.getCities()
      .takeUntil(this.ngUnsubscribe)
      .finally(() => {
        this.loader.displayLoader(false);
      })
      .subscribe(data => {
        this.cities = data;
      });
  }

  private loadCityZones(cityId) {
    if (!cityId) {
      this.selectedCityZones = [];
      return;
    }

    this.loader.displayLoader(true);
    this.cityService.get(cityId)
      .takeUntil(this.ngUnsubscribe)
      .finally(() => {
        this.loader.displayLoader(false);
      })
      .subscribe(data => {
        this.selectedCityZones = data.zones;
      });
  }

  loadAssignmentCoordinates(assignment) {
    this.loader.displayLoader(true);
    this.cityService.getZone(assignment.city_id, assignment.zone_id).finally(() => this.loader.displayLoader(false))
      .takeUntil(this.ngUnsubscribe)
      .subscribe(data => {
        assignment.coordinates = data.coordinates;
      });
  }


  private loadAssignedZones() {
    this.loader.displayLoader(true);
    this.inspectorService.getAssigedZones(this.inspectorId)
      .takeUntil(this.ngUnsubscribe)
      .finally(() => {
        this.loader.displayLoader(false);
      })
      .subscribe(data => {
        this.assignedZones = data;
        this.updateAssignedZoneColors();
        this.assignedZones.forEach(
          (assignment) => this.loadAssignmentCoordinates(assignment)
        );
        if (data.length) {
          this.setMapCenter(data[0].city_id);
        }
      });
  }

  private loadUser() {
    this.loader.displayLoader(true);
    this.userProfileService.getUser(this.route.parent.params.map(params => params['id']))
      .takeUntil(this.ngUnsubscribe)
      .finally(() => {
        this.loader.displayLoader(false);
      })
      .subscribe(data => {
        this.user = data;
      });
  }

  updateAssignedZoneColors() {
    let colorIndex = 0;
    const ZONE_COLORS = APP_SETTINGS.TERRITORY_MANAGEMENT.ZONES_COLORS;
    const zoneColorsLenght = ZONE_COLORS.length;
    this.assignedZones.forEach(
      (assignment) => {
        if (colorIndex >= zoneColorsLenght) {
          colorIndex = 0;
        }
        assignment.color = ZONE_COLORS[colorIndex++];
      }
    );
  }


  inspectorZoneRemove(assignment) {
    this.inspectorService.zoneRemoveConfirm(
      this.user, {id: assignment.zone_id, name: assignment.zone_name},
      () => {
        this.assignedZones.splice(this.assignedZones.indexOf(assignment), 1);
        this.updateAssignedZoneColors();
      }
    );
  }
}
