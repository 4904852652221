import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AuthService } from '../../Services/Authentication/auth.service';
import { ModalService } from '../../Helpers/modal.service';
import { Subscription } from 'rxjs/Rx';
import { LoaderService } from '../../Ui/loader.service';


@Component({
  selector: 'restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit, OnDestroy {
	restoreForm : FormGroup;
	private sub : Subscription = new Subscription();

    constructor(public formBuilder: FormBuilder, private authService: AuthService, private modalService: ModalService, private loading: LoaderService) {
  	  this.restoreForm = formBuilder.group({
  		  email: ['', Validators.compose([Validators.required, Validators.email])],
  	  });
    }

	restorePassword() {
		this.loading.displayLoader(true);
		this.sub.add(this.authService.restorePassword(this.restoreForm.value.email)
		.finally(() => {
			this.loading.displayLoader(false);
		})
		.subscribe(data => {
			console.log(data);
			this.modalService.simpleModal("Recupero password", "Abbiamo inviato una mail nella tua casella di posta elettronica ti pregiamo di controllare per effettuare il processo di recupero delle credenziali.")
		}, err => {
			console.log(err);
			if(err.json().data.is_business_error) {
				this.modalService.simpleModal(`Si è verificato un errore (${err.error_code})`, `${err.error_message}`)
			} else {
				this.modalService.simpleModal(`Errore (${err.error_code})`, "Si è verificato un errore durante la procedura di recupero password")
			}
		}));
	}


  ngOnInit() {
  }

  ngOnDestroy() {
	  this.sub.unsubscribe();
  }

}
