import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {NetworkingService} from '../Networking/networking.service';
import {ModalService} from '../../Helpers/modal.service';
import {LoaderService} from '../../Ui/loader.service';

@Injectable()
export class ZoneService {

  constructor(private networking: NetworkingService,
              private modalService: ModalService,
              private loaderService: LoaderService) {
  }

  public create(zone): Observable<any> {
    return this.networking.post('cities/addZone', zone, this.networking.getAuthenticatedHeader(), 'application/json').map(data => {
      return data.data;
      // });
    });
  }

  public edit(zone): Observable<any> {
    return this.networking.put('cities/editZone', zone, this.networking.getAuthenticatedHeader(), 'application/json').map(data => {
      return data.data;
    });
  }

  public delete(cityId, zoneId): Observable<any[]> {
    return this.networking.delete(`cities/${cityId}/removeZone/${zoneId}`, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        return data.data;
      });
  }


  public deleteConfirm(zone, completion): void {
    this.modalService.confirmDialog('Cancella zona',
      `Sei sicuro di voler cancellare la zona ${zone.name}?
     la procedura non può essere annullata`,
      () => {
        this.loaderService.displayLoader(true);
        console.log('deleting zone', zone);
        this.delete(zone.city_id, zone.id).finally(() => this.loaderService.displayLoader(false)).subscribe((data) => {
          completion(data);
        }, err => this.modalService.errorModal(err.json().data));
      });
  }


}
