import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-payment-stats',
	templateUrl: './payment-stats.component.html',
	styleUrls: ['./payment-stats.component.scss']
})
export class PaymentStatsComponent implements OnInit {
	@Input() payment: any = {}
	constructor() { }

	ngOnInit() {
	}

}
