import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../Services/User/user.service';
import { LoaderService } from '../../Ui/loader.service';
import { Subscription } from 'rxjs/Rx';
import { Notification } from '../../Models/Notification';
import { DashboardService } from '../../Services/Dashboard/dashboard.service';
import { Router } from '@angular/router';



@Component({
  selector: 'notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})



export class NotificationListComponent implements OnInit, OnDestroy {
	private sub : Subscription = new Subscription();
	public notifications: Notification[];
	public meta : any = {};
	public currentPage: number = 0;
	public goToPage: number = 0;



  constructor(private dashboardService: DashboardService,  private userService: UserService, private loading: LoaderService, private router: Router) {
	  this.getNotificationList()
  }

  ngOnInit() {
	  //swallow
  }

  
  getNotificationList(page: number = 0) {
	
	  this.loading.displayLoader(true);
	  this.sub.add(this.userService.getNotificationList(page, 30) //FIXME put const in enviroment
	  .finally(() => {
		  
		  this.loading.displayLoader(false);
	  })
	  .subscribe(data => {
		  this.notifications = data.notifications;
		  this.meta = data.meta;
	  }));
  }

	
  nextPage() {
	  if(!this.meta.hasNext) return
	  this.meta.currentPage++
	  this.getNotificationList(this.meta.currentPage)
  }

  prevPage() {
	  if(!this.meta.hasPrev) return
	  this.meta.currentPage--
	  this.getNotificationList(this.meta.currentPage)
  }

  directToPage() {
	  this.getNotificationList(this.goToPage)
  }


  openNotification(notification) {
 	 this.loading.displayLoader(true)
 	 this.sub.add(this.dashboardService.setNotificationAsRead(notification.id)
 	 .finally(() => {
 		 this.loading.displayLoader(false)
 	 })
 	 .subscribe(data => {
		 if(!notification.wasRead) {
			 this.dashboardService.notificationToRead--
		 }

		 switch(notification.notification_target_type) {
			 case (0):
			 break;
			 case (1):
			 this.router.navigateByUrl('/home/company/'+notification.targetID);
			 break;
			 case (2):
			 this.router.navigateByUrl('/home/registrations/detail/'+notification.targetID);
			 break;
			 case (3):
			 this.router.navigateByUrl('/home/payments/detail/'+notification.targetID);
			 default:
			 break;
		 }

 		notification.wasRead = true
 	}, err => {
 		console.log(err)
 	}))
  }

  markAllAsRead() {
	 this.loading.displayLoader(true)
	 this.dashboardService.setAllNotificationsAsRead()
	 .finally(() => {
		 this.loading.displayLoader(false)
	 })
	 .subscribe(data => {
		 this.notifications.forEach(notification => {
			 notification.wasRead = true
		 })
	 }, err => {
		 console.log(err)
	 })
  }


  ngOnDestroy() {
	  this.sub.unsubscribe();
  }

}
