
import { Notification } from './Notification';

export class Summary {
    constructor(
		public user_registrations : any = {"to_approve" : 0, "pending" : 0, "wip_mail":0,"wip_photos":0},
		public user_payments : any = {"to_approve" : 0, "pending" : 0},
		public user_managements : any = {"online" : 0, "offline" : 0},
	) {}

	static fromJson(json:any) : Summary {
		let sum = new Summary();
		sum.user_registrations = json.inspectors_registrations_count;
		sum.user_payments = json.payments_count;
		sum.user_managements = json.active_inspectors_count;
		return sum;
	}
}
