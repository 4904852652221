export class WorkHourZone {
  constructor(public id: number = null,
              public name: string = null,
              public work_calendar_rules = []) {
  }

  assignToObject(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.work_calendar_rules = json.work_calendar_rules;
  }

  static fromJson(json: any): WorkHourZone {
    const workHourZone = new WorkHourZone();
    workHourZone.assignToObject(json);
    return workHourZone;
  }


  static fromJsonArray(json: any): WorkHourZone[] {
    console.log(json);
    return json.map(j => {
      return this.fromJson(j);
    });
  }


}
