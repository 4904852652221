import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {NetworkingService} from '../Networking/networking.service';
import {ModalService} from '../../Helpers/modal.service';
import {LoaderService} from '../../Ui/loader.service';
import {Inspector} from '../../Models/Inspector'

@Injectable()
export class InspectorService {

  constructor(private networking: NetworkingService,
              private modalService: ModalService,
              private loaderService: LoaderService) {
  }

  public search(name): Observable<any[]> {
    return this.networking.get(`inspectors/search/${name}`, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        //   const citiesList = City.fromJsonArray(data.data);
        return data.data;
        // });
      });
  }


  public getAssigedZones(inspectorId): Observable<any[]> {
    return this.networking.get(`inspectors/${inspectorId}/zone`,
      this.networking.getAuthenticatedHeader(), 'application/json').map(
      data => {
        return data.data;
      }
    );
  }

  public getInspectorId(inspectorId): Observable<Inspector> {
    return this.networking.get(`inspectors/${inspectorId}?isToBypassCache=true`,
      this.networking.getAuthenticatedHeader(), 'application/json').map(
      data => {
        return data.data;
      }
    );
  }

  public zoneAssign(inspectorId, zoneId): Observable<any[]> {
    return this.networking.post(`inspectors/${inspectorId}/zone/assign?zoneId=${zoneId}`, {},
      this.networking.getAuthenticatedHeader(), 'application/json').map(
      data => {
        return data.data;
      }
    );
  }

  public zoneRemove(inspectorId, zoneId): Observable<any> {
    return this.networking.delete(`inspectors/${inspectorId}/zone/remove?zoneId=${zoneId}`,
      this.networking.getAuthenticatedHeader(), 'application/json').map(
      data => {
        return data.data;
      }
    );
  }

  public zoneRemoveConfirm(inspector, zone, completion): void {
    this.modalService.confirmDialog('Rimuovi utente',
      `L'utente ${inspector.first_name} ${inspector.last_name} sarà rimosso dalla zona ${zone.name}`,
      () => {
        this.loaderService.displayLoader(true);
        this.zoneRemove(inspector.id, zone.id).finally(() => this.loaderService.displayLoader(false)).subscribe((data) => {
          completion(data);
        }, err => this.modalService.errorModal(err.json().data));
      });
  }
}
