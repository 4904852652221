import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from "@angular/core";
import { Router } from "@angular/router";
import { City } from "../../../Models/City";
import { Subscription, Observable } from "rxjs/Rx";
import { ValuesService } from "../../../Services/Values/values.service";
import { TableStructure } from "../../../Helpers/TableStructure";
import { CompanyService } from "../../../Services/Company/company.service";
import { LocationStrategy } from "@angular/common";
import { Inspector } from "../../../Models/Inspector";
import { ActivatedRoute } from "@angular/router";
import { LoaderService } from "../../../Ui/loader.service";
import { Inspection } from "../../../Models/Inspection";
import {
  Convert,
  Export,
  InspectionCompany,
  SummarizedInspectionStatus
} from "../../../Helpers/Utils";
import * as moment from "moment";

@Component({
  selector: "inspection-archive",
  templateUrl: "./inspection-archive.component.html",
  styleUrls: ["./inspection-archive.component.scss"]
})
export class InspectionArchiveComponent implements OnInit, OnDestroy {
  lat: number = 0;
  lng: number = 0;
  private map: any;
  zoom: number = 0;
  public tableStructure: any[] = TableStructure.InspectionArchive;
  public items: any[] = [];
  public meta: any = {};
  public inspectors: Inspector[] = [];
  public inspections: Inspection[] = [];
  sub: Subscription = new Subscription();
  public cities: City[] = [];
  public style: any[] = [];
  public currentCity: string = "";
  public id: string;
  public currentPage: number = 0;
  public date = "";
  public filterActive: boolean = false;
  public dataEdit: any[];
  public type;
  public dateFrom = "";
  public dateTo = "";
  public cost: number = 0;

  @Output() applyFilters = new EventEmitter();

  constructor(
    private loading: LoaderService,
    private changeDetector: ChangeDetectorRef,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private valuesService: ValuesService,
    private router: Router,
    private url: LocationStrategy
  ) {
    this.refreshUi();
  }

  ngOnInit() {
    this.getInspection();
  }

  apply(applyFilterActiveUpdate: boolean = true) {
    this.applyAllFilters(this.date, applyFilterActiveUpdate);
  }

  applyAllFilters(date, applyFilterActiveUpdate: boolean = true) {
    if (applyFilterActiveUpdate) this.filterActive = true;
    let filters = {
      date: date
    };
    this.saveFiltersToQueryString(filters);
    this.applyFilters.emit(filters);
    //this.getInspection (date, 0);
  }

  getInspection(pageNumber: number = 0) {
    this.sub.add(
      this.companyService
        .getAllInspectionList(
          pageNumber,
          "4",
          this.dateFrom,
          this.dateTo,
          2,
          true
        )
        .finally(() => {
          //todo
        })
        .subscribe(
          data => {
            console.log(data.inspections);
            this.inspections = data.inspections;
          },
          err => {
            //todo
            console.error(err);
          }
        )
    );
  }

  saveFiltersToQueryString(filters: any) {
    let currentRoute = this.url.path().split("?")[0];
    this.router.navigate([currentRoute], {
      queryParams: { date: filters.date }
    });
  }

  refreshUi() {
    this.sub.add(
      Observable.timer(1000, 1000).subscribe(val => {
        // this.inspections = this.inspections.filter()
        this.changeDetector.detectChanges();
      })
    );
  }

  resetFilter() {
    this.filterActive = false;
    this.date = "";
    this.cities = undefined;
    this.apply(false);
  }

  public get detail_url(): string {
    let url = "../inspection/";
    return url;
  }

  checkButtonDisable() {
    if (this.date && this.currentCity != "") {
      let from = moment(this.date).unix();
      return true;
    } else return false;
  }

  updateFilters(event) {
    this.type = event.type;
    this.dateFrom = event.dateFrom;
    this.dateTo = event.dateTo;
    this.getInspection(this.currentPage);
  }

  nextPage() {
    if (!this.meta.hasNext) return;
    this.currentPage += 1;
    this.getInspection(this.currentPage);
  }

  prevPage() {
    if (!this.meta.hasPrevious) return;
    this.currentPage -= 1;
    this.getInspection(this.currentPage);
  }

  goToPage(page: number) {
    this.getInspection(page);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  downloadExcel() {
    //console.log("eccomi ")
    this.loading.displayLoader(true);
    var pages = 0;
    this.dataEdit = [{}];

    //let observables: Observable = [];

    //for (let i = 0; i < this.waypointIds.length; i++) {
    //observables.push(this.categoryApi.getCategoryData(this.waypointIds[i]))
    //}

    this.sub.add(
      this.companyService
        .getFullInspectionList(
          "",
          pages,
          SummarizedInspectionStatus.Accepted.toString(),
          this.type,
          this.dateFrom,
          this.dateTo,
          2,
          true,
          ""
        )
        .subscribe(data => {
          //console.log(data.inspections);
          // console.log(data.meta.totalPages);
          pages = data.meta.totalPages;

          data.inspections.forEach(item => {
            // if(item['company_cost'])
            // this.cost = this.cost+parseInt(item['company_cost']);

            let dataInizio;

            if (item["schedule_date"] != null)
              dataInizio = Convert.unixTimestampToLocalDateString(
                item["schedule_date"]
              );
            else
              dataInizio = Convert.unixTimestampToLocalDateString(
                item["creation_date"]
              );

            let myItem = {
              "Data inizio": dataInizio,
              "Data fine": Convert.unixTimestampToLocalDateString(
                item["closing_date"]
              ),
              Targa: item["license_plate"],
              Costo: item["company_cost"],
              Company: InspectionCompany.getCompany(item["company_id"]),
              Indirizzo: item["address"]
            };
            this.dataEdit.push(myItem);
          });

          /*let myItem = {
			"Data": "Costo totale",
			"Costo": this.cost
      }*/

          if (pages > 0) {
            for (let i = 1; i < pages + 1; i++) {
              //console.log("prova"+i);
              // this.getInspectionsRequest(i);
            }
          } else {
            Export.exportAsExcelFile(this.dataEdit);
            this.loading.displayLoader(false);
          }
        })
    );

    // Export.exportAsExcelFile(this.dataEdit);
    this.loading.displayLoader(false);
  }

  getInspectionsRequest(page: number) {
    this.sub.add(
      this.companyService
        .getFullInspectionList(
          "",
          page,
          SummarizedInspectionStatus.Accepted.toString(),
          this.type,
          this.dateFrom,
          this.dateTo,
          2,
          true,
          ""
        )
        .subscribe(data => {
          data.inspections.forEach(item => {
            let dataInizio;

            if (item["schedule_date"] != null)
              dataInizio = Convert.unixTimestampToLocalDateString(
                item["schedule_date"]
              );
            else
              dataInizio = Convert.unixTimestampToLocalDateString(
                item["creation_date"]
              );

            let myItem = {
              "Data inizio": dataInizio,
              "Data fine": Convert.unixTimestampToLocalDateString(
                item["closing_date"]
              ),
              Targa: item["license_plate"],
              Costo: item["company_cost"],
              Company: InspectionCompany.getCompany(item["company_id"]),
              Indirizzo: item["address"]
            };
            this.dataEdit.push(myItem);
          });

          if (data.meta.totalPages == page) {
            Export.exportAsExcelFile(this.dataEdit);
            this.loading.displayLoader(false);
          }
        })
    );
  }
}
