import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './Authentication/login/login.component';
import {SignupComponent} from './Authentication/signup/signup.component';
import {RestoreComponent} from './Authentication/restore/restore.component';
import {DashboardComponent} from './Dashboard/dashboard/dashboard.component';
import {DashboardHomeComponent} from './Dashboard/dashboard-home/dashboard-home.component';
import {DiaryComponent} from './Dashboard/diary/diary.component';
import {NotificationListComponent} from './Dashboard/notification-list/notification-list.component';
import {RegistrationListComponent} from './Dashboard/registration-list/registration-list.component';
import {RegistrationToApproveComponent} from './Dashboard/registration-list/registration-to-approve/registration-to-approve.component';
import {RegistrationComponent} from './Dashboard/registration/registration.component';
import {RegistrationPendingComponent} from './Dashboard/registration-list/registration-pending/registration-pending.component';
import {RegistrationAcceptedComponent} from './Dashboard/registration-list/registration-accepted/registration-accepted.component';
import {RegistrationRejectedComponent} from './Dashboard/registration-list/registration-rejected/registration-rejected.component';
import {RegistrationWorkInProgressWaitForMailConfirmationComponent} from './Dashboard/registration-list/registration-wip-wait-email-confirmation/registration-wip-wait-email-confirmation.component';
import {RegistrationWorkInProgressWaitForPhotosComponent} from './Dashboard/registration-list/registration-wip-wait-photos/registration-wip-wait-photos.component';
import {PaymentListComponent} from './Dashboard/payment-list/payment-list.component';
import {PaymentToApproveComponent} from './Dashboard/payment-list/payment-to-approve/payment-to-approve.component';
import {PaymentComponent} from './Dashboard/payment/payment.component';
import {PaymentPendingComponent} from './Dashboard/payment-list/payment-pending/payment-pending.component';
import {PaymentAcceptedComponent} from './Dashboard/payment-list/payment-accepted/payment-accepted.component';
import {PaymentRejectedComponent} from './Dashboard/payment-list/payment-rejected/payment-rejected.component';
import {AddCompanyComponent} from './Dashboard/add-company/add-company.component';
import {AddInspectionComponent} from './Dashboard/add-inspection/add-inspection.component';
import {CompanyComponent} from './Dashboard/company/company.component';
import {CompanyProfileComponent} from './Dashboard/company/company-profile/company-profile.component';
import {InspectionToApproveComponent} from './Dashboard/company/inspection-to-approve/inspection-to-approve.component';
import {InspectionToClaimComponent} from './Dashboard/company/inspection-to-claim/inspection-to-claim.component';
import {InspectionClaimedComponent} from './Dashboard/company/inspection-claimed/inspection-claimed.component';
import {InspectionComponent} from './Dashboard/company/inspection/inspection.component';
import {TerritoryManagementComponent} from './Dashboard/territory-management/territory-management.component';
import {InspectionPendingComponent} from './Dashboard/company/inspection-pending/inspection-pending.component';
import {InspectionPendingTComponent} from './Dashboard/territory-management/inspection-pending/inspection-pending.component';
import {InspectionAcceptedComponent} from './Dashboard/company/inspection-accepted/inspection-accepted.component';
import {InspectionRejectedComponent} from './Dashboard/company/inspection-rejected/inspection-rejected.component';
import {InspectionOpenedComponent} from './Dashboard/company/inspection-opened/inspection-opened.component';
import {InspectionExpiredComponent} from './Dashboard/company/inspection-expired/inspection-expired.component';
import {InspectionScheduledComponent} from './Dashboard/company/inspection-scheduled/inspection-scheduled.component';
import{InspectionScheduledPendingComponent} from './Dashboard/company/inspection-scheduled-pending/inspection-scheduled-pending.component';
import {SettingsComponent} from './Dashboard/settings/settings.component';
import {UserSettingsComponent} from './Dashboard/settings/user-settings/user-settings.component';
import {UserManagementComponent} from './Dashboard/settings/user-management/user-management.component';
import {UserProfileComponent} from './Dashboard/user-profile/user-profile.component';
import {UserStateComponent} from './Dashboard/user-profile/user-state/user-state.component';
import {InspectionsComponent} from './Dashboard/user-profile/inspections/inspections.component';
import {AssignedInspectionComponent} from './Dashboard/user-profile/assigned-inspection/assigned-inspection.component';
import {PaymentsComponent} from './Dashboard/user-profile/payments/payments.component';
import {SearchResultComponent} from './Dashboard/search-result/search-result.component';
import {SearchResultListComponent} from './Dashboard/search-result-list/search-result-list.component';
import {NotFoundComponent} from './Dashboard/not-found/not-found.component';
import {CityManagementComponent} from './Dashboard/territory-management/city-management/city-management.component';
import {SauronComponent} from './Dashboard/territory-management/sauron/sauron.component';
import {CityDetailComponent} from './Dashboard/territory-management/city-detail/city-detail.component';
import {ZoneManagementComponent} from './Dashboard/territory-management/zone-management/zone-management.component';
import {UserZonesComponent} from './Dashboard/user-profile/user-zones/user-zones.component';
import {SchedulesComponent} from './Dashboard/territory-management/schedules/schedules.component';
import {SchedulesUnconfirmedComponent } from './Dashboard/territory-management/schedules-unconfirmed/schedules-unconfirmed.component';
import { SchedulesUnassignedComponent } from './Dashboard/territory-management/schedules-unassigned/schedules-unassigned.component';
import {InspectionArchiveComponent} from './Dashboard/territory-management/inspection-archive/inspection-archive.component';
import {AllInspectionToApproveComponent} from './Dashboard/territory-management//inspection-to-approve/inspection-to-approve.component';
import {InspectionDeletedComponent} from './Dashboard/territory-management//inspection-deleted/inspection-deleted.component';


const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'restore-password',
    component: RestoreComponent
  },
  {
    path: 'home',
    component: DashboardComponent,

    children: [
      {
        path: '',
        component: DashboardHomeComponent
      },
      {
        path: 'add-inspection',
        component: AddInspectionComponent
      },
      {
        path: 'company/add',
        component: AddCompanyComponent
      },
      {
        path: 'notifications',
        component: NotificationListComponent
      },
      {
        path: 'inspection',
        component: InspectionComponent
      },
      {
        path: 'diary',
        component: DiaryComponent
      },
      {
        path: 'territory',
        component: TerritoryManagementComponent,
        children: [
          {
            path: '',
            redirectTo: 'sauron',
            pathMatch: 'full'
          },
          {
            path: 'sauron',
            component: SauronComponent
          },
          {
            path: 'city',
            component: CityManagementComponent
          },
          {
            path: 'city/:id',
            component: CityDetailComponent
          },
          {
            path: 'city/:id/zones/:zoneId',
            component: ZoneManagementComponent
          },
          {
            path: 'schedules',
            component: SchedulesComponent
          },
          {
            path: 'schedules-unconfirmed',
            component: SchedulesUnconfirmedComponent
          },
          {
            path: 'schedules-unassigned',
            component: SchedulesUnassignedComponent
          },
          {
            path: 'inspection-archive',
            component: InspectionArchiveComponent
          },
          {
            path: 'inspection-to-approve',
            component: AllInspectionToApproveComponent
          },
          {
            path: 'inspection-pending',
            component: InspectionPendingTComponent
          },
          {
            path: 'inspection-deleted',
            component: InspectionDeletedComponent
          },
          {
            path: 'toclaim',
            component: InspectionToClaimComponent,
          },
          {
            path: 'claimed',
            component: InspectionClaimedComponent,
          }
        ]
      },
      {
        path: 'registrations',
        component: RegistrationListComponent,

        children: [
          {
            path: 'detail/:id',
            component: RegistrationComponent
          },
          {
            path: 'toapprove',
            component: RegistrationToApproveComponent,
          },
          {
            path: 'pending',
            component: RegistrationPendingComponent,
          },
          {
            path: 'accepted',
            component: RegistrationAcceptedComponent,
          },
          {
            path: 'rejected',
            component: RegistrationRejectedComponent,
          },
          {
            path: 'wip-mail',
            component: RegistrationWorkInProgressWaitForMailConfirmationComponent
          },
          {
            path: 'wip-photo',
            component: RegistrationWorkInProgressWaitForPhotosComponent
          }
        ]
      },
      {
        path: 'payments',
        component: PaymentListComponent,
        children: [
          {
            path: 'detail/:id',
            component: PaymentComponent
          },
          {
            path: 'toapprove',
            component: PaymentToApproveComponent,
          },
          {
            path: 'pending',
            component: PaymentPendingComponent,
          },
          {
            path: 'accepted',
            component: PaymentAcceptedComponent,
          },
          {
            path: 'rejected',
            component: PaymentRejectedComponent,
          }
        ]
      },

      {
        path: 'company/:id',
        component: CompanyComponent,
        children: [
          {
            path: '',
            redirectTo: 'profile',
            pathMatch: 'full'
          },
          {
            path: 'profile',
            component: CompanyProfileComponent
          },
          {
            path: 'inspection/:code',
            component: InspectionComponent,
          },
          {
            path: 'opened',
            component: InspectionOpenedComponent
          },
          {
            path: 'toapprove',
            component: InspectionToApproveComponent
          },
          {
            path: 'pending',
            component: InspectionPendingComponent
          },
          {
            path: 'accepted',
            component: InspectionAcceptedComponent
          },
          {
            path: 'rejected',
            component: InspectionRejectedComponent
          },
          {
            path: 'expired',
            component: InspectionExpiredComponent
          },
          {
            path: 'scheduled',
            component: InspectionScheduledComponent
          },
          {
            path: 'scheduledPending',
            component: InspectionScheduledPendingComponent
          },
        ]
      },


      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          {
            path: 'user',
            component: UserSettingsComponent
          },
          {
            path: 'usersmanagement',
            component: UserManagementComponent,
          }
        ]
      },


      {
        path: 'user/:id',
        component: UserProfileComponent,
        children: [
          {
            path: '',
            redirectTo: 'state',
            pathMatch: 'full'
          },
          {
            path: 'state',
            component: UserStateComponent
          },
          {
            path: 'inspections',
            component: InspectionsComponent,
          },
          {
            path: 'inspections/inspection/:code',
            component: InspectionComponent,
          },
          {
            path: 'assigned-inspections',
            component: AssignedInspectionComponent,
          },
          {
            path: 'assigned-inspections/inspection/:code',
            component: AssignedInspectionComponent,
          },
          {
            path: 'registration',
            component: RegistrationComponent
          },
          {
            path: 'payments',
            component: PaymentsComponent,
          },
          {
            path: 'zones',
            component: UserZonesComponent,
          }
        ]
      },

      {
        path: 'search/inspection/:code',
        component: SearchResultComponent
      },
      {
        path: 'searchlist/:type/:id',
        component: SearchResultListComponent
      },
      {
        path: 'notfound/:type/:id',
        component: NotFoundComponent
      }
    ]
  },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
