import {Injectable} from '@angular/core';

@Injectable()
export class MapsService {

  constructor() {
  }

  setMapCenter(placeId, map) {
    function callback(place, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const marker = new google.maps.Marker({
          map: map,
          position: place.geometry.location,
          visible: false
        });
        map.setCenter(marker.getPosition());
      }
    }

    const service = new google.maps.places.PlacesService(map);
    service.getDetails({placeId}, callback);
  }




}
