import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableStructure } from '../../Helpers/TableStructure';
import { Subscription } from 'rxjs/Rx';
import { DashboardService } from '../../Services/Dashboard/dashboard.service';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnDestroy, OnInit {
	public tableStructure : any[] = TableStructure.CompanyCredits;
	public sub : Subscription = new Subscription();
	public id : string = "";
	public menu : any;


	constructor(private dashboardService: DashboardService, private route: ActivatedRoute, private router: Router) {
		//this.getCompanyID();
	}

	ngOnInit() {
		this.menu = this._menu
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

	private get _menu() {
		let menu = [
			{
				"title" : "Impostazioni",
				"router_link" : "/home/settings/user",
				"active_class" : "active",
				"unread" : null,
			},
			{
				"title" : "Gestione utenti",
				"router_link" : "/home/settings/usersmanagement",
				"active_class" : "active",
				"unread" : null,
				'hidden' : !this.dashboardService.operator.isPermitted("BackendOperatorsRead")
			}
		]
		console.log(menu)
		return menu;
	}

}
