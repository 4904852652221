import * as moment from 'moment';
import { EventFriendlyNames } from '../Helpers/Utils';


export class BusinessEvent {
  constructor(
    public id: string = "",
    public name: string = "...",
    public date: number = 0,
    public correlationId: string = "",
    public eventData: any = {}
  ) { }

  assignToObject(json: any) {
    this.id = json.id
    this.name = json.name
    this.date = json.event_date,
      this.correlationId = json.correlation_id
    this.eventData = json.data
  }

  static fromJson(json: any): BusinessEvent {
    let event = new BusinessEvent();
    event.assignToObject(json);
    return event;
  }

  static fromJsonArray(json: any): BusinessEvent[] {
    if (json) {
      return json.map(j => {
        return this.fromJson(j)
      })
    }
  }

  static getListMeta(json: any) {
    let meta = {}
    if (json) {
      meta = {
        "zeroBasedPageIndex": json.zeroBasedPageIndex,
        "hasNext": json.hasNext,
        "hasPrevious": json.hasPrevious,
        "totalPages": json.totalPages,
        "currentElementId": json.currentElementId
      }
    } else {
      meta = {
        "zeroBasedPageIndex": 0,
        "hasNext": false,
        "hasPrevious": false,
        "totalPages": 1,
        "currentElementId": ""
      }
    }
    return meta
  }

  public get parsed_event_date(): string {
    if (this.date > 0)
      return moment.unix(this.date).format('DD/MM/YYYY')

    return "Non disponibile";
  }

  public get parsed_event_hour(): string {
    if (this.date > 0)
      return moment.unix(this.date).format('HH:mm:ss')

    return "Non disponibile";
  }

  public get parsedEventName() {
    return EventFriendlyNames.getDescription(this.name);
  }
}

export class InspectionIdBasedBusinessEvent extends BusinessEvent {

  constructor(
    public id: string = "",
    public name: string = "...",
    public date: number = 0,
    public correlationId: string = "",
    public eventData: any = {},
    public inspectionId: string = ""
  ) {
    super(id, name, date, correlationId, eventData);
  }

  static fromJson(json: any): InspectionIdBasedBusinessEvent {
    let event = new InspectionIdBasedBusinessEvent();
    event.assignToObject(json);
    return event;
  }

  static fromJsonArray(json: any): InspectionIdBasedBusinessEvent[] {
    if (json) {
      return json.map(j => {
        return this.fromJson(j)
      })
    }
  }

  assignToObject(json: any) {
    if (json.data.InspectionRequestId != null)
      this.inspectionId = json.data.InspectionRequestId;
    else
      this.inspectionId = json.data.RequestId;

    super.assignToObject(json);
  }
}
