import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserProfileService } from '../../Services/User/user-profile.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Rx';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
	private sub: Subscription = new Subscription()
	public menu: any;

	constructor(private userProfileService: UserProfileService,  private route: ActivatedRoute) { }

	ngOnInit() {
		this.menu = this._menu
		this.sub.add(
			this.route.params.map(params => params['id'])
			.subscribe(id => {
        this.userProfileService.currentUserID = id
			})
		)
	}

	private get _menu() {
		let menu = [
			{
				"title" : "Stato",
				"router_link" : "state",
				"active_class" : "active",
				"unread" : null
			},
			{
				"title" : "Rilevazioni",
				"router_link" : "inspections",
				"active_class" : "active",
				"unread" : null
			},
			{
				"title" : "Rilevazioni schedulate",
				"router_link" : "assigned-inspections",
				"active_class" : "active",
				"unread" : null
			},
			{
				"title" : "Pagamenti",
				"router_link" : "payments",
				"active_class" : "active",
				"unread" : null
			},
			{
				"title" : "Registrazione",
				"router_link" : "registration",
				"active_class" : "active",
				"unread" : null
			},
      		{
				"title" : "Gestione Zona",
				"router_link" : "zones",
				"active_class" : "active",
				"unread" : null
			}

		]
		return menu;
	}

	ngOnDestroy() {
		this.sub.unsubscribe()
	}
}
