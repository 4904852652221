import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from '../../Services/Dashboard/dashboard.service';
import { Subscription } from 'rxjs/Rx';
import { LoaderService } from '../../Ui/loader.service';

@Component({
	selector: 'dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
	private sub: Subscription = new Subscription();

	constructor( private dashboardService : DashboardService, private loading: LoaderService) {}

	ngOnInit() {
		this.loadSummary();
		this.loadCompanyList();
		this.loadNotificationSummary();
		this.getOperatorData();
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

	loadCompanyList() {
		this.sub.add(this.dashboardService.loadCompanyList()
		.finally(() => {
			//swallow
		})
		.subscribe(data => {
			//swallow
		}));
	}

	loadSummary() {
		this.loading.displayLoader(true)
		this.sub.add(this.dashboardService.loadSummary()
		.finally(() => {
			this.loading.displayLoader(false)
		})
		.subscribe(data => {
			//swallow
		}));
	}

	getOperatorData() {
		this.sub.add(
			this.dashboardService.operatorData()
			.subscribe(data => {
				this.dashboardService.operator = data
			//	console.log("operator", data)
			}, err => {
			//	console.log("err")
			})
		)
	}

	loadNotificationSummary() {
		this.sub.add(this.dashboardService.getNotificationSummary()
		.finally(() => {
			//swallow
		})
		.subscribe(data => {
			//console.log("notifications summary", data)
			//swallow
		}));
	}


}
