import * as moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

export class NotificationType {
  public static readonly RegistrationToProcess = "0";
  public static readonly InspectionExpired = "1";
  public static readonly Credit = "2";

  static getNotificationText(code) {
    if (code == this.RegistrationToProcess)
      return "Registrazione da processare";
    if (code == this.InspectionExpired) return "Ispezione scaduta";
    if (code == this.Credit) return "Pacchetto crediti in scadenza";
  }
  static getNotificationIconClass(code) {
    if (code == this.RegistrationToProcess) return "fa-check";
    if (code == this.InspectionExpired) return "fa-close";
    if (code == this.Credit) return "fa-flag";
  }
}

export class CurrentStatus {
  public static readonly NotAvailable = "0";
  public static readonly Offline = "1";
  public static readonly Online = "2";
  public static readonly InspectionBooked = "3";
  public static readonly InspectionInProgress = "4";

  static getCurrentStatusString(code) {
    if (code == this.NotAvailable) return "Non Disponibile";
    if (code == this.Offline) return "Offline";
    if (code == this.Online) return "Online";
    if (code == this.InspectionBooked) return "Ispezione prenotata";
    if (code == this.InspectionInProgress) return "Ispezione in corso";
  }
}

export class Export {
  static exportAsExcelFile(
    json: any[],
    excelFileName: string = "insoore"
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"]
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array"
    });
    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}

export class Convert {
  static unixTimestampToLocalDateString(unixTimestamp: number) {
    if (unixTimestamp > 0) {
      return moment(new Date(unixTimestamp * 1e3))
        .local()
        .format("DD/MM/YYYY HH:mm");
    } else return "ND";
  }
}

export class RegistrationStatus {
  public static readonly Deleted = "-100";
  public static readonly WaitForEmailConfirmation = "0";
  public static readonly WaitForPhotos = "1";
  public static readonly ToApprove = "2";
  public static readonly InPending = "406";
  public static readonly Accepted = "200";
  public static readonly Rejected = "403";

  static getRegistrationStatusString(code) {
    if (code == this.WaitForEmailConfirmation) return "Mail da confermare";
    if (code == this.WaitForPhotos) return "In attesa foto";
    if (code == this.ToApprove) return "Da approvare";
    if (code == this.InPending) return "In pending";
    if (code == this.Accepted) return "Accettate";
    if (code == this.Rejected) return "Rifiutate";
  }
}

export class PaymentStatus {
  public static readonly ToApprove = "1";
  public static readonly Accepted = "2";
  public static readonly Rejected = "3";
  public static readonly InPending = "4";

  static getRegistrationStatusString(code) {
    if (code == this.ToApprove) return "Da approvare";
    if (code == this.InPending) return "In pending";
    if (code == this.Accepted) return "Accettate";
    if (code == this.Rejected) return "Rifiutate";
  }
}

export class SummarizedInspectionStatus {
  public static readonly Unknown = "0";
  public static readonly Opened = "1";
  public static readonly InspectionInProgress = "2";
  public static readonly WaitingForApproval = "3";
  public static readonly Accepted = "4";
  public static readonly Refused = "5";
  public static readonly Expired = "6";
  public static readonly Scheduled = "7";
  public static readonly Aborted = "9";
  public static readonly ToClaim = "10";
  public static readonly Claimed = "11";
  public static readonly ScheduledPending = "12";

  static getSummarizedInspectionStatusString(code) {
    if (code == this.Unknown) return "Sconosciuto";
    if (code == this.Opened) return "Aperte";
    if (code == this.ToClaim) return "Da periziare";
    if (code == this.Claimed) return "Periziate";
    if (code == this.Accepted) return "Accettate";
    if (code == this.Aborted) return "Eliminate";
    if (code == this.Expired) return "Scadute";
    if (code == this.Refused) return "Rifiutata";
    if (code == this.Scheduled) return "Schedulata";
  }
}

export class InspectionCompany {
  static getCompany(idCompany) {
    if (idCompany == "1") return "Test Company";
    if (idCompany == "2") return "Verti";
    if (idCompany == "3") return "Helvetia";
    if (idCompany == "11") return "HDI Assicurazioni";
    if (idCompany == "12") return "ALD";
    if (idCompany == "13") return "Cattolica Assicurazioni";
    if (idCompany == "14") return "Conte.it";
    if (idCompany == "15") return "UnipolSai";
    if (idCompany == "16") return "Studio Zilioli";
    if (idCompany == "17") return "Sara Assicurazioni";
    if (idCompany == "18") return "OCTOTELEMATICS - AXA";
    if (idCompany == "19") return "LoJack";
    if (idCompany == "20") return "Arval";
    if (idCompany == "21") return "Prima.it";
    if (idCompany == "22") return "ALD Repair";
    if (idCompany == "23") return "CarClinic";
    if (idCompany == "24") return "Aon";
    if (idCompany == "25") return "Vittoria Assicurazioni";
    if (idCompany == "26") return "Toyota";
    if (idCompany == "27") return "Reale Mutua";
    if (idCompany == "28") return "Sara Sinistri";
    if (idCompany == "29") return "Quixa";
    if (idCompany == "30") return "Car Server";
    if (idCompany == "31") return "Aon per Sogessur";
    if (idCompany == "32") return "Padana Assicurazioni";
    if (idCompany == "33") return "Jll";
    if (idCompany == "34") return "Italiana Assicurazioni S.p.A.";
    if (idCompany == "35") return "BPM Assicurazioni";
  }
}

export class InspectionStatus {
  public static readonly Unknown = "0";
  public static readonly Opened = "1";
  public static readonly InPending = "2";
  public static readonly WaitingForPhotos = "3";
  public static readonly ToApprove = "4";
  public static readonly Rejected = "5";
  public static readonly Accepted = "6";
  public static readonly CanceledByOperator = "7";
  public static readonly Canceled = "8";
  public static readonly Expired = "9";
  public static readonly Claimed = "51";
  public static readonly ToClaim = "50";
  public static readonly CanceledRefused = "10";
  public static readonly CanceledWaitingForApproval = "10";
  public static readonly WaitingForScheduledTime = "12";
  public static readonly ScheduledActive = "13";

  static getInspectionStatusString(code) {
    if (code == this.Unknown) return "Sconosciuto";
    if (code == this.Opened) return "Aperte";
    if (code == this.InPending) return "In corso";
    if (code == this.WaitingForPhotos) return "In attesa foto";
    if (code == this.ToApprove) return "Da approvare";
    if (code == this.Rejected) return "Rifiutate";
    if (code == this.Accepted) return "Accettate";
    if (code == this.Claimed) return "Periziate";
    if (code == this.ToClaim) return "Da periziare";
    if (code == this.CanceledByOperator) return "Eliminate";
    if (code == this.Canceled) return "Annullate";
    if (code == this.Expired) return "Scadute";
    if (code == this.ScheduledActive) return "Schedulate";
    if (code == this.WaitingForScheduledTime) return "Schedulate";
    if (code == this.CanceledWaitingForApproval)
      return "Annullate in attesa approvazione";
    if (code == this.CanceledRefused) return "Annullate rifiutate";
  }
}

export class InspectionType {
  public static readonly Assuntiva = "0";
  public static readonly Crash = "1";

  static getInspectionTypeString(code) {
    if (code.id == 0) return "Assuntiva";
    if (code.id == 1) return "Crash";
  }
}

export class UserRole {
  public static readonly Insoorer = "0";
  public static readonly Meccanico = "1";
  public static readonly Installatore = "2";
  public static readonly Carrista = "3";
  public static readonly Carrozziere = "4";
  public static readonly Perito = "5";
  //public static readonly Insoorer = "0;

  static getUserRoleString(code) {
    if (code == this.Insoorer) return "Insoorer";
    if (code == this.Meccanico) return "Meccanico";
    if (code == this.Installatore) return "Installatore";
    if (code == this.Carrista) return "Carrista";
    if (code == this.Carrozziere) return "Carrozziere";
    if (code == this.Perito) return "Perito";
  }
}

export class InspectorRegistrationStatus {
  public static readonly Deleted = "-100;";
  public static readonly NotRegistered = "-1;";
  public static readonly NeedEmailValidation = "0;";
  public static readonly WaitingForPhotoUpload = "1;";
  public static readonly WaitingForApproval = "2;";
  public static readonly Registered = "200;";
  public static readonly PermanentlyRejected = "403;";
  public static readonly WaitingForRequiredChanges = "406;";

  static getStatusAsString(code) {
    if (code == this.Deleted) return "Cancellato";
    if (code == this.NotRegistered) return "Non Registrato";
    if (code == this.NeedEmailValidation) return "In attesa conferma mail";
    if (code == this.WaitingForPhotoUpload)
      return "In attesa upload foto documento";
    if (code == this.WaitingForApproval) return "In attesa di approvazione";
    if (code == this.Registered) return "Attivo";
    if (code == this.PermanentlyRejected) return "Rifiutato definitivamente";
    if (code == this.WaitingForRequiredChanges)
      return "In attesa di modifica registrazione";

    return "Non Disponibile";
  }
}

export class InspectorStatus {
  public static readonly Offline = "1";
  public static readonly Available = "2";
  public static readonly Booked = "3";
  public static readonly Inprogress = "4";

  static getStatusAsString(code) {
    if (code == this.Offline) return "Offline";
    if (code == this.Available) return "Disponibile";
    if (code == this.Inprogress) return "Ispezione in corso";
    if (code == this.Booked) return "Ha una prenotazione attiva";
    return "Non Disponibile";
  }
}

export class EventFriendlyNames {
  static getDescription(eventName: string) {
    var loweredEventName = eventName.toLowerCase();
    switch (loweredEventName) {
      case "scheduledrequestexpired":
        return "Perizia schedulata scaduta";
      case "scheduledrequestbookingcancelled":
        return "Perizia schedulata cancellata";
      case "scheduledinspectionrequeststarted":
        return "Perizia schedulata iniziata";
      case "allinspectionrequestphotouploaded":
        return "Tutte le foto inviate";
      case "backendoperatorcreated":
        return "Creato Operatore Backend";
      case "backendoperatordeleted":
        return "Cancellato Operatore Backend";
      case "backendoperatorgrantprofilechanged":
        return "Cambiato profile utente Backend";
      case "backendoperatorperformedfirstlogin":
        return "Primo Login Operatore Backend";
      case "companyadministratorassigned":
        return "Assegnato amministratore company";
      case "companyadministratordemoted":
        return "Rimosso amministratore company";
      case "companycreated":
        return "Creata Company";
      case "companycreditalmostexhausted":
        return "Credito company quasi terminato";
      case "companycreditexhausted":
        return "Credito company terminato";
      case "companydeleted":
        return "Company cancellata";
      case "creditaddedtocompany":
        return "Aggiunto credito company";
      case "emailconfirmed":
        return "Confermato indirizzo email";
      case "grantednewusertype":
        return "Forniti grant di accesso";
      case "inspectionphotovalidationfailed":
        return "Fallita validazione foto";
      case "inspectionpurchased":
        return "Acquistata inspection";
      case "inspectionrequestaborted":
        return "Annullata perizia";
      case "inspectionrequestaccepted":
        return "Perizia accettata";
      case "inspectionrequestacceptrollbackrequested":
        return "Richiesto rollback acquisto perizia";
      case "inspectionrequestbooked":
        return "Ispezione prenotata";
      case "inspectionrequestschedulebooked":
        return "Perizia schedulata prenotata";
      case "inspectionrequestactivated":
        return "Perizia schedulata attivata";
      case "inspectionrequestbookingcancelled":
        return "Prenotazione annullata";
      case "inspectionrequestbookingexpired":
        return "Prenotazione scaduta";
      case "inspectionrequestcompleted":
        return "Ispezione completata (da inviare)";
      case "inspectionrequestcreated":
        return "Ispezione creata";
      case "inspectionrequestexpired":
        return "Ispezione scaduta";
      case "inspectionrequestphotouploaded":
        return "Foto ispezione caricata";
      case "inspectionrequestrefused":
        return "Ispezione rifiutata";
      case "inspectionrequeststarted":
        return "Ispezione iniziata";
      case "inspectoravailabilitychanged":
        return "Cambiato stato disponibilità inspector";
      case "inspectorcreated":
        return "Creato inspector";
      case "inspectordeleted":
        return "Cancellato inspector";
      case "inspectorlocationupdated":
        return "Posizione inspector aggiornata";
      case "inspectorphotouploaded":
        return "Foto registrazione inspector caricata";
      case "inspectorregistrationstatuschanged":
        return "Aggiornato stato registrazione utente";
      case "inspectorrewardedforinspection":
        return "Aggiunto credito per inspection";
      case "inspectorwithdrawalrequest":
        return "Richiesta riscossione credito";
      case "operatorcreated":
        return "Creato operatore";
      case "operatordeleted":
        return "Cancellato operatore";
      case "operatorperformedfirstlogin":
        return "Primo Login Operatore Company";
      case "resendconfirmationemailrequested":
        return "Richiesto reinvio mail conferma";
      case "resetpasswordrequested":
        return "Richiesta reset password";
      case "revokedusertypegrant":
        return "Revocato grant utente";
      case "uploadphotosexpired":
        return "Scaduto tempo invio foto";
      case "usercreationsucceded":
        return "Utente creato con successo";
      case "userdeleted":
        return "Utente cancellato";
      case "withdrawalrequestaccepted":
        return "Richiesta riscossione accettata";
      case "withdrawalrequestbooked":
        return "Richiesta riscossione prenotata";
      case "withdrawalrequestrefused":
        return "Richiesta riscossione rifiutata";
      case "zoneassignmentremovedfrominspector":
        return "Zona rimossa dall'inspector";
      case "zoneassignedtoinspector":
        return "Zona assegnata all'inspector";
      case "forcefullyacceptedfailed":
        return "FALLITA accept forzata dell'inspection";
      default:
        return eventName;
    }
  }
}
