import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs/Rx";
import { CompanyMock } from "../Mock/CompanyMock";
import { Credit } from "../../Models/Credit";
import { Company } from "../../Models/Company";
import { Inspection } from "../../Models/Inspection";
import { ActivatedRoute } from "@angular/router";
import { DashboardService } from "../../Services/Dashboard/dashboard.service";
import { SingleInspectionViewModel } from "../../Models/SingleInspectionViewModel";
import { Photo } from "../../Models/Photo";
import { ValueMock } from "../Mock/ValueMock";
import { NetworkingService, Endpoints } from "../Networking/networking.service";
import { Operator } from "../../Models/Operator";
import { ResponseContentType } from "@angular/http";
import { environment } from "environments/environment.prod";

@Injectable()
export class CompanyService {
  public currentId: string = "";
  constructor(
    private networking: NetworkingService,
    private route: ActivatedRoute,
    public dashboardService: DashboardService
  ) {}

  public create(
    name: string,
    email: string,
    phone: string,
    inspections: number,
    avatar: File,
    masterName: string,
    masterSurname: string,
    masterEmail: string,
    masterPhone: string
  ): Observable<any> {
    let data = new FormData();
    data.append("companyName", name);
    data.append("companyEmail", email);
    data.append("companyPhone", phone);
    data.append("initialCreditNumber", inspections.toString());
    data.append("administratorFirstName", masterName);
    data.append("administratorLastName", masterSurname);
    data.append("administratorEmail", masterEmail);
    data.append("administratorPhone", masterPhone);
    data.append("file", avatar);

    return this.networking.post(
      "companies",
      data,
      this.networking.getAuthenticatedHeader(),
      "multipart/form-data"
    );
  }

  getInspectionFromPlate(plate: string): Observable<Inspection[]> {
    return this.networking
      .get(
        "requests/search/licensePlates/" + plate,
        this.networking.getAuthenticatedHeader()
      )
      .map(data => {
        return Inspection.fromJsonArray(data.data);
      });
  }

  getSingleCompany(id: Observable<string>): Observable<Company> {
    return id.flatMap(id => this.getCompanyById(id));
  }

  getCompanySummary(id: Observable<string>) {
    return id.flatMap(id => this.getInspectionsSummaryById(id));
  }

  addCredits(id: Observable<string>, credits: number) {
    return id.flatMap(id => {
      return this.networking.post(
        "companies/" + id + "/credits?amount=" + credits,
        {},
        this.networking.getAuthenticatedHeader()
      );
    });
  }

  getInspectionsSummaryById(id: string): Observable<any> {
    return this.networking
      .get(
        "companies/" + id + "/summary/inspections",
        this.networking.getAuthenticatedHeader()
      )
      .map(data => {
        return data.data;
      });
  }

  getCompany(id: Observable<string>): Observable<Company> {
    return id.flatMap(id => {
      return this.getCompanyById(id);
    });
  }

  getCompanyById(id: string): Observable<Company> {
    return this.networking
      .get("companies/" + id, this.networking.getAuthenticatedHeader())
      .combineLatest(this.getCompanyStatsById(id), (data, stats) => {
        let company = Company.fromJson(data.data);
        company.stats = stats;
        return company;
      });
  }

  getCompanyNameById(id: string): Observable<string> {
    return this.networking
      .get("companies/" + id, this.networking.getAuthenticatedHeader())
      .combineLatest(this.getCompanyStatsById(id), (data, stats) => {
        let company = Company.fromJson(data.data);
        company.stats = stats;
        return company.company_name;
      });
  }

  getCompanyStatsById(id: string): Observable<any> {
    return this.networking
      .get(
        "companies/" + id + "/summary",
        this.networking.getAuthenticatedHeader()
      )
      .map(data => {
        return data.data;
      });
  }

  getCreditHistory(
    id: Observable<string>,
    pageNumber: number,
    pageSize: number
  ): Observable<any> {
    let data = {
      pageNumber: pageNumber,
      pageSize: 15
    };
    return id.flatMap(id => {
      return this.networking
        .get(
          "companies/" + id + "/creditsHistory",
          this.networking.getAuthenticatedHeader(),
          "application/json",
          data
        )
        .map(data => {
          let histories = Credit.fromJsonArray(data.data);
          return {
            history: histories,
            meta: Credit.getListMeta(data)
          };
        });
    });
  }

  getAllInspectionList(
    pageNumber: number,
    state = "",
    dateFrom = "",
    dateTo = "",
    sort = 0,
    sortDescending = true
  ): Observable<any> {
    let data = {};
    if (state == "-1") {
      data = {
        pageNumber: pageNumber,
        pageSize: 30,
        from: dateFrom,
        to: dateTo,
        sortby: sort,
        isToSortDescending: sortDescending
      };
    } else {
      data = {
        pageNumber: pageNumber,
        pageSize: 30,
        state: state,
        from: dateFrom,
        to: dateTo,
        sortby: sort,
        isToSortDescending: sortDescending
      };
    }

    return this.networking
      .get(
        "requests/search",
        this.networking.getAuthenticatedHeader(),
        "application/json",
        data
      )
      .map(data => {
        let inspections = Inspection.fromJsonArray(data.data);
        return {
          inspections: inspections,
          meta: Inspection.getListMeta(data)
        };
      });
  }

  getFullInspectionList(
    id: string,
    pageNumber: number,
    state = "",
    type = "",
    dateFrom,
    dateTo,
    sort = 0,
    sortDescending = true,
    address: string
  ): any {
    let data = {};

    if (state == undefined) state = null;

    if (type == "-1") {
      data = {
        pageNumber: pageNumber,
        pageSize: 9999,
        companyId: id,
        from: dateFrom,
        to: dateTo,
        state: state,
        address: address,
        sortby: sort,
        isToSortDescending: sortDescending
      };
    } else {
      data = {
        pageNumber: pageNumber,
        pageSize: 9999,
        companyId: id,
        state: state,
        from: dateFrom,
        to: dateTo,
        type: type,
        address: address,
        sortby: sort,
        isToSortDescending: sortDescending
      };
    }

    return this.networking
      .get(
        "requests/search",
        this.networking.getAuthenticatedHeader(),
        "application/json",
        data
      )
      .map(data => {
        let inspections = Inspection.fromJsonArray(data.data);
        return {
          inspections: inspections,
          meta: Inspection.getListMeta(data)
        };
      });
  }

  getInspectionList(
    id: Observable<string>,
    pageNumber: number,
    state,
    type = "",
    dateFrom,
    dateTo,
    sort = 0,
    sortDescending = true,
    address: string
  ): Observable<any> {
    return id.flatMap(id => {
      let data = {
        pageNumber: pageNumber,
        pageSize: 30,
        companyId: id,
        state: state,
        from: dateFrom,
        to: dateTo,
        type: type,
        address: address,
        sortby: sort,
        isToSortDescending: sortDescending
      };
      return this.networking
        .get(
          "requests/search",
          this.networking.getAuthenticatedHeader(),
          "application/json",
          data
        )
        .map(data => {
          let inspections = Inspection.fromJsonArray(data.data);
          console.log(inspections);
          return {
            inspections: inspections,
            meta: Inspection.getListMeta(data)
          };
        });
    });
  }

  getScheduledList(
    pageNumber: number,
    havePendingConfirmation,
    state,
    type = "",
    dateFrom = "",
    dateTo = "",
    assigned = "",
    booked = "",
    sort = 0,
    sortDescending = true,
    isInProgress = "",
    retrieveOnlyBookedOnes = ""
  ): Observable<any> {
    let data = {
      havePendingConfirmation: havePendingConfirmation,
      assigned: assigned,
      booked: booked,
      retrieveOnlyBookedOnes: retrieveOnlyBookedOnes,
      isInProgress: isInProgress,
      pageNumber: pageNumber,
      pageSize: 30,
      state: state,
      from: dateFrom,
      to: dateTo,
      type: type,
      sortby: sort,
      isToSortDescending: sortDescending
    };

    return this.networking
      .get(
        "requests/scheduled",
        this.networking.getAuthenticatedHeader(),
        "application/json",
        data
      )
      .map(data => {
        let inspections = Inspection.fromJsonArray(data.data);
        console.log(inspections);
        return {
          inspections: inspections,
          meta: Inspection.getListMeta(data)
        };
      });
  }

  getScheduledListByUser(assignedToInspectorId: string): Observable<any> {
    let data = {
      assignedToInspectorId: assignedToInspectorId,
      pageNumber: 0,
      pageSize: 50,
      sortby: 2,
      isToSortDescending: true
    };

    return this.networking
      .get(
        "requests/scheduled",
        this.networking.getAuthenticatedHeader(),
        "application/json",
        data
      )
      .map(data => {
        let inspections = Inspection.fromJsonArray(data.data);
        console.log(inspections);
        return {
          inspections: inspections,
          meta: Inspection.getListMeta(data)
        };
      });
  }

  getClaimedList(
    id: Observable<string>,
    pageNumber: number,
    type = "",
    dateFrom = "",
    dateTo = "",
    sort = 0,
    sortDescending = true,
    adjusterId: string,
    isDone = false
  ): Observable<any> {
    return id.flatMap(id => {
      let data = {
        pageNumber: pageNumber,
        pageSize: 50,
        assessmentCompletedFrom: dateFrom,
        assessmentCompletedTo: dateTo,
        type: type,
        adjusterId: adjusterId,
        sortby: sort,
        isToSortDescending: sortDescending,
        isDone: isDone
      };
      return this.networking
        .get(
          "requests/search/committed",
          this.networking.getAuthenticatedHeader(),
          "application/json",
          data
        )
        .map(data => {
          let inspections = Inspection.fromJsonArray(data.data);
          console.log(inspections);
          return {
            inspections: inspections,
            meta: Inspection.getListMeta(data)
          };
        });
    });
  }

  getToClaimList(
    id: Observable<string>,
    pageNumber: number,
    type = "",
    dateFrom = "",
    dateTo = "",
    sort = 0,
    sortDescending = true,
    adjusterId: string,
    isDone = false
  ): Observable<any> {
    return id.flatMap(id => {
      let data = {
        pageNumber: pageNumber,
        pageSize: 50,
        assessmentAssignedFrom: dateFrom,
        assessmentAssignedTo: dateTo,
        type: type,
        adjusterId: adjusterId,
        sortby: sort,
        isToSortDescending: sortDescending,
        isDone: isDone
      };
      return this.networking
        .get(
          "requests/search/committed",
          this.networking.getAuthenticatedHeader(),
          "application/json",
          data
        )
        .map(data => {
          let inspections = Inspection.fromJsonArray(data.data);
          console.log(inspections);
          return {
            inspections: inspections,
            meta: Inspection.getListMeta(data)
          };
        });
    });
  }

  getAllToClaimList(
    id: Observable<string>,
    type = "",
    dateFrom = null,
    dateTo = null,
    sort = 0,
    sortDescending = true,
    adjusterId: string,
    isDone = false
  ): Observable<any> {
    return id.flatMap(id => {
      let data = {
        pageNumber: 0,
        pageSize: 999,
        assessmentAssignedFrom: dateFrom,
        assessmentAssignedTo: dateTo,
        type: type,
        adjusterId: adjusterId,
        sortby: sort,
        isToSortDescending: sortDescending,
        isDone: isDone
      };
      return this.networking
        .get(
          "requests/search/committed",
          this.networking.getAuthenticatedHeader(),
          "application/json",
          data
        )
        .map(data => {
          let inspections = Inspection.fromJsonArray(data.data);
          console.log(inspections);
          return {
            inspections: inspections,
            meta: Inspection.getListMeta(data)
          };
        });
    });
  }

  getAllClaimedList(
    id: Observable<string>,
    type = "",
    dateFrom = "",
    dateTo = "",
    sort = 0,
    sortDescending = true,
    adjusterId: string,
    isDone = false
  ): Observable<any> {
    return id.flatMap(id => {
      let data = {
        pageNumber: 0,
        pageSize: 999,
        assessmentCompletedFrom: dateFrom,
        assessmentCompletedTo: dateTo,
        type: type,
        adjusterId: adjusterId,
        sortby: sort,
        isToSortDescending: sortDescending,
        isDone: isDone
      };
      return this.networking
        .get(
          "requests/search/committed",
          this.networking.getAuthenticatedHeader(),
          "application/json",
          data
        )
        .map(data => {
          let inspections = Inspection.fromJsonArray(data.data);
          console.log(inspections);
          return {
            inspections: inspections,
            meta: Inspection.getListMeta(data)
          };
        });
    });
  }

  getAddress(
    pageNumber: number,
    sortDescending = true,
    address: string
  ): Observable<any> {
    console.log(address);
    let data = {
      pageNumber: pageNumber,
      pageSize: 30,
      address: address,
      sortby: 3
    };
    return this.networking
      .get(
        "requests/search",
        this.networking.getAuthenticatedHeader(),
        "application/json",
        data
      )
      .map(data => {
        let inspections = Inspection.fromJsonArray(data.data);
        return {
          inspections: inspections,
          meta: Inspection.getListMeta(data)
        };
      });
  }

  getInspectionViewModel(
    inspectionID: Observable<string>
  ): Observable<SingleInspectionViewModel> {
    return inspectionID
      .switchMap(id => this.getInspectionById(id))
      .switchMap(inspection => this.getDecoratedInspection(inspection));
  }

  getDecoratedInspection(inspection) {
    var observableColor = inspection.vehicle_description.color;

    var observableAbortReason = this.getInspectionAbortReasons().map(
      abortList => {
        if (inspection.abort_reason_id != null)
          return abortList.filter(x => x.id == inspection.abort_reason_id)[0]
            .value;
        else return "";
      }
    );

    var observableManufacturer = inspection.vehicle_description.manufacturer;

    var totalPhotos = inspection.associated_inspection
      ? inspection.associated_inspection.photo_catalog
      : null;
    var observablePhotoArray = this.getPhotoArray(
      inspection.id,
      totalPhotos,
      inspection.company_id
    );

    var observableInspection = Observable.of(inspection);

    return observableInspection.combineLatest(
      observableColor,
      observableManufacturer,
      observableAbortReason,
      observablePhotoArray,
      (insp, color, manufacturer, abort, photos) => {
        return new SingleInspectionViewModel(
          insp,
          color,
          manufacturer,
          abort,
          inspection.contact_info,
          inspection.adjuster_commitment,
          photos,
          inspection.required_services,
          inspection.optional_services,
          inspection.abort_service,
          inspection.associated_review,
          inspection.schedule
        );
      }
    );
  }

  getInspectionById(id: string): Observable<Inspection> {
    return this.networking
      .getWithVersion(
        "requests/" + id,
        this.networking.getAuthenticatedHeader(),
        "application/json",
        {},
        Endpoints.default,
        +environment.api_version
      )
      .map(data => {
        return Inspection.fromJson(data.data);
      });
  }

  getPhotoArray(inspectionID, photo, company_id) {
    if (photo && Object.keys(photo).length > 0) {
      let photoObservableList = Object.keys(photo)
        .map(key => photo[key])
        .map(x => {
          if (x.was_uploaded) {
            return this.getPhotoUrlByName(
              inspectionID,
              x.name,
              company_id,
              x.service_id
            ).map(url => {
              return new Photo(
                x.id,
                x.name,
                url,
                true,
                x.latitude,
                x.longitude,
                x.photo_date_time,
                x.description,
                x.not_shoot_reason_id,
                x.service_id
              );
            });
          } else {
            return Observable.of(
              new Photo(
                x.id,
                x.name,
                null,
                false,
                x.latitude,
                x.longitude,
                x.photo_date_time,
                x.description,
                x.not_shoot_reason_id,
                x.service_id
              )
            );
          }
        });

      return Observable.combineLatest(photoObservableList, (...reqs) => {
        return reqs;
      });
    } else {
      return Observable.of([]);
    }
  }

  getPhotoUrlByName(inspectionID, photoName, companyID, serviceID) {
    var data = {
      companyId: companyID,
      requestId: inspectionID,
      photoId: photoName,
      serviceId: serviceID,
      width: 1000,
      height: 800
    };

    return this.networking
      .get(
        "imageProcessing/preview",
        this.networking.getAuthenticatedHeader(),
        "application/json",
        data
      )
      .map(data => {
        if (data.status != 404) {
          return data.data;
        }
        // return data.data
      })
      .retry(5);
  }

  getCompanyList() {
    return Observable.create((observer: Observer<any>) => {
      let company = ValueMock.COLOR_LIST;
      observer.next(company);
      observer.complete();
    })
      .delay(400)
      .map(data => {
        return data.data;
      });
  }

  getColorList() {
    return Observable.create((observer: Observer<any>) => {
      let company = ValueMock.COLOR_LIST;
      observer.next(company);
      observer.complete();
    })
      .delay(400)
      .map(data => {
        return data.data;
      });
  }

  getInspectionAbortReasons() {
    return this.networking
      .get(
        "values/inspectionAbortReasons",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  getNotShotPhotoReasons() {
    return this.networking
      .get(
        "values/notShootPhotoReasons",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  getManufacturersList() {
    return this.networking
      .get(
        "values/vehicleManufacturers",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  getReasonList() {
    return Observable.create((observer: Observer<any>) => {
      let company = CompanyMock.NOT_SHOOTED_REASON_LIST;
      observer.next(company);
      observer.complete();
    })
      .delay(400)
      .map(data => {
        return data.data;
      });
  }

  getCompanyCredits(id: Observable<string>): Observable<any> {
    return id.flatMap(id => {
      return this.getCompanyCreditsById(id);
    });
  }

  getCompanyCreditsById(id: string): Observable<any> {
    return this.networking
      .get(
        "companies/" + id + "/credits",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data;
      });
  }

  getOperatorsList(id: Observable<string>): Observable<any> {
    return id.flatMap(id => {
      return this.networking
        .get(
          "companies/" + id + "/operators",
          this.networking.getAuthenticatedHeader(),
          "application/json"
        )
        .map(data => {
          return {
            operators: Operator.fromJsonArray(data.data),
            meta: data.meta
          };
        });
    });
  }

  assign(idInspection: string, idCompany: string, idInspector: string) {
    return this.networking
      .put(
        "requests/" +
          idCompany +
          "/" +
          idInspection +
          "/assign?inspectorId=" +
          idInspector,
        {},
        this.networking.getAuthenticatedHeader()
      )
      .map(data => {
        return data;
      });
  }

  removeAssignment(idInspection: string, idCompany: string) {
    return this.networking
      .put(
        "requests/" + idCompany + "/" + idInspection + "/removeAssignment",
        {},
        this.networking.getAuthenticatedHeader()
      )
      .map(data => {
        return data;
      });
  }

  deleteInspection(idInspection: string, idCompany: string) {
    return this.networking
      .delete(
        "requests/" + idCompany + "/" + idInspection,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data;
      });
  }

  public setRole(
    companyId: Observable<string>,
    id: string,
    role: string
  ): Observable<any> {
    return companyId.flatMap(company => {
      if (role == "Master")
        return this.networking.put(
          "companies/" + company + "/operators/" + id + "/setAsAdmin",
          {},
          this.networking.getAuthenticatedHeader()
        );
      else if (role == "Supervisor")
        return this.networking.put(
          "companies/" + company + "/operators/" + id + "/setAsSupervisor",
          {},
          this.networking.getAuthenticatedHeader()
        );
      else if (role == "User")
        return this.networking.put(
          "companies/" + company + "/operators/" + id + "/demoteToOperator",
          {},
          this.networking.getAuthenticatedHeader()
        );
    });
  }

  manuallyActivate(companyId: string, inspectionId: string): Observable<any> {
    var data = {};
    return this.networking
      .post(
        "requests/" + companyId + "/" + inspectionId + "/manuallyActivate",
        data,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data;
      });
  }

  public downloadInspection(
    companyId: string,
    inspectionId: string
  ): Observable<any> {
    var data = {};
    return this.networking
      .post(
        "requests/" + companyId + "/download/" + inspectionId,
        data,
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data;
      });
  }

  public downloadInspectionReport(
    companyId: string,
    inspectionId: string
  ): Observable<any> {
    var data = {};
    return this.networking
      .post(
        "requests/" + companyId + "/download/pdf/" + inspectionId,
        data,
        this.networking.getAuthenticatedHeader(),
        "application/pdf",
        Endpoints.default,
        ResponseContentType.Blob
      )
      .map(data => {
        return data;
      });
  }
}
