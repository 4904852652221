import {Injectable} from '@angular/core';
import {NetworkingService} from '../Networking/networking.service';
import {Observable} from 'rxjs/Rx';
import {ModalService} from '../../Helpers/modal.service';
import {LoaderService} from '../../Ui/loader.service';

@Injectable()
export class CityService {

  constructor(private networking: NetworkingService,
              private modalService: ModalService,
              private loaderService: LoaderService) {
  }

  public create(city): Observable<any> {
    return this.networking.post('cities/create', city, this.networking.getAuthenticatedHeader(), 'application/json').map(data => {
      //   const citiesList = City.fromJsonArray(data.data);
      return data.data.created_city;
      // });
    });
  }

  public activate(id): Observable<any> {
    return this.networking.put('cities/'+id+'/activate', {}, this.networking.getAuthenticatedHeader(), 'application/json').map(data => {
      //   const citiesList = City.fromJsonArray(data.data);
      return data.data
      // });
    });
  }

  public setRealTime(id): Observable<any> {
    return this.networking.put('cities/'+id+'/setAsRealtimeCovered?setTo=true', {}, this.networking.getAuthenticatedHeader(), 'application/json').map(data => {
      //   const citiesList = City.fromJsonArray(data.data);
      return data.data
      // });
    });
  }

  public delete(id): Observable<any[]> {
    return this.networking.delete(`cities/delete?cityId=${id}`, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        //   const citiesList = City.fromJsonArray(data.data);
        return data.data;
        // });
      });
  }

  public get(id): Observable<any> {
    return this.networking.get(`cities/${id}`, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        //   const citiesList = City.fromJsonArray(data.data);
        return data.data;
        // });
      });
  }

  public getStaffomaticLocations(): Observable<any[]> {
    return this.networking.get('cities/staffomatic/locations', this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        //   const citiesList = City.fromJsonArray(data.data);
        return data.data;
        // });
      });
  }

  public getStaffomaticDepartments(id): Observable<any[]> {
    return this.networking.get('cities/staffomatic/departments?locationId='+id, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        //   const citiesList = City.fromJsonArray(data.data);
        return data.data;
        // });
      });
  }

  public getZoneCoordinates(): Observable<any[]> {
    return this.networking.get('cities/zoneCoordinates', this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        //   const citiesList = City.fromJsonArray(data.data);
        return data.data;
        // });
      });
  }


  public getCitiesList(): Observable<any[]> {
    return this.networking.get('cities', this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        //   const citiesList = City.fromJsonArray(data.data);
        return data.data;
        // });
      });
  }


  public deleteConfirm(city, completion): void {
    this.modalService.confirmDialog('Cancella città',
      `Sei sicuro di voler cancellare la città ${city.name}?
     la procedura non può essere annullata`,
      () => {
        console.log('deleting city', city);
        this.loaderService.displayLoader(true);
        this.delete(city.id).finally(() => this.loaderService.displayLoader(false)).subscribe((data) => {
          completion(data);
        }, err => this.modalService.errorModal(err.json().data));
      });
  }

  public getZone(cityId, zoneId): Observable<any> {
    return this.networking.get(`cities/${cityId}/${zoneId}`, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        //   const citiesList = City.fromJsonArray(data.data);
        return data.data;
        // });
      });
  }

}
