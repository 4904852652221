import { Component, OnInit } from '@angular/core';
import { MenuStructure } from '../../Helpers/MenuStructure';
import { DashboardService } from '../../Services/Dashboard/dashboard.service';

@Component({
	selector: 'app-registration-list',
	templateUrl: './registration-list.component.html',
	styleUrls: ['./registration-list.component.scss']
})
export class RegistrationListComponent implements OnInit {
	public menu : any;
	constructor(private dashboardService: DashboardService) { }

	ngOnInit() {
		this.menu = this.createMenu()
	}


	private createMenu() {
		let menu = [
			{
				"title" : "Da approvare",
				"router_link" : "/home/registrations/toapprove",
				"active_class" : "active",
				"unread" : this.dashboardService.summary.user_registrations.to_approve
			},
			{
				"title" : "In pending",
				"router_link" : "/home/registrations/pending",
				"active_class" : "active",
				"unread" : this.dashboardService.summary.user_registrations.pending
			},
			{
				"title" : "Accettate",
				"router_link" : "/home/registrations/accepted",
				"active_class" : "active",
				"unread" : null
			},
			{
				"title" : "Rifiutate",
				"router_link" : "/home/registrations/rejected",
				"active_class" : "active",
				"unread" : null
      },
      {
				"title" : "WIP - Conferma mail",
				"router_link" : "/home/registrations/wip-mail",
				"active_class" : "active",
				"unread" : this.dashboardService.summary.user_registrations.wip_mail
			},
      {
				"title" : "WIP - Caricamento foto",
				"router_link" : "/home/registrations/wip-photo",
				"active_class" : "active",
				"unread" : this.dashboardService.summary.user_registrations.wip_photos
			}
		]
		return menu;
	}

}
