import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {NetworkingService} from '../Networking/networking.service';
import {ClaimAdjusters} from '../../Models/ClaimAdjusters'

@Injectable()
export class ClaimAdjustersService {

  constructor(private networking: NetworkingService) {
  }

  public create(name: string, surname:string, phone: string, email: string,): Observable<any> {
 
    var data = {
			"FirstName": name,
			"LastName": surname,
			"phoneNumber" : phone,
			"Email": email
		}

    console.log(data);
    return this.networking.post2('claimAdjusters', data, this.networking.getAuthenticatedHeader())
  }

  public search(name:string) {
    return this.networking.getWithVersion2(`claimAdjusters/search?name=${name}&pageNumber=0&pageSize=99&email=`, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        return data.data;
      });
  }

  public getClaimAdjuster(adjusterId) {
    return this.networking.getWithVersion2(`claimAdjusters/${adjusterId}?isToBypassCache=true`,  this.networking.getAuthenticatedHeader(), 'application/json')
    .map(
      data => {
        return ClaimAdjusters.fromJsonArray(data.data)
      }
    );
  }

  assign(idInspection: string, idCompany:string, idClaimAdjuster:string) {
    return this.networking.put("requests/"+idCompany+"/"+idInspection+"/assignToAdjuster?adjusterId="+idClaimAdjuster, {}, this.networking.getAuthenticatedHeader()).map(
			data => {
				return data;
			}
		);
	}

  removeAssignment(idInspection: string, idCompany:string) {
		return this.networking.put("requests/"+idCompany+"/"+idInspection+"/removeAssignmentFromAdjuster", {}, this.networking.getAuthenticatedHeader()).map(
			data => {
				return data;
			}
		);
	}


  public removeClaimAdjuster(adjusterId): Observable<any> {
    return this.networking.delete(`claimAdjusters/${adjusterId}`,
      this.networking.getAuthenticatedHeader(), 'application/json').map(
      data => {
        return data.data;
      }
    );
  }
}
