import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InspectorStatus } from '../../Helpers/Utils';

@Component({
	selector: 'app-infowindow-inspector',
	templateUrl: './infowindow-inspector.component.html',
	styleUrls: ['./infowindow-inspector.component.scss']
})
export class InfowindowInspectorComponent implements OnInit {
	@Input() name : string = ""
	@Input() status : number = 0
	@Input() phone : string = ""
	@Input() id : string = ""
	@Input() address : string = ""
	@Input() type : string = ""
	@Input() latestUpdateTime : string = ""
	public statusString: string = ""
	@Output() arrowClicked : EventEmitter<any> = new EventEmitter()

	constructor() {
	}

	click() {
		this.arrowClicked.emit({"type" : this.status?'user':'inspection', "id" : this.id});
	}
	ngOnInit() {
		this.statusString = InspectorStatus.getStatusAsString(this.status)
		console.log(InspectorStatus.getStatusAsString(this.status), this.status)
	}

}
