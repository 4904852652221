import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {ArchwizardModule } from 'ng2-archwizard/dist/index';
import {Ng2CompleterModule } from "ng2-completer";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {routing} from './app.routing';
import {PopoverModule} from 'ngx-popover';
import {ModalModule, Overlay} from 'angular2-modal';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import {MatExpansionModule} from 'angular-material-expansion-panel/app/pages';
import {AgmCoreModule} from '@agm/core';
// Auth components
import {AuthHeaderComponent} from './Authentication/auth-header/auth-header.component';
import {LoginComponent} from './Authentication/login/login.component';
import {SignupComponent} from './Authentication/signup/signup.component';
import {RestoreComponent} from './Authentication/restore/restore.component';
//home components
import {DashboardComponent} from './Dashboard/dashboard/dashboard.component';
import {HeaderComponent} from './Dashboard/header/header.component';
import {DashboardService} from './Services/Dashboard/dashboard.service';
import {ClaimAdjustersService} from './Services/ClaimAdjusters/ClaimAdjusters.service';
import {DashboardHomeComponent} from './Dashboard/dashboard-home/dashboard-home.component';
import {DiaryComponent} from './Dashboard/diary/diary.component';
import {AuthService} from './Services/Authentication/auth.service';
import {ModalService} from './Helpers/modal.service';
import {SpinnerComponent} from './Ui/spinner/spinner.component';
import {LoaderService} from './Ui/loader.service';
import {PushNotificationService } from 'ngx-push-notifications';
import {NotificationListComponent} from './Dashboard/notification-list/notification-list.component';
import {UserService} from './Services/User/user.service';
import {RegistrationListComponent} from './Dashboard/registration-list/registration-list.component';
import {SidebarComponent} from './Dashboard/sidebar/sidebar.component';
import {RegistrationToApproveComponent} from './Dashboard/registration-list/registration-to-approve/registration-to-approve.component';
import {TableComponent} from './Dashboard/table/table.component';
import {RegistrationComponent} from './Dashboard/registration/registration.component';
import {RegistrationService} from './Services/List/Registration/registration.service';
import {RegistrationPendingComponent} from './Dashboard/registration-list/registration-pending/registration-pending.component';
import {RegistrationAcceptedComponent} from './Dashboard/registration-list/registration-accepted/registration-accepted.component';
import {RegistrationRejectedComponent} from './Dashboard/registration-list/registration-rejected/registration-rejected.component';
import {RegistrationWorkInProgressWaitForMailConfirmationComponent} from './Dashboard/registration-list/registration-wip-wait-email-confirmation/registration-wip-wait-email-confirmation.component';
import {RegistrationWorkInProgressWaitForPhotosComponent} from './Dashboard/registration-list/registration-wip-wait-photos/registration-wip-wait-photos.component';
import {PaymentListComponent} from './Dashboard/payment-list/payment-list.component';
import {PaymentToApproveComponent} from './Dashboard/payment-list/payment-to-approve/payment-to-approve.component';
import {PaymentService} from './Services/List/Payment/payment.service';
import {PaymentComponent} from './Dashboard/payment/payment.component';
import {PaymentPendingComponent} from './Dashboard/payment-list/payment-pending/payment-pending.component';
import {PaymentAcceptedComponent} from './Dashboard/payment-list/payment-accepted/payment-accepted.component';
import {PaymentRejectedComponent} from './Dashboard/payment-list/payment-rejected/payment-rejected.component';
import {AddCompanyComponent} from './Dashboard/add-company/add-company.component';
import {AddInspectionComponent} from './Dashboard/add-inspection/add-inspection.component'
import {CompanyService} from './Services/Company/company.service';
import {CompanyComponent} from './Dashboard/company/company.component';
import {CompanyProfileComponent} from './Dashboard/company/company-profile/company-profile.component';
import {InspectionToApproveComponent} from './Dashboard/company/inspection-to-approve/inspection-to-approve.component';
import {InspectionComponent} from './Dashboard/company/inspection/inspection.component';
import {StoreItemService} from './store-item.service';
import {SinglePhotoModalComponent} from './Dashboard/company/inspection/single-photo-modal/single-photo-modal.component';
import {NetworkingService} from './Services/Networking/networking.service';
import {TerritoryManagementComponent} from './Dashboard/territory-management/territory-management.component';
import {ValuesService} from './Services/Values/values.service';
import {NumberCardComponent} from './Components/number-card/number-card.component';
import {TerritoryService} from './Services/Territory/territory.service';
import {AgmSnazzyInfoWindowModule} from '@agm/snazzy-info-window';
import {InfowindowInspectorComponent} from './Components/infowindow-inspector/infowindow-inspector.component';
import {InspectionPendingComponent} from './Dashboard/company/inspection-pending/inspection-pending.component';
import {InspectionExpiredComponent} from './Dashboard/company/inspection-expired/inspection-expired.component';
import {InspectionScheduledComponent } from './Dashboard/company/inspection-scheduled/inspection-scheduled.component';
import {InspectionScheduledPendingComponent  } from './Dashboard/company/inspection-scheduled-pending/inspection-scheduled-pending.component';
import {InspectionOpenedComponent} from './Dashboard/company/inspection-opened/inspection-opened.component';
import {InspectionAcceptedComponent} from './Dashboard/company/inspection-accepted/inspection-accepted.component';
import {InspectionToClaimComponent} from './Dashboard/company/inspection-to-claim/inspection-to-claim.component';
import {InspectionClaimedComponent} from './Dashboard/company/inspection-claimed/inspection-claimed.component';
import {InspectionRejectedComponent} from './Dashboard/company/inspection-rejected/inspection-rejected.component';
import {SettingsComponent} from './Dashboard/settings/settings.component';
import {UserSettingsComponent} from './Dashboard/settings/user-settings/user-settings.component';
import {UserManagementComponent} from './Dashboard/settings/user-management/user-management.component';
import {UserProfileComponent} from './Dashboard/user-profile/user-profile.component';
import {UserStateComponent} from './Dashboard/user-profile/user-state/user-state.component';
import {UserProfileService} from './Services/User/user-profile.service';
import {PaymentStatsComponent} from './Components/payment-stats/payment-stats.component';
import {InspectionsComponent} from './Dashboard/user-profile/inspections/inspections.component';
import {AssignedInspectionComponent} from './Dashboard/user-profile/assigned-inspection/assigned-inspection.component';
import {PaymentsComponent} from './Dashboard/user-profile/payments/payments.component';
import {SearchResultComponent} from './Dashboard/search-result/search-result.component';
import {SearchResultListComponent} from './Dashboard/search-result-list/search-result-list.component';
import {FilterComponent} from './Components/filter/filter.component';
import {NotFoundComponent} from './Dashboard/not-found/not-found.component';
import {RefuseRegistrationModalComponent} from './Components/refuse-registration-modal/refuse-registration-modal.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import {GoogleMapsAPIWrapper} from '@agm/core/services/google-maps-api-wrapper';
import {BusinessEventService} from './Services/BusinessEvents/business_event.service';
import {EventDetailModalComponent} from './Components/event-detail-modal/event-detail-modal.component';
import {MapModalComponent} from './Components/map/map.component';
import {CityManagementComponent} from './Dashboard/territory-management/city-management/city-management.component';
import {SauronComponent} from './Dashboard/territory-management/sauron/sauron.component';
import {CityService} from 'app/Services/City/city.service';
import {CityDetailComponent} from './Dashboard/territory-management/city-detail/city-detail.component';
import {ZoneManagementComponent} from './Dashboard/territory-management/zone-management/zone-management.component';
import {ZoneService} from './Services/Zone/zone.service';
import {KeysPipe, ValuesPipe} from './Pipes/keys.pipe';
import {InspectorService} from './Services/Inspector/inspector.service';
import {InspectorAutocompleteComponent} from './Components/inspector-autocomplete/inspector-autocomplete.component';
import {MapsService} from './Services/Maps/maps.service';
import {LPadPipe} from './Pipes/padding.pipe';
import {UserZonesComponent } from './Dashboard/user-profile/user-zones/user-zones.component';
import {SchedulesComponent } from './Dashboard/territory-management/schedules/schedules.component';
import {SchedulesUnconfirmedComponent } from './Dashboard/territory-management/schedules-unconfirmed/schedules-unconfirmed.component';
import {SchedulesUnassignedComponent } from './Dashboard/territory-management/schedules-unassigned/schedules-unassigned.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {NgxGalleryModule } from 'ngx-gallery';
import {BootstrapModalModule } from 'angular2-modal/plugins/bootstrap';
import {InspectionArchiveComponent} from './Dashboard/territory-management/inspection-archive/inspection-archive.component';
import {InspectionPendingTComponent} from './Dashboard/territory-management/inspection-pending/inspection-pending.component';
import {AllInspectionToApproveComponent} from './Dashboard/territory-management//inspection-to-approve/inspection-to-approve.component';
import {InspectionDeletedComponent} from './Dashboard/territory-management//inspection-deleted/inspection-deleted.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AuthHeaderComponent,
    LoginComponent,
    SignupComponent,
    RestoreComponent,
    DashboardComponent,
    HeaderComponent,
    DiaryComponent,
    DashboardHomeComponent,
    SpinnerComponent,
    NotificationListComponent,
    RegistrationListComponent,
    SidebarComponent,
    RegistrationToApproveComponent,
    TableComponent,
    RegistrationComponent,
    RegistrationPendingComponent,
    RegistrationAcceptedComponent,
    RegistrationWorkInProgressWaitForPhotosComponent,
    RegistrationWorkInProgressWaitForMailConfirmationComponent,
    RegistrationRejectedComponent,
    PaymentListComponent,
    PaymentToApproveComponent,
    PaymentComponent,
    PaymentPendingComponent,
    PaymentAcceptedComponent,
    PaymentRejectedComponent,
    AddCompanyComponent,
    CompanyComponent,
    CompanyProfileComponent,
    InspectionToApproveComponent,
    InspectionPendingComponent,
    InspectionPendingTComponent,
    InspectionScheduledPendingComponent,
    InspectionAcceptedComponent,
    InspectionOpenedComponent,
    InspectionExpiredComponent,
    InspectionRejectedComponent,
    InspectionToClaimComponent,
    InspectionClaimedComponent,
    InspectionDeletedComponent,
    InspectionComponent,
    AllInspectionToApproveComponent,
    SinglePhotoModalComponent,
    TerritoryManagementComponent,
    NumberCardComponent,
    InfowindowInspectorComponent,
    SettingsComponent,
    UserSettingsComponent,
    UserManagementComponent,
    UserProfileComponent,
    UserStateComponent,
    PaymentStatsComponent,
    InspectionsComponent,
    AssignedInspectionComponent,
    PaymentsComponent,
    SearchResultComponent,
    SearchResultListComponent,
    FilterComponent,
    NotFoundComponent,
    RefuseRegistrationModalComponent,
    EventDetailModalComponent,
    MapModalComponent,
    CityManagementComponent,
    SauronComponent,
    CityDetailComponent,
    ZoneManagementComponent,
    KeysPipe,
    ValuesPipe,
    InspectorAutocompleteComponent,
    LPadPipe,
    UserZonesComponent,
    InspectionScheduledComponent,
    SchedulesComponent,
    SchedulesUnconfirmedComponent,
    SchedulesUnassignedComponent,
    AddInspectionComponent,
    InspectionArchiveComponent
    
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BootstrapModalModule,
    routing,
    Ng2CompleterModule,
    ArchwizardModule, 
    NgxGalleryModule, 
    HttpClientModule,
    NgbModule.forRoot(),
    TranslateModule.forRoot(),
    GooglePlaceModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    PopoverModule,
    ModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDxRWKXK2bKd_Od_gBP9LY8yPFVzAVMgaU',
      libraries: ['places', 'drawing', 'geometry']
    }),
    AgmSnazzyInfoWindowModule,
    NgxJsonViewerModule
  ],
  providers: [StoreItemService,
    DashboardService,
    ClaimAdjustersService,
    PushNotificationService,
    AuthService,
    Overlay,
    ModalService,
    LoaderService,
    UserService,
    RegistrationService,
    PaymentService,
    CompanyService,
    CityService,
    NetworkingService,
    ValuesService,
    TerritoryService,
    UserProfileService,
    GoogleMapsAPIWrapper,
    BusinessEventService,
    InspectorService,
    ZoneService,
    MapsService],
  bootstrap: [AppComponent],
  entryComponents: [SinglePhotoModalComponent, RefuseRegistrationModalComponent, EventDetailModalComponent, MapModalComponent]
})
export class AppModule {
}
