import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { City } from '../../../Models/City';
import { environment } from '../../../../environments/environment';
import { Subscription, Observable } from 'rxjs/Rx';
import { ValuesService } from '../../../Services/Values/values.service';
import { TableStructure } from '../../../Helpers/TableStructure';
import { TerritoryService } from '../../../Services/Territory/territory.service';
import { Inspector } from '../../../Models/Inspector';
import { CityInspection } from '../../../Models/CityInspection';
import { InspectionType } from '../../../Helpers/Utils';
import { MapsService } from '../../../Services/Maps/maps.service';

@Component({
  selector: 'app-sauron',
  templateUrl: './sauron.component.html',
  styleUrls: ['./sauron.component.scss']
})
export class SauronComponent implements OnInit, OnDestroy {
  lat: number = 0;
  lng: number = 0;
  private map: any;

  zoom: number = 0;
  public tableStructure: any[] = TableStructure.CityInspection;
  public items: any[] = [];
  public meta: any = {};
  public inspectors: Inspector[] = []
  public inspections: CityInspection[] = []
  sub: Subscription = new Subscription()
  public cities: City[] = []
  public style: any[] = []
  public currentCity: string = ''
  public canRefresh: boolean = true
  public refreshTime: number = 30000
  public timer: Subscription = new Subscription();



  constructor(private changeDetector: ChangeDetectorRef, private territoryService: TerritoryService, private valuesService: ValuesService, private mapsService: MapsService, private router: Router) {
    this.initMap()
    this.refreshUi()
    this.autoRefreshMap()
    this.style = [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
          { visibility: 'off' }
        ]
      }
    ];
  }

  public get onlineUsersCount() {
    return this.inspectors.length
  }

  public get activeCrashesCount() {
    let inspections = this.inspections.filter(inspection => {
      return inspection.request_type == InspectionType.Crash
    })
    return inspections.length
  }

  public get activeAssuntiveCount() {
    let inspections = this.inspections.filter(inspection => {
      return inspection.request_type == InspectionType.Assuntiva
    })
    return inspections.length
  }

  public get activeScheduledCount() {
    let inspections = this.inspections.filter(inspection => {
      return inspection.is_scheduled == true
    })
    return inspections.length
  }

  refreshUi() {
    this.sub.add(
      Observable.timer(1000, 1000)
        .subscribe(val => {
          this.inspections = this.inspections.filter(inspection => !inspection.isPassed)
          this.changeDetector.detectChanges()
        })
    )
  }

  private autoRefreshMap() {
    this.timer = new Subscription()
    this.timer.add(
      Observable.timer(this.refreshTime, this.refreshTime)
        .subscribe(val => {
          if (this.canRefresh) {
            this.refreshMap();
          }
        })
    )
  }

  public clearRefresh() {
    this.timer.unsubscribe();
    this.autoRefreshMap();
  }

  public refreshMap() {
    if (this.currentCity != '') {
      this.getOnlineUserForCity(this.currentCity)
      this.getInspectionForCity(this.currentCity)
    }
  }

  public infoWindowOpened() {
    this.canRefresh = false
  }

  public infoWindowClosed() {
    this.canRefresh = true
  }

  ngOnInit() {
    this.getCities()
  }

  getCities() {
    this.sub.add(
      this.valuesService.getCities()
        .finally(() => {
          //todo
        })
        .subscribe(cities => {
          this.cities = cities
          console.log('CITTA:', this.cities)
        }, err => {
          //todo
        })
    )
  }

  initMap() {
    this.moveMap(0, 0, environment.gmap_default_start_point.zoom)
  }

  moveMap(lat: number, lng: number, zoom: number, placeId: string = null) {
    if (placeId !== null) {
      this.mapsService.setMapCenter(placeId, this.map)
    } else {
      this.lat = lat
      this.lng = lng
    }
    this.zoom = zoom
  }

  selectCity(value) {
    this.getOnlineUserForCity(value)
    this.getInspectionForCity(value)
    this.currentCity = value
    let city = this.cities.find(city => {
      return city.id == value
    })
    this.moveMap(city.coord.lat, city.coord.lng, city.coord.zoom, city.mappingId)
  }


  getOnlineUserForCity(cityId: string) {
    this.sub.add(
      this.territoryService.getOnlineUserByCityId(cityId)
        .finally(() => {
          //todo
        })
        .subscribe(inspectors => {
          this.inspectors = inspectors
        }, err => {
          //todo
        })
    )
  }


  getInspectionForCity(cityId: string) {
    this.sub.add(
      this.territoryService.getSessionByCity(cityId)
        .finally(() => {
          //todo
        })
        .subscribe(inspections => {
          this.inspections = inspections.filter(inspection => !inspection.isPassed)
        }, err => {
          //todo
          console.error(err)
        })
    )
  }

  openInspector(obj: any) {
    console.log(obj.type);
    if (obj.type == 'user') {
      this.router.navigateByUrl('/home/user/' + obj.id);
    } else {
      this.router.navigateByUrl('/home/search/inspection/' + obj.id);
    }
  }

  moveMapTo(id: string) {
    let inspection = this.inspections
      .find(inspection => {
        return inspection.id == id
      })

    this.lat = parseFloat(inspection.latitude)
    this.lng = parseFloat(inspection.longitude)
  }


  ngOnDestroy() {
    this.sub.unsubscribe()
    this.timer.unsubscribe()
  }


  mapReady(map: google.maps.Map) {
    this.map = map;
  }
}
