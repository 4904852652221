export function PolySnapper(myThis,opts) {
  function extend(obj, ...args) {

    args.forEach(function (source) {
      if (source) {
        for (const prop in source) {
          if (source[prop].constructor === Object) {
            if (!obj[prop] || obj[prop].constructor === Object) {
              obj[prop] = obj[prop] || {};
              extend(obj[prop], source[prop]);
            } else {
              obj[prop] = source[prop];
            }
          } else {
            obj[prop] = source[prop];
          }
        }
      }
    });
    return obj;
  }

  function defined(obj, key) {
    return typeof obj[key] !== 'undefined';
  }
 
  const that = myThis;

  myThis.keyDownListener = null;
  myThis.keyUpListener = null;

  myThis.drawing = false;
  myThis.currentpoly = null;
  myThis.polys = (defined(opts, 'polygons')) ? opts.polygons : [];

  const _map = (defined(opts, 'map')) ? opts.map : null;
  const _marker = (defined(opts, 'marker')) ? opts.marker : new google.maps.Marker();
  const _thresh = (defined(opts, 'threshold')) ? opts.threshold : 20;
  const _key = (defined(opts, 'key')) ? opts.key : 'shift';
  const _keyReq = (defined(opts, 'keyRequired')) ? opts.keyRequired : false;

  const _onEnabled = (defined(opts, 'onEnabled')) ? opts.onEnabled : function () {
  };
  const _onDisabled = (defined(opts, 'onDisabled')) ? opts.onDisabled : function () {
  };
  const _onChange = (defined(opts, 'onChange')) ? opts.onChange : function () {
  };

  const _polystyle = (defined(opts, 'polystyle')) ? (JSON.parse(JSON.stringify(opts.polystyle))) : {};
  const _hidePOI = (defined(opts, 'hidePOI')) ? opts.hidePOI : false;

  let _keyDown = false; 

  if (!_map) {
    console.log('We need to know the map');
    return;
  }

  const _mapDiv = _map.getDiv(); // document.getElementById(_map.getDiv().getAttribute('id'));

  if (_hidePOI) {

    _map.poi = function (state) {

      const styles = [
        {
          'featureType': 'transit',
          'stylers': [
            {'visibility': 'off'}
          ]
        }, {
          'featureType': 'poi',
          'stylers': [
            {'visibility': 'off'}
          ]
        }, {
          'featureType': 'landscape',
          'stylers': [
            {'visibility': 'off'}
          ]
        }
      ];

    //  myThis.set('styles', (state) ? {} : styles);

    };

  }

  if (_keyReq) {

    const keymap = {
      'shift': 16,
      'ctrl': 17
    };
    const which = keymap[_key];

    myThis.keyDownListener = window.addEventListener('keydown', function (e) {
      _keyDown = (e.which === which);
    });

    myThis.keyUpListener = window.addEventListener('keyup', function (e) {
      _keyDown = (e.which === which) ? false : true;
    });
  }

  return {
    polygon: function () {
      return that.currentpoly;
    },
    enabled: function () {
      return that.drawing;
    },
    enable: function () {

      that.drawing = true;

      if (_hidePOI) {
        _map.poi(false);
      }

      const vertexMarker = _marker;
      // const snapable_polys = that.polys.filter(function (p) {
      //   return (typeof p.snapable !== 'undefined' && p.snapable);
      // });
      const snapable_polys = that.polys;
      const snapable_points = snapable_polys.map(function (p) {
        return p.getPath().getArray();
      }).reduce(function (a, b) {
        return a.concat(b);
      }, []);
      let last_closeby = null;
      // the official Drawing Manager will not work!
      _map.setOptions({draggableCursor: 'crosshair'});

      that.currentpoly = new google.maps.Polygon(
        extend(_polystyle, {editable: true, map: _map})
      );

      that.currentpoly.addListener('rightclick', function (e) {

        if (e.vertex != null && myThis.getPath().getLength() > 3) {
          myThis.getPath().removeAt(e.vertex);
          _onChange();
        }

      });

      // you can delete vertices in the current polygon by right clicking them
      _map.addListener('click', function (e) {

        // Because path is an MVCArray, we can simply append a new coordinate
        // and it will automatically appear.
        const ll = (last_closeby && (!_keyReq || _keyReq && _keyDown)) ? last_closeby : e.latLng;
        that.currentpoly.getPath().push(ll);
        _onChange();

      });

      /*listening to set_at event, and calling the setAt() method inside
        will cause a Maximum call stack size exceeded...
          google.maps.event.addListener(currentpoly.getPath(), "set_at", function(idx){
              if(last_closeby) currentpoly.getPath().setAt(idx, last_closeby);
          });
      Instead, we can addListenerOnce, and make sure to re-attach the listner AFTER setAt
      */
      (function setAtRecurse() {
        google.maps.event.addListenerOnce(that.currentpoly.getPath(), 'set_at', function (idx) {
          if (last_closeby && (!_keyReq || _keyReq && _keyDown)) {
            that.currentpoly.getPath().setAt(idx, last_closeby);
          }
          setAtRecurse();
          _onChange();
        });
      }());

      // Same comments go for insert_at ...
      (function insertAtRecurse() {
        google.maps.event.addListenerOnce(that.currentpoly.getPath(), 'insert_at', function (idx) {
          if (last_closeby && (!_keyReq || _keyReq && _keyDown)) {
            that.currentpoly.getPath().setAt(idx, last_closeby);
          }
          insertAtRecurse();
          _onChange();
        });
      }());


      /*
          we cannot listen to move events on the gmap object.. because when we
          drag existing points, or new ones, the mouse move events are suspended
          instead, we must attach mousemove to the mapcanvas (jquery), and then
          convert x,y coordinates in the map canvas to lat lng points.
      */

      _mapDiv.onmousemove = function (e) {
        const bounds = _map.getBounds();
        const neLatlng = bounds.getNorthEast();
        const swLatlng = bounds.getSouthWest();
        const startLat = neLatlng.lat();
        const endLng = neLatlng.lng();
        const endLat = swLatlng.lat();
        const startLng = swLatlng.lng();

        const lat = startLat + ((e.offsetY / myThis.offsetHeight) * (endLat - startLat));
        const lng = startLng + ((e.offsetX / myThis.offsetWidth) * (endLng - startLng));
        const ll = new google.maps.LatLng(lat, lng);
        // find any of the existing polygon points are close to the mousepointer
        const closeby = snapable_points.filter(function (p) {
          return (google.maps.geometry.spherical.computeDistanceBetween(ll, p)) < _thresh;
        })[0] || null;
        /* we could just use:
            if(closeby){
                vertexMarker.setOptions({
                    position: closeby,
                    map: map
                });
            }
            else vertexMarker.setMap(null);
        However, it causes the marker to flicker because we are constantly calling
        setOptions every mousemove. We will instead, save the last position of closeby,
        and only set it again if it has changed...
        */

        if (closeby && closeby !== last_closeby) {
          last_closeby = closeby;
          vertexMarker.setPosition(closeby);
          vertexMarker.setMap(_map);
        } else if (!closeby) {
          last_closeby = null;
          vertexMarker.setMap(null);
        }


      };

      // now execute the callback
      _onEnabled();
    },
    disable: function () {

      if (_hidePOI) {
        _map.poi(true);
      }

      that.drawing = false;
      _map.setOptions({draggableCursor: null});
      that.currentpoly.setMap(null);

      _mapDiv.onmousemove = null;

      if (_keyReq) {

        window.removeEventListener('keydown', myThis.keyDownListener);
        window.removeEventListener('keyup', myThis.keyUpListener);

      }

      // annnd the callback
      _onDisabled();

    }

  };
}
