///<reference path="../../../../../node_modules/@types/googlemaps/index.d.ts"/>
import {Component, ElementRef, NgZone, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {CityService} from '../../../Services/City/city.service';
import {ValuesService} from '../../../Services/Values/values.service';
import {MapsAPILoader} from '@agm/core';
import {WorkHourZone} from '../../../Models/WorkHourZone';
import {LoaderService} from '../../../Ui/loader.service';
import {Subject} from 'rxjs/Subject';
import {ModalService} from '../../../Helpers/modal.service';
import {Address} from "ngx-google-places-autocomplete/objects/address";

declare var google: any;

@Component({
  selector: 'app-city-management',
  templateUrl: './city-management.component.html',
  styleUrls: ['./city-management.component.scss']
})
export class CityManagementComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
 // public email: string = "";
  public cities: any[] = [];
  public citiesStaffomatic: any[] = [];
  public city = {
    mappingId: null,
    name: null,
    zoneId: null,
    ExternalId: null,
    SupportEmail:null,
    CreateLocationOnStaffomatic:null,
    StaffomaticLocationName:null
  };
  
  public workHourZones: WorkHourZone[] = [];
  public selectedWorkHourZone: WorkHourZone;
  public periodsOfYear = {
    0: 'Set - Feb',
    1: 'Mar - Mag',
    2: 'Giu - Ago'
  };
  @ViewChild('cityName')
  public searchElementRef: ElementRef;

  constructor(private cityService: CityService,
              private valuesService: ValuesService,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private loaderService: LoaderService,
              private modalService: ModalService) {
  }


  ngOnInit() {
    this.initAutocomplete();
    this.initCity();
    this.loadCities();
    this.loadWorkHourZones();
    this.getStaffomaticCities();
   
    
  }

  getStaffomaticCities(){
    this.cityService.getStaffomaticLocations().subscribe(data=>{
      this.citiesStaffomatic = data;
    })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initCity() {
    this.city = {
      mappingId: null,
      name: null,
      zoneId: null,
      ExternalId: null,
      CreateLocationOnStaffomatic:false,
      StaffomaticLocationName:null,
      SupportEmail:null
    };
  }

  private initAutocomplete() {
   /*  // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['(cities)']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.city.ExternalId = place.place_id;
          this.city.name = place.name;
          this.city.StaffomaticLocationName = place.name;
          //this.city.CreateLocationOnStaffomatic = true;
        });
      });
    }); */
  }

  private loadWorkHourZones() {
    this.valuesService.getWorkHourZones().takeUntil(this.ngUnsubscribe).subscribe(
      (data) => {
       // console.log('workHourZone', data);
        this.workHourZones = data;
      }
    );
  }


  private loadCities(): void {
    this.loaderService.displayLoader(true);
    this.cityService.getCitiesList().takeUntil(this.ngUnsubscribe).finally(() => this.loaderService.displayLoader(false)).subscribe(
      (data) => {
       // console.log('cities', data);
        this.cities = data;
      }
    );
  }

  cityNameChange(event, isStaffomatic:boolean=false) {
    // reset mapping id on city name change
  /*   this.city.mappingId = null;
    this.city.name = null;
    this.city.CreateLocationOnStaffomatic = false;
    this.city.ExternalId = null; */

    if(isStaffomatic){
      this.city.ExternalId = event.target.value;
      this.city.StaffomaticLocationName = this.citiesStaffomatic.filter(item => item.id == event.target.value)[0].name;
      this.city.name = this.citiesStaffomatic.filter(item => item.id == event.target.value)[0].name;
    }  else {
      console.log(event);
      this.city.mappingId = event.place_id;
    }
  }

  cityFormSubmit(form) {
    this.city.SupportEmail = "info@insoore.com";
    this.loaderService.displayLoader(true);
    this.cityService.create(
      this.city
    ).takeUntil(this.ngUnsubscribe).finally(() => this.loaderService.displayLoader(false)).subscribe(
      (data) => {
        console.log(data);
       // this.activeCity(data.id)
        this.cities.push(data);
        this.initCity();
        form.form.reset();
      }, err => this.modalService.errorModal(err.json().data)
    );
  }

  deleteCity(city): void {
    this.cityService.deleteConfirm(city, () => {
      this.cities.splice(this.cities.indexOf(city), 1);
    });
  }


  onWorkHourZoneChange($event) {
    this.selectedWorkHourZone = this.workHourZones.find((z) => {
      return z.id === parseInt(this.city.zoneId, 10);
    });
  }
}
