import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ModalService } from '../../../Helpers/modal.service';
import { LoaderService } from '../../../Ui/loader.service';
import { Operator } from '../../../Models/Operator';
import { Subscription } from 'rxjs/Rx';
import { DashboardService } from '../../../Services/Dashboard/dashboard.service';

@Component({
	selector: 'app-user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
	public user : Operator = new Operator();
	profileForm : FormGroup;
	public confirm: boolean = false;
	private sub: Subscription = new Subscription();

	constructor(private modalService: ModalService, private dashboardService: DashboardService, private loaderService: LoaderService, public modal:ModalService, public formBuilder: FormBuilder) {
		this.user = this.dashboardService.operator
	}

	ngOnInit() {
		this.confirm = false;
		this.profileForm = this.formBuilder.group({
			actualPassword: ['', Validators.compose([Validators.required])],
			newPassword: ['', Validators.required],
			confirmPassword: ['', Validators.required]
		}, {validator: this.checkPassword});
	}

	checkPassword(g:FormGroup) {
		return g.get('newPassword').value === g.get('confirmPassword').value ? null : {'mismatch': true}
	}

	changePassword() {
		this.loaderService.displayLoader(true);
		this.dashboardService.changePassword(this.profileForm.value.actualPassword, this.profileForm.value.newPassword).subscribe((data) => {
			this.loaderService.displayLoader(false);
			this.modal.simpleModal("Password cambiata", "Hai cambiato la password con successo")
		}, err => {
			this.loaderService.displayLoader(false);
			let error = err.json().data
			if(error.is_business_error) {
				this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
			} else {
				this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
			}
			console.log(err)
		});
	}
}
