import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  public currentType: string = ""
  public currentId: string = ""
  public sub: Subscription = new Subscription()
  public searchQuery: string = ""

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.sub.add(
      this.route.params.map(params => params['type']).subscribe(type => {
        this.currentType = type
      })
    )
    this.sub.add(
      this.route.params.map(params => params['id']).subscribe(id => {
        this.currentId = id
      })
    )
  }

  public search() {
    switch (this.currentType) {
      case ("user"):
        this.router.navigateByUrl('home/searchlist/user/' + this.searchQuery);
        break;
      case ("plate"):
        this.router.navigateByUrl('/home/searchlist/plate/' + this.searchQuery);
        break;
      case ("inspection"):
        this.router.navigateByUrl('/home/search/inspection/' + this.searchQuery);
        break;
      case ("company"):
        this.router.navigateByUrl('/home/company/' + this.searchQuery);
        break;
      case ("inspector"):
        this.router.navigateByUrl('/home/user/' + this.searchQuery);
        break;
    }
  }

  public get type() {
    switch (this.currentType) {
      case ("user"):
        return "utente"
      case ("plate"):
        return "targa"
      case ("inspection"):
        return "ispezione"
      case ("company"):
        return "company"
      case ("inspector"):
        return "inspector"
    }
  }
}
