import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { CompanyService } from '../../Services/Company/company.service';
import { LoaderService } from '../../Ui/loader.service';
import { ModalService } from '../../Helpers/modal.service';
import { Subscription } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-add-company',
	templateUrl: './add-company.component.html',
	styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnDestroy {
	addCompanyForm : FormGroup;
	sub : Subscription = new Subscription();
	public avatar: File = null
	public thumbnail: string = ""
	constructor(public formBuilder: FormBuilder, public router: Router, private companyService : CompanyService, private loader: LoaderService, private modalService:ModalService) {
		this.initializeForm();
	}

	create() {
		this.modalService.confirmDialog("Conferma", "Confermando questa registrazione L’azienda sarà ufficialmente registrata nel nostro sistema, assicurati dell’esattezza dei suoi dati. ", () => this.addCompany())

	}

	addCompany() {
		this.loader.displayLoader(true);
		this.sub.add(this.companyService.create(this.addCompanyForm.value.name, this.addCompanyForm.value.email, this.addCompanyForm.value.phone, this.addCompanyForm.value.inspections, this.avatar,  this.addCompanyForm.value.masterName, this.addCompanyForm.value.masterSurname, this.addCompanyForm.value.masterEmail, this.addCompanyForm.value.masterPhone)
		.finally( () => {
			this.loader.displayLoader(false);
		}).subscribe(data => {
			let url = environment.signup_url
			this.modalService.simpleModal("Azienda aggiunta", "L’utente "+this.addCompanyForm.value.masterName + " " + this.addCompanyForm.value.masterSurname+" è stato aggiunto, comunica i dati per la registrazione per effettuare il primo accesso al seguente indirizzo: <br> <a href='"+url+"'>"+url+"</a>")
			this.initializeForm();
		}, err => {
			let error = err.json().data
			if(error.is_business_error) {
				this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
			} else {
				this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore durante la creazione dell'azienda")
			}

			console.log(err)
		}));
	}

	initializeForm() {
		this.addCompanyForm = this.formBuilder.group({
			name: ['', Validators.compose([Validators.required])],
			email: ['', Validators.compose([Validators.required, Validators.email])],
			phone: ['', Validators.compose([Validators.required])],
			inspections: ['', Validators.compose([Validators.required])],
			masterName: ['', Validators.compose([Validators.required])],
			masterSurname: ['', Validators.compose([Validators.required])],
			masterEmail: ['', Validators.compose([Validators.required])],
			masterPhone: ['', Validators.compose([Validators.required])]
		});
	}


	fileChange(event) {
		this.avatar = event.target.files[0]
		var url = URL.createObjectURL(event.target.files[0]);
		var img = new Image;

		img.onload = function() {
			alert(img.width);
		};

		this.thumbnail = url
	}


	ngOnDestroy() {
		this.sub.unsubscribe();
	}

}
