import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import { UserProfileService } from '../../../Services/User/user-profile.service';
import { Inspection } from '../../../Models/Inspection';
import { LoaderService } from '../../../Ui/loader.service';
import { ModalService } from '../../../Helpers/modal.service';
import { Inspector } from '../../../Models/Inspector';
import { TableStructure } from '../../../Helpers/TableStructure';
import { CompanyService } from '../../../Services/Company/company.service';

@Component({
  selector: 'app-assigned-inspection',
  templateUrl: './assigned-inspection.component.html',
  styleUrls: ['./assigned-inspection.component.scss']
})
export class AssignedInspectionComponent implements OnInit {
  public tableStructure : any[] = TableStructure.ScheduledInspectionsAssigned;
	private sub: Subscription = new Subscription()
	public total_inspections: number = 0
	public aborted_inspections: number = 0
	public accepted_inspections: number = 0
  public refused_inspections: number = 0
  public expired_inspections: number =0
  public other_inspections: number =0
	public inspections: Inspection[] = []
	public inspector: Inspector = new Inspector()
	public meta : any = {};
	public sortDescending: boolean = true;
	public currentPage: number = 0;

	constructor(private companyService: CompanyService, private userProfileService: UserProfileService, private route: ActivatedRoute, private loader: LoaderService, private modal: ModalService) { }

	ngOnInit() {
		this.getInspectorData()
	
	}

	private getInspectorData() {
		this.loader.displayLoader(true)
		this.sub.add(
			this.userProfileService.getUser(this.route.parent.params.map(params => params['id']))
			.finally(()=>{
				this.loader.displayLoader(false)
			})
			.subscribe(data => {
				this.loader.displayLoader(false)
        this.inspector = data
        if(data){
          this.getInspectionList()
        }
        
			}, err => {
				this.loader.displayLoader(false)
				this.modal.simpleModal('Si è verificato un errore', 'Si è verificato un errore nel recuperare i dati dell\'utente')
			})
		)
	}


	nextPage() {
		if(!this.meta.hasNext) return
		this.currentPage+=1
		this.getInspectionList(this.currentPage)
	}

	prevPage() {
		if(!this.meta.hasPrevious) return
		this.currentPage-=1
		this.getInspectionList(this.currentPage)
	}

	goToPage(page: number) {
		this.getInspectionList(page)
	}

	sortBy(type: number) {
		this.sortDescending = !this.sortDescending
		this.getInspectionList(this.currentPage)
	}


	getInspectionList(page = 0) {
    this.loader.displayLoader(true);
    this.sub.add(this.companyService.getScheduledListByUser(this.inspector.id)
    
		.subscribe(
			data => {
				this.loader.displayLoader(false)
				this.inspections = data.inspections;
				this.meta = data.meta;
			}, err => {
				this.loader.displayLoader(false)
				this.modal.simpleModal('errore', 'Si è verificato un errore')
			}
		));
	}

	ngOnDestroy() {
		this.sub.unsubscribe()
	}

}