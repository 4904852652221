import { Component, OnInit } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';
import { InspectionStatus } from '../../Helpers/Utils';
import { LoaderService } from '../../Ui/loader.service';
import { CompanyService } from '../../Services/Company/company.service';
import { ModalService } from '../../Helpers/modal.service';
import { TableStructure } from '../../Helpers/TableStructure';
import { Subscription } from 'rxjs/Rx';
import { UserProfileService } from '../../Services/User/user-profile.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-search-result-list',
	templateUrl: './search-result-list.component.html',
	styleUrls: ['./search-result-list.component.scss']
})
export class SearchResultListComponent implements OnInit {

	public tableStructure : any[] = TableStructure.Inspections;
	public items : any[] = [];
	public sub : Subscription = new Subscription();
	public id : string;
	public meta : any = {};
	public currentPage: number = 0;
	private detailUrl: string = '';
	private searchType:string = "";
	private paramSearch:string = "";

	constructor(private userProfileService: UserProfileService, private router: Router, private loading: LoaderService, private companyService: CompanyService, private route: ActivatedRoute, private modalService: ModalService ) {
		this.route.params.subscribe(params => {
			if(params['type'] == "plate") {
				this.detailUrl = "/home/search/inspection/";
				this.tableStructure = TableStructure.Inspections
				this.getInspectionfromPlate(params['id'])
				this.searchType = "plate";
			} else if(params['type'] == "user") {
				this.detailUrl = "/home/user/";
				this.tableStructure = TableStructure.InspectorList
				this.getInspectorsBySurname(params['id'])
				this.searchType = "user";
			} else if(params['type'] == "address") {
				this.detailUrl = "/home/search/inspection/";
				this.tableStructure = TableStructure.Inspections
				this.getInspectionbyAddress(params['id'])
				this.searchType = "address";
			}

			this.paramSearch = params['id'];
		});
	}

	getInspectionfromPlate(plate: string, page: number = 0) {
		this.loading.displayLoader(true);

		this.sub.add(this.companyService.getInspectionFromPlate(plate)
		.subscribe(
			data => {
				this.loading.displayLoader(false)
				if(data.length>0){
					this.items = data;
				} else {
					this.router.navigateByUrl('home/notfound/plate/'+plate);
				}
			}, err => {
				this.loading.displayLoader(false)
				this.router.navigateByUrl('home/notfound/plate/'+plate);
				//this.modalService.simpleModal('errore', 'Si è verificato un errore')
				console.log(err)
			}
		));
	}

	getInspectionbyAddress(address: string, page: number = 0) {

		this.loading.displayLoader(true);
		this.sub.add(this.companyService.getAddress(page, false, address)
		.subscribe(
			data => {
				this.loading.displayLoader(false)
				this.items = data.inspections;
				this.meta = data.meta;
			}, err => {
				this.loading.displayLoader(false)
				this.router.navigateByUrl('home/notfound/address/'+address);
			}
		));



	/*	this.loading.displayLoader(true);
		this.sub.add(this.companyService.getInspectionFromPlate(address)
		.subscribe(
			data => {
				this.loading.displayLoader(false)
				if(data.length>0){
					this.items = data;
				} else {
					this.router.navigateByUrl('home/notfound/address/'+address);
				}
			}, err => {
				this.loading.displayLoader(false)
				this.router.navigateByUrl('home/notfound/address/'+address);
				//this.modalService.simpleModal('errore', 'Si è verificato un errore')
				console.log(err)
			}
		));*/
	}


	getInspectorsBySurname(surname: string, page: number = 0) {
		this.loading.displayLoader(true);
		this.sub.add(this.userProfileService.getInspectorsBySurname(surname)
		.subscribe(
			data => {
				this.loading.displayLoader(false)
				this.items = data;
				console.log(data);
			}, err => {
				this.loading.displayLoader(false)
				this.router.navigateByUrl('home/notfound/user/'+surname);
				//this.modalService.simpleModal('errore', 'Si è verificato un errore')
				console.log(err)
			}
		));
	}

	public get detail_url(): string {
		let url = this.detailUrl;
		return url;
	}

	ngOnInit() {
		//this.getInspectionfromPlate();
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

	nextPage() {
		if(!this.meta.hasNext) return
		this.currentPage+=1
		
		console.log("prova");

		if(this.searchType == "plate") {
			this.detailUrl = "/home/search/inspection/";
			this.tableStructure = TableStructure.Inspections
			this.getInspectionfromPlate(this.paramSearch, this.currentPage)
		} else if(this.searchType == "user") {
			this.detailUrl = "/home/user/";
			this.tableStructure = TableStructure.InspectorList
			this.getInspectorsBySurname(this.paramSearch, this.currentPage)
		} else if(this.searchType == "address") {
			this.detailUrl = "/home/user/";
			this.tableStructure = TableStructure.Inspections
			this.getInspectionbyAddress(this.paramSearch, this.currentPage)
		}
	}

	prevPage() {
		if(!this.meta.hasPrevious) return
		this.currentPage-=1
		
		if(this.searchType == "plate") {
			this.detailUrl = "/home/search/inspection/";
			this.tableStructure = TableStructure.Inspections
			this.getInspectionfromPlate(this.paramSearch, this.currentPage)
		} else if(this.searchType == "user") {
			this.detailUrl = "/home/user/";
			this.tableStructure = TableStructure.InspectorList
			this.getInspectorsBySurname(this.paramSearch, this.currentPage)
		} else if(this.searchType == "address") {
			this.detailUrl = "/home/user/";
			this.tableStructure = TableStructure.Inspections
			this.getInspectionbyAddress(this.paramSearch, this.currentPage)
		}
	}

	goToPage(page: number) {
		if(this.searchType == "plate") {
			this.detailUrl = "/home/search/inspection/";
			this.tableStructure = TableStructure.Inspections
			this.getInspectionfromPlate(this.paramSearch, page)
		} else if(this.searchType == "user") {
			this.detailUrl = "/home/user/";
			this.tableStructure = TableStructure.InspectorList
			this.getInspectorsBySurname(this.paramSearch, page)
		} else if(this.searchType == "address") {
			this.detailUrl = "/home/user/";
			this.tableStructure = TableStructure.Inspections
			this.getInspectionbyAddress(this.paramSearch, page)
		}
	}

}
