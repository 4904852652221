    import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/catch';
import { ResponseContentType } from '@angular/http';

@Injectable()
export class NetworkingService {
  private endpoints = [environment.api_default_url, environment.api_auth_url, environment.api_authapi_url, ""];
      

  constructor(private http: Http, private router: Router) { }
  post(path: string, data: any = {}, headers: any[] = [], contentType: string = 'application/json', endpoint = Endpoints.default, respContType: ResponseContentType = ResponseContentType.Json) {
    return this.http.post(
      this.buildUrl(endpoint, path), data, this.buildOptions(headers, contentType, respContType)
    ).map((data: Response) => {
      var contentType = data.headers.get("content-type");
      if (contentType != undefined && contentType === "application/pdf")
        return data;
      let json = data.json();
      return json;
    }).catch(
      error => {
        this.checkStatus(error);
        return Observable.throw(error)
      }
    )
  }

  post2(path: string, data: any = {}, headers: any[] = [], contentType: string = 'application/json', endpoint = Endpoints.default, respContType: ResponseContentType = ResponseContentType.Json, apiVersion:number = 2) {
    console.log("mydata",data);
    return this.http.post(
      this.buildUrlWithApi(endpoint, path, data, apiVersion), data, this.buildOptions(headers, contentType, respContType)
    ).map((data: Response) => {
      var contentType = data.headers.get("content-type");
      if (contentType != undefined && contentType === "application/pdf")
        return data;
      let json = data.json();
      return json;
    }).catch(
      error => {
        this.checkStatus(error);
        return Observable.throw(error)
      }
    )
  }


  getWithVersion(path: string, headers: any[] = [], contentType: string = 'application/json', data: any = {}, endpoint = Endpoints.default, apiVersion:number = 3) {
    return this.http.get(
      this.buildUrlWithApi(endpoint, path, data, apiVersion), this.buildOptions(headers, contentType)
    ).map((data: Response) => {
      let json = data.json();
      return json;
    }).catch(
      error => {
        this.checkStatus(error);
        return Observable.throw(error)
      }
    )
  }

  getWithVersion2(path: string, headers: any[] = [], contentType: string = 'application/json', data: any = {}, endpoint = Endpoints.default, apiVersion:number = 3) {
    return this.http.get(
      this.buildUrlWithApiNotEnd(endpoint, path, data, apiVersion), this.buildOptions(headers, contentType)
    ).map((data: Response) => {
      let json = data.json();
      return json;
    }).catch(
      error => {
        this.checkStatus(error);
        return Observable.throw(error)
      }
    )
  }

  get(path: string, headers: any[] = [], contentType: string = 'application/json', data: any = {}, endpoint = Endpoints.default) {
    return this.http.get(
      this.buildUrl(endpoint, path, data), this.buildOptions(headers, contentType)
    ).map((data: Response) => {
      let json = data.json();
      return json;
    }).catch(
      error => {
        this.checkStatus(error);
        return Observable.throw(error)
      }
    )
  }

  put(path: string, data: any = {}, headers: any[] = [], contentType: string = 'application/json', endpoint = Endpoints.default) {
    return this.http.put(
      this.buildUrlNotEnd(endpoint, path), data, this.buildOptions(headers, contentType)
    ).map((data: Response) => {
      let json = data.json();
      return json;
    }).catch(
      error => {
        this.checkStatus(error);
        return Observable.throw(error)
      }
    )
  }

  delete(path: string, headers: any[] = [], contentType: string = 'application/json', endpoint = Endpoints.default) {
    return this.http.delete(
      this.buildUrl(endpoint, path), this.buildOptions(headers, contentType)
    ).map((data: Response) => {
      let json = data.json();
      return json;
    }).catch(
      error => {
        this.checkStatus(error);
        return Observable.throw(error)
      }
    )
  }



  public getAuthenticatedHeader() {
    return [{ "key": "Authorization", "value": this.buildBearerToken() }]
  }

  buildBearerToken() {
    return "Bearer " + this.getAuthenticationTokenFromLocalStorage();
  }

  getAuthenticationTokenFromLocalStorage() {
    return localStorage.getItem('access_token');
  }



  //Questa funzione costruirà l'url definitivo sulla qualle effettuare le chiamate HTTP, concatenerà all'endpoint selezionato, il path e il numero di api_version
  buildUrl(endpoint: Endpoints, path: string, data: any = {}) {
    let qm = "?"
    if (path.indexOf(qm) > -1) {
      qm = "&"
    }

    let useFakes = ""
    if (environment.UseFakes) {
      useFakes = "&UseFakes=1"
    } else {
      useFakes = ""
    }

    return this.endpoints[endpoint] + [path + qm + "api-version=" + environment.api_version + useFakes, this.serialize(data)].join("&")
  }

  buildUrlNotEnd(endpoint: Endpoints, path: string, data: any = {}) {
    let qm = "?"
    if (path.indexOf(qm) > -1) {
      qm = "&"
    }

    let useFakes = ""
    if (environment.UseFakes) {
      useFakes = "&UseFakes=1"
    } else {
      useFakes = ""
    }

    return this.endpoints[endpoint] + [path + qm + "api-version=" + environment.api_version + useFakes, this.serialize(data)].join("")
  }

  buildUrlWithApi(endpoint: Endpoints, path: string, data: any = {}, apiVersion:number) {
    let qm = "?"
    if (path.indexOf(qm) > -1) {
      qm = "&"
    }

    let useFakes = ""
    if (environment.UseFakes) {
      useFakes = "&UseFakes=1"
    } else {
      useFakes = ""
    }

    return this.endpoints[endpoint] + [path + qm + "api-version=" + apiVersion, this.serialize(data)].join("&")
  }

  buildUrlWithApiNotEnd(endpoint: Endpoints, path: string, data: any = {}, apiVersion:number) {
    let qm = "?"
    if (path.indexOf(qm) > -1) {
      qm = "&"
    }

    let useFakes = ""
    if (environment.UseFakes) {
      useFakes = "&UseFakes=1"
    } else {
      useFakes = ""
    }

    return this.endpoints[endpoint] + [path + qm + "api-version=" + apiVersion, this.serialize(data)].join("")
  }

  buildUrlWithoutApi(endpoint: Endpoints, path: string, data: any = {}, apiVersion:number) {
    let qm = "?"
    if (path.indexOf(qm) > -1) {
      qm = ""
    }

    let useFakes = ""
    if (environment.UseFakes) {
      useFakes = "&UseFakes=1"
    } else {
      useFakes = ""
    }

    return this.endpoints[endpoint] + [path + qm + "api-version=" + apiVersion, this.serialize(data)].join("&")
  }


  //Questa funzione è necessaria per verificare che la chiamata sia stata autorizzata, in caso contrario chiamerà la funzione unauthorized()
  checkStatus(error) {
    if (error.status === 401) {
      this.unauthorized();
    }
  }

  //Questa funzione effettuerà un redirect sulla pagina di login
  unauthorized() {
    console.warn("non autorizzato");
    localStorage.clear();
    this.router.navigateByUrl("/");
  }


  //Questa funzione ha lo scopo di andare a costruire gli headers di ogni chiamata
  buildOptions(headers: any[] = [], contentType: string = 'application/json', responseType: ResponseContentType = ResponseContentType.Json,) {
    let h = new Headers({ 'Content-Type': contentType });
    if (contentType == "multipart/form-data") {
      h.delete("Content-Type")
    }

    headers.forEach(item => {

      h.append(item['key'], item['value'])
    });
    h.append("locale","IT-IT")

    return new RequestOptions({ 'headers': h, 'responseType': responseType });
  }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

}


export enum Endpoints {
  default = 0,
  auth = 1,
  authapi = 2,
  none = 3
}
