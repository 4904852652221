import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs/Rx";
import { TableStructure } from "../../../Helpers/TableStructure";
import { CompanyService } from "../../../Services/Company/company.service";
import { SummarizedInspectionStatus } from "../../../Helpers/Utils";
import { LoaderService } from "../../../Ui/loader.service";
import { ActivatedRoute } from "@angular/router";
import { ModalService } from "../../../Helpers/modal.service";
import { Convert, Export, InspectionCompany } from "../../../Helpers/Utils";

@Component({
  selector: "app-inspection-claimed",
  templateUrl: "./inspection-claimed.component.html",
  styleUrls: ["./inspection-claimed.component.scss"]
})
export class InspectionClaimedComponent implements OnInit, OnDestroy {
  public tableStructure: any[] = TableStructure.ClaimedInspections;
  public items: any[] = [];
  public sub: Subscription = new Subscription();
  public id: string;
  public meta: any = {};
  public currentPage: number = 0;
  public sortDescending: boolean = true;
  public dataEdit: any[];
  public type: string;
  public dateFrom = "";
  public dateTo = "";
  public cost: number = 0;
  public totalItem: number = 0;
  public counterItem: number = 0;

  constructor(
    private loading: LoaderService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  getInspectionList(page = 0, sort = 2) {
    this.loading.displayLoader(true);
    console.log(this.route.parent.params.map(params => params["id"]));
    this.sub.add(
      this.companyService
        .getClaimedList(
          this.route.parent.params.map(params => params["id"]),
          page,
          this.type,
          this.dateFrom,
          this.dateTo,
          sort,
          this.sortDescending,
          "",
          true
        )
        .subscribe(
          data => {
            this.loading.displayLoader(false);
            this.items = data.inspections;
            //console.log(data.meta)
            this.meta = data.meta;
          },
          err => {
            this.loading.displayLoader(false);
            let error = err.json().data;
            if (error.is_business_error) {
              this.modalService.simpleModal(
                `Si è verificato un errore (${error.error_code})`,
                `${error.error_message}`
              );
            } else {
              this.modalService.simpleModal(
                `Errore (${error.error_code})`,
                "Si è verificato un errore"
              );
            }
          }
        )
    );
  }

  public get detail_url(): string {
    let url = "../inspection/";
    return url;
  }

  updateFilters(event) {
    this.type = event.type;
    this.dateFrom = event.dateFrom;
    this.dateTo = event.dateTo;
    this.getInspectionList(this.currentPage);
  }

  nextPage() {
    if (!this.meta.hasNext) return;
    this.currentPage += 1;
    this.getInspectionList(this.currentPage);
  }

  prevPage() {
    if (!this.meta.hasPrevious) return;
    this.currentPage -= 1;
    this.getInspectionList(this.currentPage);
  }

  goToPage(page: number) {
    this.getInspectionList(page);
  }

  ngOnInit() {
    this.getInspectionList();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  sortBy(type: number) {
    this.sortDescending = !this.sortDescending;
    this.getInspectionList(this.currentPage, type);
  }

  downloadExcel() {
    this.loading.displayLoader(true);
    var pages = 0;
    this.dataEdit = [{}];
    this.sub.add(
      this.companyService
        .getAllClaimedList(
          this.route.parent.params.map(params => params["id"]),
          this.type,
          this.dateFrom,
          this.dateTo,
          2,
          this.sortDescending,
          "",
          true
        )
        .subscribe(data => {
          data.inspections.forEach(item => {
            this.counterItem++;
            this.totalItem = data.inspections.length;

            let dataInizio;
            /*	if(item['schedule_date'] != null){
				dataInizio = Convert.unixTimestampToLocalDateString(item['schedule_date'])
			} else*/
            dataInizio = Convert.unixTimestampToLocalDateString(
              item["creation_date"]
            );

            let myItem = {
              Id: item["id"],
              //	"Data inizio": dataInizio,
              "Data rilevazione": Convert.unixTimestampToLocalDateString(
                item["closing_date"]
              ),
              "Data Perizia": Convert.unixTimestampToLocalDateString(
                item["completion_date"]
              ),
              Targa: item["license_plate"],
              Stato: "Periziate",
              //"Costo": item['company_cost'],
              Company: InspectionCompany.getCompany(item["company_id"]),
              Indirizzo: item["address"],
              Perito: item["adjuster_full_name"]
            };
            this.dataEdit.push(myItem);
            //console.log(this.dataEdit.length);
            //		console.log(counter)
            /*if(this.counterItem==999){
					pages=pages+1;
					this.getInspectionsRequest(pages)
				}*/
            //	console.log(data.inspections)
          });

          if (this.counterItem == this.totalItem) {
            this.loading.displayLoader(false);
            Export.exportAsExcelFile(this.dataEdit);
          }

          /*	if(counter == 999 && data.meta.totalPages!=pages){

				for (let i = 1; data.meta.totalPages<pages+1; i++) { 
					this.getInspectionsRequest(pages)
			 }
			 
			 Export.exportAsExcelFile(this.dataEdit);

			} else {
				Export.exportAsExcelFile(this.dataEdit);
			}*/
          //	pages++;
          //	counter=0;
          //	this.getInspectionsRequest(pages)
          //this.loading.displayLoader(false);
          //	} else if(counter>0 && data.meta.totalPages!=pages){

          //	}

          //  if(pages > 0){
          //  for (let i = 1; i<pages+1; i++) {
          //console.log("prova"+i);
          // this.getInspectionsRequest(i);
          // }
          // } else {
          //  Export.exportAsExcelFile(this.dataEdit);
          //  this.loading.displayLoader(false);
          // }
        })
    );

    // Export.exportAsExcelFile(this.dataEdit);
    // this.loading.displayLoader(false);
  }
}
