import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from '../../Services/Dashboard/dashboard.service';
import { Summary } from '../../Models/Summary';
import { Company } from '../../Models/Company';
import { Router } from '@angular/router';
import { LoaderService } from '../../Ui/loader.service';
import { Subscription } from 'rxjs/Rx';
import {PushNotificationService } from 'ngx-push-notifications';
import { environment } from '../../../environments/environment';
import { random } from '@turf/turf';


@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public summary: Summary = new Summary();
  private sub: Subscription = new Subscription();
  public searchQuery: string = "";
  public searchFor: string = "0";

  constructor(public dashboardService: DashboardService, private _pushNotificationService: PushNotificationService, private router: Router, private loading: LoaderService) { }

  ngOnInit() {
    const isGranted = this._pushNotificationService.isPermissionGranted;

    if(!isGranted)
      this._pushNotificationService.requestPermission();
    
      this.summary = this.dashboardService.summary;
   
  }

  goToAddInspection(){
    this.router.navigate(['/home/add-inspection'],{ queryParams:{ 'foobar': new Date().getTime()}});
  }

  rand() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }

  openNotification(notification) {
    this.loading.displayLoader(true)
    this.sub.add(this.dashboardService.setNotificationAsRead(notification.id)
      .finally(() => {
        this.loading.displayLoader(false)
      })
      .subscribe(data => {
        if (!notification.wasRead) {
          this.dashboardService.notificationToRead--
        }
        switch (notification.notification_target_type) {
          case (0):
            break;
          case (1):
            this.router.navigateByUrl('/home/company/' + notification.targetID);
            break;
          case (2):
            this.router.navigateByUrl('/home/registrations/detail/' + notification.targetID);
            break;
          case (3):
            this.router.navigateByUrl('/home/payments/detail/' + notification.targetID);
          default:
            break;
        }
        /*
        if(notification.targetType) {
        this.router.navigateByUrl('home/company/0/inspection/'+notification.targetID);
      }*/
        notification.wasRead = true;
      }, err => {
        console.log(err)
      }))
  }

  markAllAsRead() {
    this.loading.displayLoader(true)
    this.sub.add(this.dashboardService.setAllNotificationsAsRead()
      .finally(() => {
        this.loading.displayLoader(false)
      })
      .subscribe(data => {
        this.dashboardService.notificationSummary.forEach(notification => {
          notification.wasRead = true
        })
      }, err => {
        console.log(err)
      }))
  }

  public search() {
    let searchQuery = this.searchQuery.trim();


    switch (this.searchFor) {
      case "0":
        this.router.navigateByUrl('/home/search/inspection/' + searchQuery)
        break;
      case "5":
        this.router.navigateByUrl('/home/searchlist/address/' + searchQuery)
        break;
      case "1":
        this.router.navigateByUrl('/home/searchlist/plate/' + searchQuery)
        break;
      case "2":
        this.router.navigateByUrl('home/searchlist/user/' + searchQuery)
        break;
      case "3":
        this.router.navigateByUrl('home/user/' + searchQuery)
        break;
      case "4":
        this.router.navigateByUrl('home/company/' + searchQuery)
        break;
    }
  }

  

  logout() {
    localStorage.removeItem('access_token');
    this.router.navigateByUrl('/')
  }
  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  public get userAvatarName() {
    return this.dashboardService.operator.first_name.charAt(0) + " " + this.dashboardService.operator.last_name.charAt(0)
  }

  public get version() {
    return environment.client_version
  }

  public camelize(str) {
    console.log(str)
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
      return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
    }).replace(/\s+/g, '');
  }

}
