import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
	selector: 'sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
	@Input() title: string;
	@Input() structure : any[] = [];

	constructor(private router: Router) { 
	}

	public get _structure() {
		return null
	}

	openNav() {
		document.getElementById("sidebar-wrapper").style.width = "200px";
	  }
	  
	  closeNav() {
		document.getElementById("sidebar-wrapper").style.width = "0";
	}

}
