import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { NetworkingService } from '../Networking/networking.service';
import { Inspector } from '../../Models/Inspector';
import { Inspection } from '../../Models/Inspection';
import { Payment } from '../../Models/Payment';
import { PendingInspection } from '../../Models/PendingInspection';
import { MapsAPILoader } from '@agm/core';

import { Directive, Input } from '@angular/core';
declare var google: any;

@Injectable()
export class UserProfileService {
  public currentUserID: string = ""

  constructor(private mapsAPILoader: MapsAPILoader, private networking: NetworkingService) { }

  public getUser(id: Observable<string>, isToBypassCache: boolean = false): Observable<Inspector> {
    return id.flatMap(id => this.getUserByID(id, isToBypassCache))
  }

  private getUserByID(id: string, isToBypassCache: boolean): Observable<Inspector> {
    return this.networking.get('inspectors/' + id, this.networking.getAuthenticatedHeader(),'application/json', {"isToBypassCache": false}).combineLatest((info, locationAndAddress: any) => {
      let inspector = Inspector.fromJson(info.data)
      if (locationAndAddress && locationAndAddress.location) {
        inspector.inspector_id = locationAndAddress.location.data.inspector_id
        inspector.latitude = locationAndAddress.location.data.latitude
        inspector.longitude = locationAndAddress.location.data.longitude
        inspector.latest_update_time = locationAndAddress.location.data.time
        inspector.address = locationAndAddress.address
      }
      return inspector
    })
  }

  private getAddress(location) {
    return Observable.create(observer => {
      return this.mapsAPILoader.load().then(() => {
        console.log('google script loaded');
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'location': { 'lat': location.data.latitude, 'lng': location.data.longitude } }, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            observer.next(results[0].formatted_address);
            observer.complete();
          } else {
            console.log('Error - ', results, ' & Status - ', status);
            observer.next({});
            observer.complete();
          }
        });
      });
    });
  }


  public getUserLocationById(id: Observable<string>): Observable<any> {
    return id.flatMap(id => this.networking.get('inspectors/' + id + '/location', this.networking.getAuthenticatedHeader()))
      .flatMap(location => {
        return this.getAddress(location).map(address => ({ location, address }))
      });
  }

  public getUserStats(id: Observable<string>): Observable<any> {
    return id.flatMap(id => this.getUserStatsByID(id))
  }

  private getUserStatsByID(id: string): Observable<any> {
    return this.networking.get('inspectors/' + id + '/inspections/stats', this.networking.getAuthenticatedHeader())
      .map(data => {
        return data.data
      })
  }

  public getUserInspections(id: Observable<string>, pageNumber: number, pageSize: number): Observable<any> {
    return id.flatMap(id => this.getInspectionByUserId(id, pageNumber, pageSize))
  }

  private getInspectionByUserId(id: string, pageNumber: number, pageSize: number): Observable<any> {
    let data = {
      "pageNumber": pageNumber,
      "sortby": 2,
      "pageSize": pageSize,
      "isToSortDescending":true

    }
    return this.networking.get('inspectors/' + id + '/inspections/history', this.networking.getAuthenticatedHeader(), 'application/json', data)
      .map(data => {
        let inspections = Inspection.fromJsonArray(data.data)
        return {
          "inspections": inspections,
          "meta": Inspection.getListMeta(data)
        }
      })
  }

  public getUserPendingInspections(id: Observable<string>): Observable<any> {
    return id.flatMap(id => this.getUserPendingInspectionsById(id))
  }

  private getUserPendingInspectionsById(id: string): Observable<any> {
    return this.networking.get(`inspectors/${id}/pendingInspections`, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        return PendingInspection.fromJsonArray(data.data)
      })
  }

  public paymentHistory(id: Observable<string>): Observable<any> {
    return id.flatMap(id => this.getPaymentHistoryById(id))
  }

  public getPaymentHistoryById(id: string) {
    return this.networking.get('inspectors/' + id + '/payments/stats', this.networking.getAuthenticatedHeader())
      .map(data => {
        return data.data
      })
  }


  public paymentList(id: Observable<string>): Observable<any> {
    return id.flatMap(id => this.getPaymentsListById(id))
  }

  private getPaymentsListById(id: string): Observable<any> {

    return this.networking.get('inspectors/' + id + '/payments/history', this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        return Payment.fromJsonArray(data.data)
      })
  }

  public blockUser(id: Observable<string>): Observable<any> {
    return id.flatMap(id => {
      console.log("function not implemented -- user " + id)
      return id
      //return this.networking.delete(`inspectors/${id}`, this.networking.getAuthenticatedHeader(), 'application/json')
    })
  }

  public deleteUser(id: Observable<string>): Observable<any> {
    return id.flatMap(id => {
      return this.networking.delete(`inspectors/${id}`, this.networking.getAuthenticatedHeader(), 'application/json')
    })
  }

  public getActualStatus(id: Observable<string>): Observable<any> {
    return id.flatMap(id => {
      return this.networking.get(`inspectors/${id}/actualStatus`, this.networking.getAuthenticatedHeader(), 'application/json')
        .map(data => {
          return data.data
        })
    })
  }

  public getInspectorsBySurname(surname: string): Observable<any> {
    return this.networking.get(`inspectors/registrations/search/${surname}`, this.networking.getAuthenticatedHeader(), 'application/json')
      .map(data => {
        return Inspector.fromJsonArray(data.data)
      })
  }

  public resetUser(id: Observable<string>): Observable<any> {
    return id.flatMap(id => {
      return this.networking.put(`inspectors/${id}/resetAvailability`, {}, this.networking.getAuthenticatedHeader(), 'application/json')
        .map(data => {
          return data.data
        })
    })
  }

}
