import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Rx';
import { LoaderService } from '../../../Ui/loader.service';
import { DashboardService } from '../../../Services/Dashboard/dashboard.service';
import { ClaimAdjustersService } from '../../../Services/ClaimAdjusters/ClaimAdjusters.service';
import { ModalService } from '../../../Helpers/modal.service';
import { TableStructure } from '../../../Helpers/TableStructure';
import { Operator } from '../../../Models/Operator';

export interface Role {
	id: number;
	value:string;
  }

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
	public addUserForm : FormGroup;
	private sub: Subscription = new Subscription();
	public users: Operator[] = [];
	public tableStructure : any[] = TableStructure.UsersManagement;
	public roleList: any[] = [];

	constructor(private loaderService:LoaderService, private claimAdjusterService:ClaimAdjustersService, private formBuilder: FormBuilder, public dashboardService: DashboardService, public modal:ModalService) {
		this.initializeForm();
	}

	initializeForm() {
		this.addUserForm = this.formBuilder.group({
			name: ["", Validators.compose([Validators.required])],
			surname: ["", Validators.compose([Validators.required])],
			phone: ["", Validators.compose([Validators.required])],
			email: ["", Validators.compose([Validators.required, Validators.email])],
			role: [0, Validators.compose([Validators.required])],
		});
	}
	ngOnInit() {
		this.getUsersList();
		this.getClaimAdjusterList();
		this.getRoleList();
	}

	addUser() {
		this.loaderService.displayLoader(true); // enable spinner

		if(this.addUserForm.value.role==104){

			this.sub.add(this.claimAdjusterService.create(this.addUserForm.value.name, this.addUserForm.value.surname, this.addUserForm.value.phone, this.addUserForm.value.email)
			.finally(() => {
				this.loaderService.displayLoader(false); // disable spinner
			})
			.subscribe(() => {
				this.modal.simpleModal('Utente creato con successo', 'L\'utente è stato creato.')
				this.getUsersList();
				this.initializeForm();
			}, err => {
				let error = err.json().data;
				console.log(error)
				this.loaderService.displayLoader(false); // disable spinner
				if(error.is_business_error) {
					this.modal.simpleModal('Si è verificato un errore', error.error_message)
				} else {
					this.modal.simpleModal('Si è verificato un errore', error.error_code);
				}
			}));

		} else {
			this.sub.add(this.dashboardService.addUser(this.addUserForm.value.name, this.addUserForm.value.surname, this.addUserForm.value.phone, this.addUserForm.value.email, this.addUserForm.value.role)
			.finally(() => {
				this.loaderService.displayLoader(false); // disable spinner
			})
			.subscribe((data) => {
				this.modal.simpleModal('Utente creato con successo', 'L\'utente è stato creato.')
				this.getUsersList();
				this.initializeForm();
			}, err => {
				let error = err.json().data;
				console.log(error)
				this.loaderService.displayLoader(false); // disable spinner
				if(error.is_business_error) {
					this.modal.simpleModal('Si è verificato un errore', error.error_message)
				} else {
					this.modal.simpleModal('Si è verificato un errore', error.error_code);
				}
			}));
		}
	}

	public startEdit(user: Operator) {
		user.editBlocked = false
	}

	public edit(user: Operator) {
		this.loaderService.displayLoader(true);
		console.log(user.profileType);
		this.sub.add(
			this.dashboardService.updateUser(user)
			.finally(() => {
				this.loaderService.displayLoader(false)
			})
			.subscribe(res => {
				user.editBlocked = true
				console.log(res)
			}, err => {
				console.log(err)
				this.modal.simpleModal('Si è verificato un errore', 'non è stato possibile modificare l\'utente');
			})
		)

	}

	private getRoleList() {
		this.loaderService.displayLoader(true); // enable spinner
		this.sub.add(
			this.dashboardService.getRoleList()
			.finally(() => {
				this.loaderService.displayLoader(false); // disable spinner
			})
			.subscribe(roles => {
				this.roleList = roles

				let claimAdjuster:Role = { id:104, value:"Perito"}

				this.roleList.push(claimAdjuster)
				console.log(roles);
			}, err => {
				console.log(err)
			})
		)
	}

	getUsersList() {
		this.loaderService.displayLoader(true); // enable spinner
		this.sub.add(this.dashboardService.getUsersList()
		.finally(() => {
			this.loaderService.displayLoader(false); // disable spinner
		})
		.subscribe(data => {
			this.users = data;
		}, err => {
			this.modal.simpleModal('Si è verificato un errore', 'non è stato possibile caricare la lista degli utenti');
		}));
	}

	getClaimAdjusterList() {
		this.loaderService.displayLoader(true); // enable spinner
		this.sub.add(this.claimAdjusterService.search("")
		.finally(() => {
			this.loaderService.displayLoader(false); // disable spinner
		})
		.subscribe(data => {
			//this.users = data;
			console.log("beriti"+data)
		}, err => {
			this.modal.simpleModal('Si è verificato un errore', 'non è stato possibile caricare la lista degli utenti');
		}));
	}

	public deleteUser(id: string) {
		this.modal.confirmDialog('Eliminazione utente', 'Sei sicuro di voler eliminare questo utente?', () => {
			this.performDelete(id);
		})
	}

	private performDelete(id: string) {
		this.loaderService.displayLoader(true); // enable spinner
		this.sub.add(this.dashboardService.deleteUser(id)
		.finally(() => {
			this.loaderService.displayLoader(false); // disable spinner

		})
		.subscribe(
			data => {
				this.modal.simpleModal('Utente rimosso', 'L\'utente è stato rimosso con successo');
				this.getUsersList();
			},
			err => {
				this.modal.simpleModal('Si è verificato un errore', 'Si è verificato un errore durante l\'elimnazione dell\'utente');
			}
		));
	}

	public get activeUsers() {
		let maxUser = environment.max_users_for_account;
		if(this.users) {
		}
		return "";
	}


	ngOnDestroy() {
		this.sub.unsubscribe();
	}

}
