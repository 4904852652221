import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../../../Ui/loader.service';
import { UserProfileService } from '../../../Services/User/user-profile.service';
import { Subscription } from 'rxjs/Rx';
import { Inspector } from '../../../Models/Inspector';
import { ModalService } from '../../../Helpers/modal.service';
import { TableStructure } from '../../../Helpers/TableStructure';
import { PaymentStatus } from '../../../Helpers/Utils';
import { PaymentService } from '../../../Services/List/Payment/payment.service';
@Component({
	selector: 'app-payments',
	templateUrl: './payments.component.html',
	styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, OnDestroy {
	private sub: Subscription = new Subscription()
	public tableStructure : any[] = TableStructure.Payments;
	public items : any[] = [];
	public user : Inspector = new Inspector()
	public payment : any = {}
	constructor(private loader: LoaderService, private userProfileService: UserProfileService, private route: ActivatedRoute, private modal: ModalService) { }

	ngOnInit() {
		this.getInspectorData()
		this.getPaymentHistory()
		this.getPaymentList()
	}

	private getInspectorData() {
		this.loader.displayLoader(true)
		this.sub.add(
			this.userProfileService.getUser(this.route.parent.params.map(params => params['id']))
			.finally(()=>{
				this.loader.displayLoader(false)
			})
			.subscribe(data => {
				this.loader.displayLoader(false)
				this.user = data
			}, err => {
				this.loader.displayLoader(false)
				this.modal.simpleModal('Si è verificato un errore', 'Si è verificato un errore nel recuperare i dati dell\'utente')
				console.log(err)
			})
		)
	}

	private getPaymentList() {
		this.loader.displayLoader(true);
		this.sub.add(this.userProfileService.paymentList(this.route.parent.params.map(params => params['id']))
		.finally(() => {
			this.loader.displayLoader(false);
		})
		.subscribe(data => {
		   this.items = data;
		}));
	}

	private getPaymentHistory() {
		this.sub.add(
			this.userProfileService.paymentHistory(this.route.parent.params.map(params => params['id']))
			.subscribe(data => {
				this.payment = data
				console.log("payment", data)
			})
		)
	}

	ngOnDestroy() {

	}
}
