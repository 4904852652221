import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable,  } from 'rxjs/Rx';
//import { AuthApiService } from '../../Services/Authentication/auth-api.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss']
})

export class DiaryComponent implements OnInit {

  public mail:string = "info@insoore.com"; 
  public password:string = "Insoore#18"; 
  public apiUrl:string = "https://api.staffomaticapp.com/v3/insoore-srl";

  constructor(private httpClient: HttpClient) {
    
  }

  ngOnInit() {
    
    this.login(this.mail, this.password).subscribe(
      data => {
          console.log("primadata",data);
      }
    );
  }


  login(email: string, password: string): Observable<any> {
    return this.httpClient.get(this.apiUrl+"/locations.json",
        {
          headers: new HttpHeaders({
          'User-Agent': 'Insoore app (francesco.marconi@whoosnap.com)',
          'Content-Type': 'application/json',
          'Authorization': email+":"+password
        })
      }).map(
        data => {
          console.log("data",data);
        })
  }

}
