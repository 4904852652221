import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {InspectorService} from '../../Services/Inspector/inspector.service';

@Component({
  selector: 'app-inspector-autocomplete',
  templateUrl: './inspector-autocomplete.component.html',
  styleUrls: ['./inspector-autocomplete.component.scss']
})
export class InspectorAutocompleteComponent implements OnInit {

  public selectedInspectorValue: any;

  @Input()
  public get selectedInspector() {
    return this.selectedInspectorValue;
  }

  @Output() selectedInspectorChange = new EventEmitter();

  public set selectedInspector(value) {
    this.selectedInspectorValue = value;
    this.selectedInspectorChange.emit(this.selectedInspectorValue);
  }

  constructor(private inspectorService: InspectorService) {
  }

  ngOnInit() {
  }

  searchInspector = (text$: Observable<string>) => {
    return text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {
        return this.inspectorService.search(term)
          .catch((err) => {
            return of([]);
          });
      }).map(items => {
        return items;
      });
  };

  formatter = (x) => {
    return x.fullname;
  };


}
