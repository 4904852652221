export class ClaimAdjusters {
	constructor(
		public adjuster_id: string = "...",
		public first_name : string = "...",
		public last_name : string = "...",
		public phone_number: string = "...",
		public email: string = "...",
	) {}

	assignToObject(json: any) {
		this.adjuster_id = json.adjuster_id;
		this.first_name = json.first_name
		this.last_name = json.last_name
		this.phone_number = json.phone_number
    	this.email = json.email
	}

	static fromJson(json:any) : ClaimAdjusters {
		let operator = new ClaimAdjusters();
		operator.assignToObject(json);
		return operator;
	}

	static fromJsonArray(json: any) : ClaimAdjusters[] {
		return json.map(j => {
			return this.fromJson(j)
		})
	}
}