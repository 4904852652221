import * as moment from 'moment';

export class Credit {
	constructor(
		public n_credits : string = "...",
		public emission_date : number = 0,
    public operator_name: string = "...",
    public operator_id: number = -1,
    public operation_type: number = -1,
    public operation_id: string = "...",
    public id: string = "...",
	) {}

	 assignToObject(json: any) {
		this.n_credits = json.amount;
    this.emission_date = json.operation_date_time;
    this.operator_id = json.operator_id;
		this.operator_name = json.requester_full_name;
    this.id = json.id;
    this.operation_id = json.operation_id;
    this.operation_type = json.operation_type;
	}

	static fromJson(json:any) : Credit {
		let credit = new Credit();
		credit.assignToObject(json);
		return credit;
	}

	static fromJsonArray(json:any) : Credit[] {
		return json.map(j => {
			return this.fromJson(j)
		})
	}


	public get parsed_date(): string {
		return moment.unix(this.emission_date).format('DD/MM/YYYY | HH:mm')
	}

	public static getListMeta(json: any) {
		let meta = {
					"currentPage" : json.data.currentPage,
					"hasNext" : json.data.hasNext,
					"hasPrev" : json.data.hasPrev,
					"recordsPerPage": json.data.recordsPerPage,
					"totalRecords" : json.data.totalRecords,
					"totalPages" : json.data.totalPages
		}
		return meta
	}

}
