import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LocationStrategy } from "@angular/common";
import * as moment from "moment";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"]
})
export class FilterComponent implements OnInit {
  public type = "";
  public dateFrom = "";
  public dateTo = "";
  public filterActive: boolean = false;
  public filterState: boolean = false;
  public state: number = null;

  @Output() applyFilters = new EventEmitter();
  @Output() DownloadXLSEvent: EventEmitter<any> = new EventEmitter();
  @Input() inspectionState: boolean;
  @Input() isExcel: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private url: LocationStrategy
  ) {
    //this.type=-1;
  }

  ngOnInit() {
    this.filterActive = false;
    this.getQueryStringParams(false);
    this.type = "";
    this.state = -1;
  }

  apply(applyFilterActiveUpdate: boolean = true) {
    if (this.type == "-1") this.type = "";
    if (this.dateFrom == undefined) this.dateFrom = "";
    if (this.dateTo == undefined) this.dateTo = "";

    this.applyAllFilters(
      this.type,
      this.dateFrom,
      this.dateTo,
      applyFilterActiveUpdate,
      this.state
    );
  }

  applyAllFilters(
    type,
    dateFrom,
    dateTo,
    applyFilterActiveUpdate: boolean = true,
    state
  ) {
    if (applyFilterActiveUpdate) this.filterActive = true;

    if (this.type == "") this.type = "";

    if (this.state == -1) state = "";
    let filters = {
      type: type,
      dateFrom: dateFrom,
      dateTo: dateTo,
      state: state
    };

    this.saveFiltersToQueryString(filters);
    this.applyFilters.emit(filters);
    //console.log(filters);
  }

  saveFiltersToQueryString(filters: any) {
    let currentRoute = this.url.path().split("?")[0];
    let myType = "";

    if (this.type == "") myType = "";
    else myType = filters.type;

    this.router.navigate([currentRoute], {
      queryParams: {
        type: myType,
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        state: filters.state
      }
    });
  }

  downloadXLS() {
    this.DownloadXLSEvent.emit();
  }

  getQueryStringParams(applyFilterActiveUpdate: boolean = true) {
    this.route.queryParams.subscribe(params => {
      this.type = params.type;
      this.dateFrom = params.dateFrom;
      this.dateTo = params.dateTo;
      this.state = params.state;
      this.apply(applyFilterActiveUpdate);
    });
  }

  resetFilter() {
    this.filterActive = false;

    this.type;
    this.dateFrom = "";
    this.dateTo = "";
    this.state;
    this.apply(false);
  }

  checkButtonDisable() {
    /*if(this.dateFrom && this.dateTo) {
			let from = moment(this.dateFrom).unix();
			let to = moment(this.dateTo).unix();
			if(to < from) return true
			return false
		}*/

    return false;
  }
}
