import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-territory-management',
  templateUrl: './territory-management.component.html',
  styleUrls: ['./territory-management.component.scss']
})
export class TerritoryManagementComponent implements OnInit {

  public menu: any[] = [
    {
      'title': 'Sauron',
      'router_link': '/home/territory/sauron',
      'active_class': 'active',
      'unread': null
    },
    {
      'title': 'Gestione città',
      'router_link': '/home/territory/city',
      'active_class': 'active',
      'unread': null
    },
    {
      'title': 'Schedulate assegnate',
      'router_link': '/home/territory/schedules',
      'active_class': 'active',
      'unread': null
    },
    {
      'title': 'Schedulate non assegnate',
      'router_link': '/home/territory/schedules-unassigned',
      'active_class': 'active',
      'unread': null
    },
    {
      'title': 'Schedulate non confermate',
      'router_link': '/home/territory/schedules-unconfirmed',
      'active_class': 'active',
      'unread': null
    },
    {
      'title': 'Ultime rilevazioni approvate',
      'router_link': '/home/territory/inspection-archive',
      'active_class': 'active',
      'unread': null
    },
    {
      'title': 'Rilevazioni da approvare',
      'router_link': '/home/territory/inspection-to-approve',
      'active_class': 'active',
      'unread': null
    },
    {
      'title': 'Rilevazioni in corso',
      'router_link': '/home/territory/inspection-pending',
      'active_class': 'active',
      'unread': null
    },
    {
      "title" : "Accettate da periziare",
      "router_link" : "/home/territory/toclaim",
      "active_class" : "active",
      "unread" : null
    },
    {
      "title" : "Accettate periziate",
      "router_link" : "/home/territory/claimed",
      "active_class" : "active",
      "unread" : null
    },
    {
      'title': 'Archivio rilevazioni',
      'router_link': '/home/territory/inspection-deleted',
      'active_class': 'active',
      'unread': null
    }
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
