import { Component, OnInit } from '@angular/core';
import { Summary } from '../../Models/Summary';
import { DashboardService } from '../../Services/Dashboard/dashboard.service';
import { Router } from '@angular/router';

@Component({
	selector: 'dashboard-home',
	templateUrl: './dashboard-home.component.html',
	styleUrls: ['./dashboard-home.component.scss']
})
export class DashboardHomeComponent implements OnInit {

	constructor(public dashboardService: DashboardService, private router: Router) { }

	ngOnInit() {

	}
	navigateTo(url) {
		this.router.navigateByUrl('home/'+url)
	}


}
