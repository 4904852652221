import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
