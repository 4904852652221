import { Component, OnInit, OnDestroy } from '@angular/core';
import { TableStructure } from '../../../Helpers/TableStructure';
import { LoaderService } from '../../../Ui/loader.service';
import { Subscription } from 'rxjs/Rx';
import { Registration } from '../../../Models/Registration';
import { RegistrationService } from '../../../Services/List/Registration/registration.service';
import { RegistrationStatus } from '../../../Helpers/Utils';

@Component({
	selector: 'app-registration-wip-wait-email-confirmation',
	templateUrl: './registration-wip-wait-email-confirmation.component.html',
	styleUrls: ['./registration-wip-wait-email-confirmation.component.scss']
})
export class RegistrationWorkInProgressWaitForMailConfirmationComponent implements OnInit, OnDestroy {
	public tableStructure : any[] = TableStructure.Registrations;
	public items : any[] = [];
	public sub : Subscription = new Subscription();
	public meta : any = {};
	public currentPage: number = 0;
	public sortDescending: boolean = true;

	constructor(private loading: LoaderService, private registrationService: RegistrationService ) {
		this.getRegistrationList()
	}

	getRegistrationList(page: number = 0, sort = 2) {
		this.loading.displayLoader(true);
		this.sub.add(this.registrationService.getRegistrationsList(RegistrationStatus.WaitForEmailConfirmation,page, sort, this.sortDescending)
		.finally(() => {
			this.loading.displayLoader(false);
		})
		.subscribe(data => {
			this.items = data.registrations;
			this.meta = data.meta;
			console.log(data.meta);
		}));
	}

	nextPage() {
		if(!this.meta.hasNext) return
		this.currentPage+=1
		this.getRegistrationList(this.currentPage)
	}

	prevPage() {
		if(!this.meta.hasPrevious) return
		this.currentPage-=1
		this.getRegistrationList(this.currentPage)
	}

	goToPage(page: number) {
		this.getRegistrationList(page)
	}

	ngOnInit() {
		//swallos
	}

	sortBy(type: number) {
		this.sortDescending = !this.sortDescending
		this.getRegistrationList(this.currentPage, type)
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

}
