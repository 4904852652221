import * as moment from "moment";
import {
  InspectionStatus,
  InspectionType,
  SummarizedInspectionStatus
} from "../Helpers/Utils";

export class Inspection {
  constructor(
    //  private companyService: CompanyService,
    public id: string = "",
    public request_type: number = 0,
    public abort_reason_id: number = 0,
    public creation_date: number = 0,
    public closing_date: number = 0,
    public commitment_date: number = 0,
    public completion_date: number = 0,
    public arrive_date: number = 0,
    public schedule_date: number = 0,
    public start_date: number = 0,
    public is_commited_to_adjuster: boolean = false,
    public all_media_uploaded_date: number = 0,
    public state: string = "",
    public license_plate: string = "...",
    public inspection_description: string = "...",
    public address: string = "...",
    public adjuster_full_name: string = "...",
    public associated_inspection: any[] = [{}],
    public optional_services: any[] = [{}],
    public schedule: any[] = [{}],
    public associated_review: any[] = [{}],
    public required_services: any[] = [{}],
    public abort_service: any = [{}],
    public contact_info: any[] = [{}],
    public adjuster_commitment: any[] = [{}],
    public company_cost: number = 0,
    public inspector_earning: number = 0,
    public inspectionRequestState: number = 0,
    public latitude: number = 0,
    public longitude: number = 0,
    public media_upload_deadline_date: number = 0,
    public validity_deadline_date: string = "",
    public vehicle_description: any = {},
    public company_name: string = "...",
    public operator_name: string = "...",
    public inspector: string = "...",
    public schedule_booked_by: string = "...",
    public comments: string = "...",
    public is_booked: boolean = false,
    public is_scheduled: boolean = false,
    public color: string = "...",
    public manufacturer: string = "...",
    public insoorer: string = "",
    public company_id: string = "",
    public inspector_id: string = "",
    public rejection_date: number = -1,
    public rejector_full_name: string = "",
    public request_rejection_reason: string = ""
  ) {}

  assignToObject(obj: any) {
    this.id = obj.id;
    this.adjuster_full_name = obj.adjuster_full_name;
    this.commitment_date = obj.commitment_date;
    this.request_type = obj.request_type;
    this.schedule_booked_by = obj.schedule_booked_by;
    this.creation_date = obj.creation_date;
    this.closing_date = obj.closing_date;
    this.completion_date = obj.completion_date;
    this.company_cost = obj.company_cost;
    this.inspector_earning = obj.inspector_earning;
    this.is_commited_to_adjuster = obj.is_commited_to_adjuster;
    this.associated_review = obj.associated_review;
    this.abort_reason_id = obj.abort_reason_id;
    this.contact_info = obj.contact_info;
    this.adjuster_commitment = obj.adjuster_commitment;
    this.arrive_date = obj.arrive_date;
    this.inspector_id = obj.inspector_id;
    this.inspection_description = obj.inspection_description;
    this.start_date = obj.start_date;
    this.schedule_date = obj.schedule_date;
    this.all_media_uploaded_date = obj.all_media_uploaded_date;
    this.state = obj.state ? obj.state.id : "";
    this.license_plate =
      obj.license_plate || obj.vehicle_description.license_plate;
    this.address = obj.address;
    this.schedule = obj.schedule;
    this.optional_services = obj.optional_services;
    this.required_services = obj.required_services;
    this.abort_service = obj.abort_service;
    this.associated_inspection = obj.associated_inspection;
    this.is_booked = obj.is_booked;
    this.latitude = obj.latitude;
    this.longitude = obj.longitude;
    this.media_upload_deadline_date = obj.media_upload_deadline_date;
    this.vehicle_description = obj.vehicle_description;
    this.company_name = obj.company_name;
    this.operator_name = obj.operator_name;
    this.inspector = obj.inspector;
    this.comments = obj.comments;
    this.company_id = obj.requester_company_id || obj.company_id;
    this.rejection_date = obj.rejection_date;
    this.rejector_full_name = obj.rejector_full_name;
    this.request_rejection_reason = obj.request_rejection_reason;
  }

  static fromJson(json: any): Inspection {
    let inspection = new Inspection();
    inspection.assignToObject(json);
    return inspection;
  }

  static fromJsonArray(json: any): Inspection[] {
    return json.map(j => {
      return this.fromJson(j);
    });
  }

  public get parsed_rejection_date(): string {
    if (this.rejection_date) {
      return moment.unix(this.rejection_date).format("DD/MM/YYYY | HH:mm");
    } else {
      return "ND";
    }
  }

  public get parsed_closing_date(): string {
    if (this.closing_date) {
      return moment.unix(this.closing_date).format("DD/MM/YYYY | HH:mm");
    } else if (this.arrive_date) {
      return moment.unix(this.arrive_date).format("DD/MM/YYYY | HH:mm");
    } else {
      return "ND";
    }
  }

  public get parsed_start_date(): string {
    if (this.start_date) {
      return moment.unix(this.start_date).format("DD/MM/YYYY | HH:mm");
    } else {
      return "ND";
    }
  }

  public get parsed_schedule_date(): string {
    if (this.schedule_date) {
      return moment.unix(this.schedule_date).format("DD/MM/YYYY | HH:mm");
    } else {
      return "ND";
    }
  }

  public get parsed_completion_date(): string {
    if (this.completion_date) {
      return moment.unix(this.completion_date).format("DD/MM/YYYY | HH:mm");
    } else {
      return "ND";
    }
  }

  public get parsed_commitment_date(): string {
    if (this.commitment_date) {
      return moment.unix(this.commitment_date).format("DD/MM/YYYY | HH:mm");
    } else {
      return "ND";
    }
  }

  public get parsed_creation_date(): string {
    if (this.creation_date) {
      return moment.unix(this.creation_date).format("DD/MM/YYYY | HH:mm");
    } else {
      return "ND";
    }
  }

  public get parsed_all_media_uploaded_date(): string {
    if (this.all_media_uploaded_date) {
      return moment
        .unix(this.all_media_uploaded_date)
        .format("DD/MM/YYYY | HH:mm");
    } else {
      return "ND";
    }
  }

  public get state_string(): string {
    return InspectionStatus.getInspectionStatusString(this.state);
  }

  public get state_summarize_string(): string {
    return SummarizedInspectionStatus.getSummarizedInspectionStatusString(
      this.state
    );
  }

  public get state_color(): string {
    switch (this.state) {
      case InspectionStatus.Rejected.toString():
        return "#C9302C";
      case InspectionStatus.Expired.toString():
        return "#C9302C";
      case InspectionStatus.Accepted.toString():
        return "#5CB85C";
      case InspectionStatus.Opened.toString():
        return "#EC971F";
      case InspectionStatus.InPending.toString():
        return "#EC971F";
      case InspectionStatus.ScheduledActive.toString():
        return "#EC971F";
      case InspectionStatus.WaitingForScheduledTime.toString():
        return "#EC971F";
      default:
        return "#818a91";
    }
  }

  public get company_string(): string {
    let idCompany = this.company_id;

    if (idCompany == "1") return "Test Company";
    if (idCompany == "2") return "Verti";
    if (idCompany == "3") return "Helvetia";
    if (idCompany == "11") return "HDI Assicurazioni";
    if (idCompany == "12") return "ALD";
    if (idCompany == "13") return "Cattolica Assicurazioni";
    if (idCompany == "14") return "Conte.it";
    if (idCompany == "15") return "UnipolSai";
    if (idCompany == "16") return "Generali";
    if (idCompany == "17") return "Sara Assicurazioni";
    if (idCompany == "18") return "Octo Telematics";
    if (idCompany == "19") return "LoJack";
    if (idCompany == "20") return "Arval";
    if (idCompany == "21") return "Prima.it";
    if (idCompany == "22") return "ALD Repair";
    if (idCompany == "23") return "CarClinic";
    if (idCompany == "24") return "Aon";
    if (idCompany == "25") return "Vittoria Assicurazioni";
    if (idCompany == "26") return "Toyota";
    if (idCompany == "27") return "Reale Mutua";
    if (idCompany == "28") return "Sara Sinistri";
    if (idCompany == "29") return "Quixa";
    if (idCompany == "30") return "Car Server";
    if (idCompany == "31") return "Aon per Sogessur";
    if (idCompany == "32") return "Padana Assicurazioni";
    if (idCompany == "33") return "Jll";
    if (idCompany == "34") return "Italiana Assicurazioni S.p.A.";
    if (idCompany == "35") return "BPM Assicurazioni";
  }

  public get type_string(): string {
    return InspectionType.getInspectionTypeString(this.request_type);
  }

  static getListMeta(json: any) {
    let meta = {
      zeroBasedPageIndex: json.meta.zeroBasedPageIndex,
      hasNext: json.meta.hasNext,
      hasPrevious: json.meta.hasPrevious,
      totalPages: json.meta.totalPages
    };
    return meta;
  }
}
