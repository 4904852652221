import * as moment from 'moment';
import { RegistrationStatus , InspectionType } from '../Helpers/Utils';

export class PendingInspection {
	constructor(
		public assigned_to_inspector_id : string = "...",
		public complete_date_time : number = 0,
		public expire_date: number = 0,
		public license_plate: string = "...",
		public number_of_photos_loaded: number = 0,
		public request_type: number = 0,
		public start_date_time: number = 0,
		public total_number_of_photos: number = 0,
	) {}


	 assignToObject(json: any) {
		this.assigned_to_inspector_id = json.assigned_to_inspector_id;
		this.complete_date_time = json.complete_date_time;
		this.expire_date = json.expire_date;
		this.license_plate = json.license_plate;
		this.number_of_photos_loaded = json.number_of_photos_loaded;
		this.request_type = json.request_type;
		this.start_date_time = json.start_date_time;
		this.total_number_of_photos = json.total_number_of_photos;
	}

	static fromJson(json:any) : PendingInspection {
		let inspection = new PendingInspection();
		inspection.assignToObject(json);
		return inspection;
	}


	static fromJsonArray(json: any): PendingInspection[] {
	  return json.map(j => {
		return this.fromJson(j)
	  })
	}


	public get typology() {
		return InspectionType.getInspectionTypeString(this.request_type)
	}

	public get start() {
		return moment.unix(this.start_date_time).format('DD/MM/YYYY | HH:mm')
	}

	public get completed() {
		return moment.unix(this.complete_date_time).format('DD/MM/YYYY | HH:mm')
	}

	public get expire() {
		return moment.unix(this.expire_date).format('DD/MM/YYYY | HH:mm')
	}

	public get pictures() {
		return this.number_of_photos_loaded+"/"+this.total_number_of_photos
	}

}
