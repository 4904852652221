
import { NotificationType } from '../Helpers/Utils';export class Notification {
    constructor(
		public id : string = "",
		public notificationType: number = 0,
		public notificationText: string = "",
		public notificationTitle: string = "",
		public targetType: number = 0,
		public targetID: number = 0,
		public wasRead: boolean = false,
		public creationDate: Date = new Date(),
		public notification_target_type: number = 0
	) {}

	public get icon(): string {
		return NotificationType.getNotificationIconClass(this.notificationType);
	}

	static fromJson(json:any) : Notification {
		let notification = Object.create(Notification.prototype);
		notification.id = json.id,
		notification.notificationType = json.notification_types,
		notification.notificationText = json.text,
		notification.notificationTitle = json.title
		notification.targetType = json.notification_target_type,
		notification.targetID = json.target_id,
		notification.wasRead = json.was_read,
		notification.creationDate = new Date(json.creation_date),
		notification.notification_target_type = json.notification_target_type
		Object.assign(notification, json);
		return notification;
	}

	static fromJsonArray(json:any) : Notification[] {
		return json.map(j => {
			return this.fromJson(j)
		})
	}

	static getListMeta(json: any) {
		return {
				"currentPage" : json.page_index,
				"hasNext" : json.has_next_page,
				"hasPrev" : json.has_previous_page,
				"recordsPerPage": json.page_size,
				"totalRecords" : json.total_pages,
				"totalPages" : json.total_pages
			}
	}
}
