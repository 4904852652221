import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DashboardService } from "../../Services/Dashboard/dashboard.service";
import { ArchwizardModule } from "ng2-archwizard/dist/index";
import { StoreItemService } from "../../store-item.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CompanyService } from "../../Services/Company/company.service";
import {
  NetworkingService,
  Endpoints
} from "../../Services/Networking/networking.service";
import { LoaderService } from "../../Ui/loader.service";
import { ModalService } from "../../Helpers/modal.service";
import { Subscription, Observable, Subject } from "rxjs/Rx";
import { ViewEncapsulation } from "@angular/core";
import { CompleterService, CompleterData } from "ng2-completer";
import { InspectionCompany } from "../../Helpers/Utils";
import { Address } from "ngx-google-places-autocomplete/objects/address";

declare var google: any;
var dataInspection = {};

export interface ServiceConfiguration {
  serviceId: number;
  isRequired: boolean;
  name: string;
}

export interface InsoorerNameSurname {
  nameSurname: string;
  surname: string;
  id: string;
}

export interface ServiceConfigurationApi {
  serviceId: number;
  isRequired: boolean;
}

@Component({
  selector: "app-add-inspection",
  templateUrl: "./add-inspection.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-inspection.component.scss"]
})
export class AddInspectionComponent implements OnInit {
  sub: Subscription = new Subscription();
  public step: string = "Step 1";
  public companySelected: boolean = false;
  public isScheduled: boolean = false;
  public inspectionTypeName: string;
  public inspectionTypeId: string;
  public thumbnail: string = "";
  public note: string;
  public insoorerAssigned: string;
  public insoorer: string;
  public address: string;
  public colors: any[];
  public templateList: any[] = [];
  public serviceList: any[] = [];
  public inputType: string = "0";
  public manufacturers: any[];
  public confirm: boolean = false;
  public color: number;
  public company: string;
  public InspectionRequestType: string;
  public inspectionDate: string;
  public scheduleHour: string;
  public scheduleMinute: string;
  public manufacturer: string;
  public plate: string;
  public nomeInsoorer: string;
  public rimborsoGenerico: string;
  public phone: string;
  public email: string;
  public name: string;
  public surname: string;
  public myAddress: string;
  public ServiceConfigurationList: any[] = [];
  public ServiceConfigurationApiList: any[] = [];
  //	public serviceConfiguration: ServiceConfiguration;
  public count: number = 0;
  public arrayFlag: any[] = [];
  public countStep2: number = 0;
  public conta: number = 0;
  public checkTarga: string;
  public reportForm: FormGroup;
  public initial: string;
  public searchStr: string = "";
  public searchStr2: string = "";
  public textInsoorer: string = "";
  public dataService: CompleterData;
  public searchData = [];
  public insoorerList: InsoorerNameSurname[];
  public data$ = new Subject();
  public idInspector: string = "";
  public nameInspector: string = "";
  public resultsFetched: boolean = false;
  public isFirstChar = false;
  public terms: any[] = [];
  public termsValue: any[] = [];
  public statusBtnAdd: boolean = true;
  public disabled: boolean = true;
  public indirizzo: string;
  public lat: string;
  public lng: string;
  public testoLat: string;

  constructor(
    private completerService: CompleterService,
    public dashboardService: DashboardService,
    private networking: NetworkingService,
    private storeItem: StoreItemService,
    private loaderService: LoaderService,
    public wizard: ArchwizardModule,
    public formBuilder: FormBuilder,
    public router: Router,
    private companyService: CompanyService,
    private loader: LoaderService,
    private modalService: ModalService
  ) {
    this.loader.displayLoader(false);
    this.getManufacturersAndColors();
    this.dataService = this.completerService.local(
      this.data$,
      "surname",
      "nameSurname"
    );
  }

  ngOnInit() {
    this.resetForm();

    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  showDate() {
    if (this.reportForm.value.type == 2) {
      return true;
    }
    return false;
  }
  showAddress() {
    if (this.reportForm.value.addMethodLocalita == 1) {
      return true;
    }
    return false;
  }

  setAddress(event: Address) {
    this.lng = event.geometry.location.lng().toFixed(6);
    this.lat = event.geometry.location.lat().toFixed(6);
    this.reportForm.patchValue({ longitude: this.lng });
    this.reportForm.patchValue({ latitude: this.lat });
    this.reportForm.patchValue({ address: event.formatted_address });
  }

  getManufacturersAndColors() {
    this.getManufacturersAndColors2().subscribe(data => {
      this.colors = data.colors;
      this.manufacturers = data.manufacturers;
    });
  }

  reset() {
    this.data$.next();
    this.searchStr2 = "";
    this.searchStr = "";
    document.getElementById("txtInsoorer").style.visibility = "visible";
    document.getElementById("txtInsoorer").focus();
    this.resultsFetched = false;
    this.disabled = true;
    this.statusBtnAdd = true;
  }

  SearchUsers(event) {
    if (event.key == "Backspace") this.reset();

    if (this.searchStr.length > 2) {
      document.getElementById("txtInsoorer").style.visibility = "hidden";
      document.getElementById("txtAutocomplete").focus();
    }

    this.searchStr2 = this.searchStr;

    if (
      !this.resultsFetched &&
      event.key != "Backspace" &&
      this.searchStr2.length > 0
    ) {
      this.dashboardService.getInsoorerList(this.searchStr).subscribe(
        data => {
          data.forEach(item => {
            if (item.first_name) {
              let insoorer: InsoorerNameSurname = {
                surname: item.last_name,
                nameSurname: item.last_name + " " + item.first_name,
                id: item.id
              };
              if (insoorer) {
                this.insoorerList.push(insoorer);
              }
            }
          });

          if (this.insoorerList && this.insoorerList.length > 0)
            this.data$.next(this.insoorerList);
        },
        err => {}
      );

      this.resultsFetched = true;
      this.insoorerList = [];
    }

    this.searchStr2 = this.searchStr;
  }

  selectUsers(event) {
    if (event) this.idInspector = event.originalObject.id;
    this.nameInspector = event.originalObject.nameSurname;
    this.disabled = false;
    this.statusBtnAdd = false;
  }

  isDisabled() {
    return this.disabled;
  }

  getColorList() {
    return this.networking
      .get(
        "values/colorList",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  getManufacturersList() {
    return this.networking
      .get(
        "values/vehicleManufacturers",
        this.networking.getAuthenticatedHeader(),
        "application/json"
      )
      .map(data => {
        return data.data;
      });
  }

  buildBearerToken() {
    return "Bearer " + this.getAuthenticationTokenFromLocalStorage();
  }

  getAuthenticationTokenFromLocalStorage() {
    return this.storeItem.read("access_token");
  }

  getManufacturersAndColors2() {
    var colorsListObservable = this.getColorList();
    var manufacturersListObservable = this.getManufacturersList();
    return colorsListObservable.combineLatest(
      manufacturersListObservable,
      (colors, manufacturers) => {
        return { colors, manufacturers };
      }
    );
  }

  getType() {
    this.loaderService.displayLoader(true);
    let idCompany = this.reportForm.value.addMethod;

    if (idCompany != 0) {
      this.sub.add(
        this.dashboardService
          .getTemplateList(idCompany)
          .finally(() => {
            this.companySelected = true;
            this.loaderService.displayLoader(false);
          })
          .subscribe(
            template => {
              this.templateList = template;
              console.log(template);
            },
            err => {}
          )
      );
    } else {
      this.companySelected = false;
      this.loaderService.displayLoader(false);
    }
  }

  getColor() {
    this.color = this.colors.filter(item => {
      return item.id == this.reportForm.value.color;
    })[0].value;
  }

  getManufacturer() {
    this.manufacturer = this.manufacturers.filter(item => {
      return item.id == this.reportForm.value.manufacturer;
    })[0].value;
  }

  checkRequired(step) {
    if (step == "2") this.step = "Step 2";
    else if (step == "3") this.step = "Step 3";
    else if (step == "4") this.step = "Step 4";
  }

  checked(idService) {
    let isDefault = false;

    if (
      this.ServiceConfigurationApiList.find(x => x.serviceId === idService) !=
      null
    )
      isDefault = this.ServiceConfigurationApiList.find(
        x => x.serviceId === idService
      ).isRequired;

    return isDefault;
  }

  resetForm() {
    dataInspection = {};
    this.loader.displayLoader(false);
    this.confirm = false;
    this.count = 0;
    this.countStep2 = 0;
    this.arrayFlag = [];
    this.conta = 0;
    this.isScheduled = false;
    this.scheduleHour = "";
    this.scheduleMinute = "";
    this.inspectionDate = "";
    this.nomeInsoorer = "";
    this.plate = "";
    this.phone = "";
    this.email = "";
    this.name = "";
    this.surname = "";
    this.note = "";
    this.inspectionDate = "";
    this.nameInspector = "";
    this.idInspector = "";
    this.insoorerAssigned = this.nameInspector;
    this.templateList = [];
    this.serviceList = [];
    this.ServiceConfigurationList = [];
    this.ServiceConfigurationApiList = [];
    this.setScheduled(false);

    this.reportForm = this.formBuilder.group({
      manufacturer: [0, Validators.compose([])],
      nomeInsoorer: ["", Validators.compose([])],
      rimborsoGenerico: ["", Validators.compose([])],
      plate: ["", Validators.compose([])],
      Insoorer: ["", Validators.compose([])],
      phone: ["", Validators.compose([])],
      email: ["", Validators.compose([])],
      name: ["", Validators.compose([])],
      surname: ["", Validators.compose([])],
      note: ["", Validators.compose([])],
      color: [0, Validators.compose([])],
      inspectionDate: ["", Validators.compose([])],
      scheduleHour: ["", Validators.compose([])],
      scheduleMinute: ["", Validators.compose([])],
      assignment: ["", Validators.compose([])],
      type: [0, Validators.compose([Validators.required])],
      InspectionRequestType: [0, Validators.compose([Validators.required])],
      addMethodLocalita: [0, Validators.compose([Validators.required])],
      addMethod: [0, Validators.compose([Validators.required])],
      latitude: ["", Validators.compose([Validators.required])],
      completer: ["", Validators.compose([Validators.required])],
      longitude: ["", Validators.compose([Validators.required])],
      address: ["", Validators.compose([])]
    });
  }

  getService(e) {
    this.inspectionTypeName = e.target.parentNode.innerText;
    this.inspectionTypeId = e.target.parentNode.id;
    let idCompany = this.reportForm.value.addMethod;
    //this.setButton();
    if (idCompany != 0) {
      this.sub.add(
        this.dashboardService
          .getServiceList(idCompany, this.inspectionTypeId)
          .finally(() => {})
          .subscribe(
            data => {
              this.serviceList = data;
              this.ServiceConfigurationList = [];
              this.ServiceConfigurationApiList = [];

              for (let myserviceConfiguration of this.serviceList) {
                if (myserviceConfiguration.is_active_by_default == true) {
                  let serviceConfiguration: ServiceConfiguration = {
                    isRequired: myserviceConfiguration.is_required_by_default,
                    serviceId: myserviceConfiguration.id,
                    name: myserviceConfiguration.name
                  };

                  let serviceConfigurationApi: ServiceConfigurationApi = {
                    isRequired: myserviceConfiguration.is_required_by_default,
                    serviceId: myserviceConfiguration.id
                  };

                  this.ServiceConfigurationList.push(serviceConfiguration);
                  this.ServiceConfigurationApiList.push(
                    serviceConfigurationApi
                  );
                }
              }
            },
            err => {}
          )
      );
    }
  }

  selectService(e) {
    let myCheck = document.getElementById(
      "required_" + e.target.parentNode.id
    ) as HTMLInputElement;
    let required: boolean;

    if (e.target.checked) {
      if (myCheck.checked == true) required = true;
      else required = false;

      let serviceConfiguration: ServiceConfiguration = {
        isRequired: required,
        serviceId: e.target.parentNode.id,
        name: e.target.parentNode.innerText
      };

      let serviceConfigurationApi: ServiceConfigurationApi = {
        isRequired: required,
        serviceId: e.target.parentNode.id
      };

      this.ServiceConfigurationList.push(serviceConfiguration);
      this.ServiceConfigurationApiList.push(serviceConfigurationApi);

      document
        .getElementById("required_" + e.target.parentNode.id)
        .removeAttribute("disabled");
    } else {
      let index = this.ServiceConfigurationList.findIndex(
        x => x.serviceId == e.target.parentNode.id
      );
      let index2 = this.ServiceConfigurationApiList.findIndex(
        x => x.serviceId == e.target.parentNode.id
      );

      this.ServiceConfigurationList.splice(index, 1);
      this.ServiceConfigurationApiList.splice(index2, 1);

      document
        .getElementById("required_" + e.target.parentNode.id)
        .setAttribute("disabled", "disabled");
    }
  }

  setScheduled(value) {
    console.log(value);
    if (value == "true") {
      this.isScheduled = true;
      document.getElementById("inspectionDate").removeAttribute("disabled");
      document.getElementById("scheduleHour").removeAttribute("disabled");
      document.getElementById("scheduleMinute").removeAttribute("disabled");
      document
        .getElementById("scheduleDateLabel")
        .setAttribute("style", "visibility: visible");
      document
        .getElementById("scheduleTimeLabel")
        .setAttribute("style", "visibility: visible");
    } else {
      this.isScheduled = false;
      document
        .getElementById("inspectionDate")
        .setAttribute("disabled", "true");
      document.getElementById("scheduleHour").setAttribute("disabled", "true");
      document
        .getElementById("scheduleMinute")
        .setAttribute("disabled", "true");
      document
        .getElementById("scheduleDateLabel")
        .setAttribute("style", "visibility: hidden");
      document
        .getElementById("scheduleTimeLabel")
        .setAttribute("style", "visibility: hidden");
    }
    //this.setButton();
  }

  updateMap() {
    if (this.reportForm.value.latitude && this.reportForm.value.longitude) {
      this.lat = this.reportForm.value.latitude;
      this.lng = this.reportForm.value.longitude;

      this.reverseGeocoding(
        this.reportForm.value.latitude,
        this.reportForm.value.longitude
      );
    }

    this.initMap(this.lat, this.lng);
  }

  updateReport() {
    this.getColor();
    this.getManufacturer();
    this.company = InspectionCompany.getCompany(
      this.reportForm.value.addMethod
    );
    this.note = this.reportForm.value.note;
    this.inspectionDate = this.reportForm.value.inspectionDate;
    this.scheduleHour = this.reportForm.value.scheduleHour;
    this.scheduleMinute = this.reportForm.value.scheduleMinute;
    this.plate = this.reportForm.value.plate;
    this.insoorer = this.reportForm.value.Insoorer;
    this.phone = this.reportForm.value.phone;
    this.email = this.reportForm.value.email;
    this.name = this.reportForm.value.name;
    this.surname = this.reportForm.value.surname;

    if (this.showAddress()) this.address = this.reportForm.value.address;
  }

  onChangeInput(search: string) {
    console.log("prova");
  }

  initMap(lat, lng) {
    let mapProp = {
      center: new google.maps.LatLng(lat, lng),
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    let icon =
      this.reportForm.value.type == 1
        ? "assets/crash.png"
        : "assets/assuntiva.png";
    let map = new google.maps.Map(document.getElementById("g-map"), mapProp);
    let map1 = new google.maps.Map(document.getElementById("g-map1"), mapProp);
    let marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map: map,
      icon: icon
    });

    let marker1 = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map: map1,
      icon: icon
    });
  }

  reverseGeocoding(lat, lng) {
    var addr = Observable.create(observer => {
      var geocoder = new google.maps.Geocoder();
      var latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
      geocoder.geocode({ location: latlng }, function(results, status) {
        if (status === "OK") {
          if (results[1]) {
            observer.next(results[1].formatted_address);
          }
        }
      });
    });

    var subscription = addr.subscribe(x => (this.address = x));
  }

  createReport2() {
    this.loaderService.displayLoader(true); // enable spinner
    this.sendReport(
      this.isScheduled,
      this.reportForm.value.addMethod,
      this.inspectionTypeId,
      this.reportForm.value.plate,
      this.reportForm.value.phone,
      this.reportForm.value.email,
      this.reportForm.value.name,
      this.reportForm.value.surname,
      this.reportForm.value.manufacturer,
      this.reportForm.value.color,
      this.reportForm.value.latitude,
      this.address,
      this.reportForm.value.longitude,
      this.reportForm.value.inspectionDate,
      this.reportForm.value.scheduleHour,
      this.reportForm.value.scheduleMinute,
      this.reportForm.value.assignment,
      this.reportForm.value.note,
      this.idInspector
    ).subscribe(
      data => {
        this.loaderService.displayLoader(false); // disable spinner
        this.modalService.simpleModal(
          "Successo",
          "La rilevazione è stata creata con successo"
        );
        this.router.navigateByUrl(
          "/home/company/" +
            this.reportForm.value.addMethod +
            "/inspection/" +
            data.id
        );
      },
      err => {
        let error = err.json().data;
        this.loaderService.displayLoader(false); // disable spinner

        if (error.is_business_error)
          this.modalService.simpleModal(
            `Codice errore (${error.error_code})`,
            `Tipo di errore: ${error.error_message}`
          );
        else
          this.modalService.simpleModal(`ERRORE`, `Errore generico del server`);
      }
    );
  }

  sendReport(
    isScheduled: boolean,
    company: string,
    template: string,
    plateNumber: string,
    phone: string,
    email: string,
    name: string,
    surname: string,
    manufacturer: number,
    color: number,
    latitude: number,
    address: string,
    longitude: number,
    scheduleDay: string,
    scheduleHour: number,
    scheduleMinute: string,
    idAssignment: string,
    notes: string,
    inspectorId: string
  ) {
    if (phone) {
      phone.replace("+39", "");
      phone = phone.trim();
    }

    this.pupulateDict("TemplateId", template);
    this.pupulateDict("PlateNumber", plateNumber);
    this.pupulateDict("CompanyId", company);
    this.pupulateDict("Manufacturer", manufacturer);
    this.pupulateDict("Color", color);
    this.pupulateDict("Latitude", latitude);
    this.pupulateDict("Address", address);
    this.pupulateDict("Longitude", longitude);
    this.pupulateDict("Notes", notes);
    this.pupulateDict("MappingId", idAssignment);
    this.pupulateDict("ServiceConfiguration", this.ServiceConfigurationApiList);

    if (this.idInspector && this.idInspector != "")
      this.pupulateDict("AssignedToInspectorId", this.idInspector);

    if (isScheduled == true) {
      this.pupulateDict("IsToCreateAsScheduled", true);
      this.pupulateDict("ScheduleInformations", {
        Day: new Date(scheduleDay),
        Hour: scheduleHour,
        Minutes: scheduleMinute
      });
    } else {
      this.pupulateDict("IsToCreateAsScheduled", false);
    }

    if (phone) {
      this.pupulateDict("RequestContactInfo", {
        FirstName: name,
        LastName: surname,
        PhoneNumber: "+39" + phone,
        Email: email,
        Notes: ""
      });
    }

    return this.networking
      .post(
        "requests",
        this.createDataDict(),
        this.networking.getAuthenticatedHeader(),
        "application/json",
        Endpoints.default
      )
      .map(data => {
        return data.data;
      });
  }

  pupulateDict(keyName, valueName) {
    this.terms.push(keyName);
    this.termsValue.push(valueName);
  }

  createDataDict() {
    let i = 0;
    for (let key of this.terms) {
      dataInspection[key] = this.termsValue[i];
      i++;
    }

    return dataInspection;
  }

  removeInsoorer() {
    this.nameInspector = "";
    this.idInspector = "";
    this.insoorerAssigned = this.nameInspector;
    document.getElementById("insooreContainer").style.visibility = "hidden";
  }

  addInsoorer() {
    document.getElementById("insooreContainer").style.visibility = "visible";

    if (this.nameInspector) this.insoorerAssigned = this.nameInspector;
  }

  checkInput(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || event.key == ".") {
      return true;
    }
    return false;
  }

  checkPasteInput(event) {
    return !isNaN(event.clipboardData.getData("text/plain"));
  }

  /*setButton(){
			if(this.companySelected == true){
				if(this.InspectionRequestType != "null"){
					if(this.isScheduled == false){
						(<HTMLSelectElement> document.getElementById("Prosegui")).disabled = false;
						document.getElementById("Prosegui").style.cursor = "pointer";
					}
					else if(this.count == 3)
					{
						(<HTMLSelectElement> document.getElementById("Prosegui")).disabled = false;
						document.getElementById("Prosegui").style.cursor = "pointer";

					}
					else{
						(<HTMLSelectElement> document.getElementById("Prosegui")).disabled = true;
						document.getElementById("Prosegui").style.cursor = "not-allowed";

					}
				}
			}
	}*/

  setDate(event) {
    if (event.type == "change") {
      this.count = this.count + 1;
      //	this.setButton();
    }
  }

  setHour(event) {
    if (event.type == "change") {
      this.count = this.count + 1;
      //	this.setButton();
    }
  }

  setMinute(event) {
    if (event.type == "change") {
      this.count = this.count + 1;
      //	this.setButton();
    }
  }

  setButtonProsegui2(serviceId) {
    var element = <HTMLInputElement>(
      document.getElementById("required_" + serviceId)
    );
    var isChecked = element.checked;

    let serviceConfigurationApi: ServiceConfigurationApi = {
      isRequired: isChecked,
      serviceId: serviceId
    };

    let index = this.ServiceConfigurationApiList.findIndex(
      x => x.serviceId == serviceId
    );

    if (index > -1) this.ServiceConfigurationApiList.splice(index, 1);

    this.ServiceConfigurationApiList.push(serviceConfigurationApi);

    if (isChecked == true) {
      this.arrayFlag.push(serviceId);
    } else {
      this.arrayFlag.pop();
    }
  }
}
