import * as moment from "moment";

export class Payment {
  constructor(
    public id: string = "...",
    public name: string = "...",
    public surname: string = "...",
    public email: string = "...",
    public paypal_email: string = "...",
    public inspector_id: string = "...",
    public payment_request_date: number = 0,
    public requested_amount: number = 0,
    public month_requests_number: number = 0,
    public yearly_cashes: number = 0,
    public collected_cashes: number = 0,
    public status = "",
    public responsible_backend_operator_name: string = "...",
    public rejection_reason: string = "..."
  ) {}

  assignToObject(json: any) {
    this.id = json.id;
    this.name = json.inspector_name || json.name;
    this.surname = json.inspector_surname || json.surname;
    this.email = json.inspector_email || json.email;
    this.paypal_email = json.inspector_paypal_email;
    this.inspector_id = json.inspector_id;
    this.payment_request_date = json.request_date;
    this.requested_amount = json.amount;
    this.month_requests_number = json.month_requests_number;
    this.yearly_cashes = json.yearly_cashes;
    this.collected_cashes = json.total_collected_cashes;
    this.status = json.status;
    this.responsible_backend_operator_name =
      json.responsible_backend_operator_name;
    this.rejection_reason = json.rejection_reason;
  }

  static fromJson(json: any): Payment {
    let payment = new Payment();
    payment.assignToObject(json);
    return payment;
  }

  static fromJsonArray(json: any): Payment[] {
    return json.map(j => {
      return this.fromJson(j);
    });
  }

  public get button_name(): string {
    return "Visualizza";
  }

  public get payment_detail_header_name(): string {
    return this.isAccepted ? "Accettato da" : "Rifiutato da";
  }

  public get amount() {
    return this.requested_amount ? this.requested_amount.toFixed(2) : 0;
  }

  public get yearlyCashes() {
    return this.yearly_cashes ? this.yearly_cashes.toFixed(2) : 0;
  }

  public get collectedCashes() {
    return this.collected_cashes ? this.collected_cashes.toFixed(2) : 0;
  }

  public get isToBeBooked() {
    return this.status === "1";
  }

  public get isAccepted() {
    return this.status === "2";
  }

  public get isRefused() {
    return this.status === "3";
  }

  public get isBooked() {
    return this.status === "4";
  }

  public get wasProcessed() {
    return this.isAccepted || this.isRefused;
  }
  public get parsed_date(): string {
    return moment.unix(this.payment_request_date).format("DD/MM/YYYY | HH:mm");
  }

  public static getListMeta(json: any) {
    let meta = {
      zeroBasedPageIndex: json.meta.zeroBasedPageIndex,
      hasNext: json.meta.hasNext,
      hasPrevious: json.meta.hasPrevious,
      totalPages: json.meta.totalPages
    };
    return meta;
  }
}
