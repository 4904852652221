import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-number-card',
  templateUrl: './number-card.component.html',
  styleUrls: ['./number-card.component.scss']
})
export class NumberCardComponent implements OnInit {
	@Input() number: string = ""
	@Input() label: string = ""
	@Input() color: string = ""

  constructor() { }

  ngOnInit() {
  }

}
