import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'lpad'
})
export class LPadPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const [size, str] = args.split(':');
    return value.toString().padStart(parseInt(size, 10), str);
  }

}
