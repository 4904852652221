import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router,} from '@angular/router';
import {ZoneService} from '../../../Services/Zone/zone.service';
import {CityService} from '../../../Services/City/city.service';
import {Zone} from '../../../Models/Zone';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/onErrorResumeNext';
import 'rxjs/add/operator/merge';
import {InspectorService} from '../../../Services/Inspector/inspector.service';
import {MapsService} from '../../../Services/Maps/maps.service';
import {APP_SETTINGS} from '../../../app.settings';
import {LoaderService} from '../../../Ui/loader.service';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs/Subject';
import {ModalService} from '../../../Helpers/modal.service';

@Component({
  selector: 'app-zone-management',
  templateUrl: './zone-management.component.html',
  styleUrls: ['./zone-management.component.scss']
})
export class ZoneManagementComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  public ZONE_DEFAULT_COLOR = APP_SETTINGS.TERRITORY_MANAGEMENT.ZONE_DEFAULT_COLOR;
  public city: any = {};
  public map: any;
  public selectedInspector: any;
  // public inspectors: any[] = [];
  public zone: Zone = new Zone();
  cityId: number;
  zoneId: number;

  constructor(private cityService: CityService,
              private zoneService: ZoneService,
              private inspectorService: InspectorService,
              private route: ActivatedRoute,
              private router: Router,
              private mapService: MapsService,
              private loaderService: LoaderService,
              private modalService: ModalService) {
  }

  ngOnInit() {
    const routeSnapshot: ActivatedRouteSnapshot = this.route.snapshot;
    this.zoneId = +routeSnapshot.params['zoneId'];
    this.cityId = +routeSnapshot.params['id'];
    this.loadZone();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadZone() {
    this.loaderService.displayLoader(true);

    this.cityService.getZone(this.cityId, this.zoneId).finally(() => this.loaderService.displayLoader(false))
      .takeUntil(this.ngUnsubscribe)
      .subscribe(zone => {
        this.zone = zone;
        this.zone.assigned_inspectors = zone.assigned_inspectors.map((i) => {
          i['id'] = i['inspector_id'];
          return i;
        });
      });
  }

  loadCity() {
    this.cityService.get(this.cityId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(city => {
        this.mapService.setMapCenter(city.mapping_id, this.map);
        this.city = city;
      });
  }

  deleteZone() {
    this.zoneService.deleteConfirm(this.zone, () => {
      this.router.navigate([`/home/territory/city/${this.zone.cityId}`]);
    });
  }

  inspectorAddFormSubmit(form) {
    this.inspectorService.zoneAssign(this.selectedInspector.id, this.zoneId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (data) => {
          this.zone.assigned_inspectors.push(this.selectedInspector);
          this.selectedInspector = null;
          form.reset();
        }, err => this.modalService.errorModal(err.json().data)
      );
  }

  inspectorZoneRemove(inspector) {
    this.inspectorService.zoneRemoveConfirm(inspector, this.city,
      (data) => {
        this.zone.assigned_inspectors.splice(this.city.zones.indexOf(inspector), 1);
      }
    );
  }

  mapReady(map: google.maps.Map) {
    this.map = map;
    this.loadCity();
    // set default map zoom
    map.setZoom(environment.gmap_default_start_point.zoom);
  }
}
