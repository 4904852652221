export const APP_SETTINGS = {
  TERRITORY_MANAGEMENT: {
    ZONE_DEFAULT_COLOR: '#D9534F',
    ZONE_READONLY_COLOR: '#C0C0C0',
    ZONES_COLORS: [
      '#ffe728',
      '#ff0200',
      '#3581ff',
      '#ff8986',
      '#3714ff',
      '#0dff00',
      '#2effe7',
      '#feffcd',
      '#ffbc52',
      '#f59fff',
      '#737fff',
      '#d5ffe6',
      '#e6ff97',
      '#6602ff'
    ]
  }
};
