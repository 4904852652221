export class TableStructure {
  public static readonly UserProfilePendingInspections: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Tipologia",
        "key": "typology"
      },
      {
        "title": "Presa In carico",
        "key": "start"
      },
      {
        "title": "Completata",
        "key": "completed"
      },
      {
        "title": "Scade il",
        "key": "expire"
      },
      {
        "title": "Ricevute",
        "key": "pictures"
      },
      {
        "title": "Targa",
        "key": "license_plate"
      }
    ];

  public static readonly Registrations: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Nome",
        "key": "name",
        "sort": 0
      },
      {
        "title": "Cognome",
        "key": "surname",
        "sort": 1
      }, 
      {
        "title": "Email",
        "key": "email",
        "sort": 3
      },
      {
        "title": "Data di registrazione",
        "key": "parsed_date",
        "sort": 2
      },
      {
        "title": "",
        "key": "button_name"
      }
    ];

  public static readonly CompanyOperators: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Nome",
        "key": "first_name",
      },
      {
        "title": "Cognome",
        "key": "last_name",
      },
      {
        "title": "Telefono",
        "key": "phone_number",
      },
      {
        "title": "Tipologia utente",
        "key": "role_name",
      },
      {
        "title": "",
        "key": ""
      }
    ];

    public static readonly InspectionEvents: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Data",
        "key": "parsed_event_date",
        
      },
      {
        "title": "Ora",
        "key": "parsed_event_hour",
      },
      {
        "title": "Evento",
        "key": "parsedEventName",
      }
    ];

  public static readonly InspectorEvents: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Data",
        "key": "parsed_event_date",
      },
      {
        "title": "Ora",
        "key": "parsed_event_hour",
      },
      {
        "title": "Evento",
        "key": "parsedEventName",
      },
      {
        "title": "Id Inspection",
        "key": "inspectionId"
      }
    ];

  public static readonly Payments: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Nome",
        "key": "name"
      },
      {
        "title": "Cognome",
        "key": "surname"
      },
      {
        "title": "Email",
        "key": "email"
      },
      {
        "title": "Data richiesta pagamento",
        "key": "parsed_date"
      },
      {
        "title": "",
        "key": "button_name"
      }
    ];

  public static readonly CompanyCredits: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "N. Rilevaizoni",
        "key": "n_credits"
      },
      {
        "title": "Data Emissione",
        "key": "parsed_date"
      },
      {
        "title": "Operatore",
        "key": "operator_name"
      },

    ];


  public static readonly Inspections: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Tipologia",
        "key": "type_string" 
      },
      {
        "title": "Data creazione",
        "key": "parsed_creation_date",
        "sort": 2
      },
    //  {
    //    "title": "Data di arrivo",
    //    "key": "parsed_closing_date"
    //  },
      {
        "title": "Stato",
        "key": "state_string"
      },
      {
        "title": "Targa vettura",
        "key": "license_plate"
      },
      {
        "title": "Company",
        "key": "company_string"
      },
      {
        "title": "Indirizzo",
        "key": "address"
      }
    ]

    public static readonly ClaimInspections: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Tipologia",
        "key": "type_string" 
      },
      {
        "title": "Data rilevazione",
        "key": "parsed_closing_date",
        "sort": 2
      },
      {
        "title": "Data Invio a perito",
        "key": "parsed_commitment_date",
        "sort": 1
      },      
      {
        "title": "Targa vettura",
        "key": "license_plate"
      },
      {
        "title": "Company",
        "key": "company_string"
      },
      {
        "title": "Perito",
        "key": "adjuster_full_name"
      }
    ]

    
    public static readonly ClaimedInspections: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Tipologia",
        "key": "type_string" 
      },
      {
        "title": "Data rilevazione",
        "key": "parsed_closing_date",
        "sort": 2
      },
      {
        "title": "Data perizia",
        "key": "parsed_completion_date",
        "sort": 1
      },      
      {
        "title": "Targa vettura",
        "key": "license_plate"
      },
      {
        "title": "Company",
        "key": "company_string"
      },
      {
        "title": "Perito",
        "key": "adjuster_full_name"
      }
    ]


    public static readonly ScheduledInspectionsAssigned: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Tipologia",
        "key": "type_string" 
      },
      {
        "title": "Data appuntamento",
        "key": "parsed_schedule_date",
        "sort": 2
      },
      {
        "title": "Targa vettura",
        "key": "license_plate"
      },
      {
        "title": "Indirizzo",
        "key": "address"
      },
      {
        "title": "Company",
        "key": "company_string"
      },
    ]


    public static readonly ScheduledInspections: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Tipologia",
        "key": "type_string" 
      },
      {
        "title": "Data appuntamento",
        "key": "parsed_schedule_date",
        "sort": 2
      },
      {
        "title": "Data di arrivo",
        "key": "parsed_closing_date"
      },
      {
        "title": "Stato",
        "key": "state_string"
      },
      {
        "title": "Targa vettura",
        "key": "license_plate"
      },
      {
        "title": "Indirizzo",
        "key": "address"
      }
    ]



  public static readonly UserProfileInspections: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Tipologia",
        "key": "type_string"
      },
      {
        "title": "Presa in carico",
        "key": "creation_date"
      },
      {
        "title": "Completata",
        "key": "parsed_arrive_date"
      },
      {
        "title": "Scade tra",
        "key": "state_string"
      },
      {
        "title": "Ricevute",
        "key": "state_string"
      },
      {
        "title": "Targa vettura",
        "key": "license_plate"
      },
      {
        "title": "Indirizzo",
        "key": "address"
      }
    ]



  public static readonly Territory: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Tipologia",
        "key": "type_string"
      },
      {
        "title": "Data di creazione",
        "key": "parsed_creation_date"
      },
      {
        "title": "Data di arrivo",
        "key": "parsed_closing_date"
      },
      {
        "title": "Stato",
        "key": "state_string"
      },
      {
        "title": "Targa vettura",
        "key": "plate"
      }
    ]

  public static readonly CityInspection: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Indirizzo",
        "key": "address"
      },
      {
        "title": "Tipologia",
        "key": "type"
      },
      {
        "title": "Orario appuntamento",
        "key": "parsed_validity_deadline_date"
      },
      {
        "title": "Schedulata",
        "key": "is_scheduled"
      },
      {
        "title": "Timer",
        "key": "timer"
      },

    ];

    public static readonly CityInspectionSchedules: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Company",
        "key": "company_name" 
      },
      {
        "title": "Data appuntamento",
        "key": "parsed_schedule_date",
        "sort": 2
      },
      {
        "title": "Insoorer",
        "key": "schedule_booked_by"
      },
      {
        "title": "Targa vettura",
        "key": "license_plate"
      },
      {
        "title": "Indirizzo",
        "key": "address"
      }
    ];

    public static readonly CityInspectionScheduledNotAssigned: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Company",
        "key": "company_name" 
      },
      {
        "title": "Data appuntamento",
        "key": "parsed_schedule_date",
        "sort": 2
      },
      {
        "title": "Targa vettura",
        "key": "license_plate"
      },
      {
        "title": "Indirizzo",
        "key": "address"
      }
    ];
  
    public static readonly InspectionArchive: any[] =
    [
      {
        "title": "ID",
        "key": "id"
      },
      {
        "title": "Indirizzo",
        "key": "address"
      },
      {
        "title": "Data appuntamento",
        "key": "parsed_closing_date"
      },
      {
        "title": "Targa",
        "key": "license_plate"
      },
      {
        "title": "Company",
        "key": "company_string"
      },
      {
        "title": "Stato",
        "key": "state_string"
      }

    ];

  public static readonly UsersManagement: any[] =
    [
      {
        "title": "Nome",
        "key": "first_name"
      },
      {
        "title": "Cognome",
        "key": "last_name"
      },
      {
        "title": "Email",
        "key": "email"
      },
      {
        "title": "Telefono",
        "key": "phone_number"
      },
    ];

  public static readonly InspectorList: any[] =
    [
      {
        "title": "Nome",
        "key": "surname"
      },
      {
        "title": "Cognome",
        "key": "name"
      },
      {
        "title": "Email",
        "key": "email"
      }
    ];
}
