import { Component, OnInit, OnDestroy } from '@angular/core';
import { TableStructure } from '../../../Helpers/TableStructure';
import { Subscription } from 'rxjs/Rx';
import { LoaderService } from '../../../Ui/loader.service';
import { PaymentService } from '../../../Services/List/Payment/payment.service';
import { PaymentStatus } from '../../../Helpers/Utils';

@Component({
	selector: 'app-payment-accepted',
	templateUrl: './payment-accepted.component.html',
	styleUrls: ['./payment-accepted.component.scss']
})
export class PaymentAcceptedComponent implements OnInit, OnDestroy{
	public tableStructure : any[] = TableStructure.Payments;
	public items : any[] = [];
	public sub : Subscription = new Subscription();
	public meta : any = {};
	public currentPage: number = 0;
	public sortDescending: boolean = true;


	constructor(private loading: LoaderService, private paymentService: PaymentService ) {
		this.getPaymentList()
	}

	getPaymentList(pageNumber = 0, sort = 0) {
		this.loading.displayLoader(true);
		this.sub.add(this.paymentService.getPaymentsList(PaymentStatus.Accepted, pageNumber, sort, this.sortDescending)
		.finally(() => {
			this.loading.displayLoader(false);
		})
		.subscribe(data => {
			this.items = data.payments;
			this.meta = data.meta;
		}));
	}


	nextPage() {
		if(!this.meta.hasNext) return
		this.currentPage+=1
		this.getPaymentList(this.currentPage)
	}

	prevPage() {
		if(!this.meta.hasPrevious) return
		this.currentPage-=1
		this.getPaymentList(this.currentPage)
	}

	goToPage(page: number) {
		this.getPaymentList(page)
	}


	ngOnInit() {
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}


	sortBy(type: number) {
		this.sortDescending = !this.sortDescending
		this.getPaymentList(this.currentPage, type)
	}

}
