import { Inspection } from './Inspection';
import { Services } from './Services';
import { Schedule } from './Schedule';
import { AssociatedReview } from './AssociatedReview';
import { Photo } from './Photo';
import {ContactInfo} from './ContactInfo';
import {AdjusterCommitment} from './AdjusterCommitment';

export class SingleInspectionViewModel {
	constructor(
		public singleInspection: Inspection, 
		public color : any, 
		public manufacturer: any,
		public abort: any,
		public contact_info: ContactInfo,
		public adjuster_commitment: AdjusterCommitment,
		public photo: Photo[],
		public required_services: Services[],
		public optional_services: Services[],
		public abort_service: Services,
		public associated_review: AssociatedReview,
		public schedule: Schedule
	) 
		{}
}
