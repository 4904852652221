import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { Inspector } from '../../../Models/Inspector';
import { UserProfileService } from '../../../Services/User/user-profile.service';
import { Subscription } from 'rxjs/Rx';
import { LoaderService } from '../../../Ui/loader.service';
import { ModalService } from '../../../Helpers/modal.service';
import { Inspection } from '../../../Models/Inspection';
import { TableStructure } from '../../../Helpers/TableStructure';
import { Location } from '@angular/common';
import { DashboardService } from '../../../Services/Dashboard/dashboard.service';
import { PendingInspection } from '../../../Models/PendingInspection';
import * as moment from 'moment';
import { CurrentStatus, InspectorStatus } from '../../../Helpers/Utils';
import { EventDetailModalComponent } from '../../../Components/event-detail-modal/event-detail-modal.component';
import { BusinessEventService } from '../../../Services/BusinessEvents/business_event.service';

@Component({
  selector: 'app-user-state',
  templateUrl: './user-state.component.html',
  styleUrls: ['./user-state.component.scss']
})
export class UserStateComponent implements OnInit, OnDestroy {
  public user: Inspector = new Inspector()
  private sub: Subscription = new Subscription()
  public lat: number = 0;
  public lng: number = 0;
  public zoom: number = 13;
  public total_inspections: number = 0
  public aborted_inspections: number = 0
  public accepted_inspections: number = 0
  public refused_inspections: number = 0
  public inspections: Inspection[] = []
  public tableStructure: any[] = TableStructure.UserProfilePendingInspections;
  public payment: any = {}
  public pendingInspections: PendingInspection[] = []
  public actualStatus: any = {}


  public currentPage: number = 0;
  public events: any[] = []
  public eventsListMeta: any = {}
  public eventsTableStructure: any[] = TableStructure.InspectorEvents;
  public pageSize: number = 8;

  constructor(private modalService: ModalService, private dashboardService: DashboardService, private location: Location, private userProfileService: UserProfileService, private loader: LoaderService, private modal: ModalService, private router: Router, private route: ActivatedRoute, private eventService: BusinessEventService) {
  }

  ngOnInit() {
    this.refreshInspectorData();
  }

  public refreshInspectorData() {
    this.getUser()
    this.getUserStats()
    this.getPendingInspections()
    this.getActualStatus()
    this.getEvents(0)
  }

  public getUser() {
    this.loader.displayLoader(true)
    this.sub.add(
      this.userProfileService.getUser(this.route.parent.params.map(params => params['id']), true)
        .finally(() => {
          this.loader.displayLoader(false)
        })
        .subscribe(data => {
          this.user = data
          this.getUserLocation()
        }, err => {
          this.loader.displayLoader(false)

          if (err.status == 404 || err.status == 500) {
            this.route.parent.params.subscribe(params => {
              this.router.navigateByUrl('home/notfound/inspector/' + params['id']);
            });
          } else {

            if (err.data) {
              let error = err.json().data
              if (error.is_business_error) {
                this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
              } else {
                this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
              }
            } else
              this.modalService.simpleModal(`Errore`, `(${err})`)
          }
          console.log(err)
        })
    )
  }

  private getUserLocation() {
    this.sub.add(
      this.userProfileService.getUserLocationById(this.route.parent.params.map(params => params['id']))
        .finally(() => {
          this.loader.displayLoader(false)
        })
        .subscribe(loc => {
          this.loader.displayLoader(false)
          this.user.availability = loc.location.data.availability;
          this.user.latitude = loc.location.data.latitude;
          this.user.longitude = loc.location.data.longitude;
          this.user.latest_update_time = loc.location.data.time;
          this.user.address = loc.address;
        }, err => {
          this.loader.displayLoader(false)
          if (err.status !== 404)
            this.modal.simpleModal('Si è verificato un errore', 'Si è verificato un errore nel recuperare i dati di posizione dell\'utente')
          console.log(err)
        })
    )
  }

  private getUserStats() {
    this.sub.add(
      this.userProfileService.getUserStats(this.route.parent.params.map(params => params['id']))
        .subscribe(data => {
          this.total_inspections = data.total_inspections
          this.aborted_inspections = data.aborted_inspections
          this.accepted_inspections = data.accepted_inspections
          this.refused_inspections = data.refused_inspections
        }, err => {

        })
    )
  }

  private getLastInspections() {
    this.sub.add(
      this.userProfileService.getUserInspections(this.route.parent.params.map(params => params['id']), 0, 4)
        .subscribe(data => {
          this.inspections = data.inspections
        }, err => {
          console.log(err)
        })
    )
  }

  private getPendingInspections() {
    this.sub.add(
      this.userProfileService.getUserPendingInspections(this.route.parent.params.map(params => params['id']))
        .subscribe(data => {
          console.log("pending inspections", data)
          this.pendingInspections = data
        }, err => {
          console.log(err)
        })
    )
  }

  private getPaymentHistory() {
    this.sub.add(
      this.userProfileService.paymentHistory(this.route.parent.params.map(params => params['id']))
        .subscribe(data => {
          this.payment = data
          console.log("payment", data)
        })
    )
  }

  public get parsedAvailabilityStatus() {
    if (this.user.availability) {
      return InspectorStatus.getStatusAsString(this.user.availability);
    }
    return "Non disponibile";
  }

  public get getAvailability() : string {
    switch (this.user.availability) {
      case InspectorStatus.Available:
        return "online";
      case InspectorStatus.Offline:
        return "offline";
      case InspectorStatus.Inprogress:
      case InspectorStatus.Booked:
        return "working";
      default:
        return "offline";
    }
  }

  public get viewAllLink() {
    return "/home/user/" + this.user.inspector_id + "/inspections";
  }

  public blockUser() {
    this.modal.simpleModal("Si è verificato un errore", "La funzione non è ancora stata implementata")
  }


  public deleteUser() {
    this.modal.confirmDialog("Cancella utente", "Una volta confermato, l’utente sarà ufficialmente cancellato dal sistema, questa operazione non si potrà annullare", () => {
      this.loader.displayLoader(true)
      this.userProfileService.deleteUser(this.route.parent.params.map(params => params['id']))
        .finally(() => this.loader.displayLoader(false))
        .subscribe(data => {
          this.location.back();
        }, err => {
          let error = err.json().data
          if (error.is_business_error) {
            this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
          } else {
            this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
          }
          console.log(err)
        })
    });
  }

  private getActualStatus() {
    this.sub.add(
      this.userProfileService.getActualStatus(this.route.parent.params.map(params => params['id']))
        .subscribe(data => {
          console.log("actual status", data)
          this.actualStatus = data
        }, err => {
          console.log(err)
        })
    )
  }

  public parseDate(unix) {
    if (unix == 0) return "ND"
    return moment.unix(unix).format('DD/MM/YYYY | HH:mm')
  }

  public get currentStatusAsString() {
    return CurrentStatus.getCurrentStatusString(this.actualStatus.current_availability)
  }

  public resetUser() {
    this.modal.confirmDialog("Resettare stato dell'utente?", "In caso affermativo lo stato dell'utente sarà portato da 'occupato' (prenotata/ispezione in corso) a 'disponibile'. Nota che se l'inspector sta svolgendo una rilevazione, resettandone lo stato gli impedirari di proseguire.<br/>Usare solo in caso di utente bloccato", () => {
      this.loader.displayLoader(true)
      this.sub.add(
        this.userProfileService.resetUser(this.route.parent.params.map(params => params['id']))
          .finally(() => {
            this.loader.displayLoader(false)
          })
          .subscribe(data => {
            this.loader.displayLoader(false)
            this.modal.simpleModal("Operazione effettuata", "L'utente è stato resettato con successo");
            this.getUser();
          }, err => {
            this.loader.displayLoader(false)
            let error = err.json().data
            if (error.is_business_error) {
              this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
            } else {
              this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
            }
            console.log(err)
          })
      );
    })
  }

  getEvents(page: number) {
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.sub.add(this.eventService.getInspectorEvents(this.route.parent.params.map(params => params['id']), new Date(), tomorrow, page, this.pageSize)
      .subscribe(data => {
        this.currentPage = data.meta.zeroBasedPageIndex;
        this.events = data.events;
        this.eventsListMeta = data.meta;
      }));
  }

  openDetail(event: any) {
    var eventDetail = this.events.find(e => e.id == event)
    this.modalService.modalWithComponent(EventDetailModalComponent, eventDetail, null);
  }

  nextPage() {
    if (!this.eventsListMeta.hasNext) return
    this.getEvents(this.currentPage + 1)
  }

  prevPage() {
    if (!this.eventsListMeta.hasPrevious) return
    this.getEvents(this.currentPage - 1)
  }

  goToPage(page: number) {
    this.getEvents(page)
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
