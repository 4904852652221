export const environment = {
	production: false,
	client_version: "PROD  v0.1.1",
  api_default_url: "https://backoffice-api.insoore.com/api/",
  api_auth_url: "https://insoore-authserver.azurewebsites.net",
  api_authapi_url: "https://insoore-authserver-api.azurewebsites.net",
	api_version: '3.0',
	google_api_key: "AIzaSyCLqY1DS-QBBPElV9WN7gyjdR0ifjBwvDU",
	client_id: 'be-operator-api',
	client_secret: 'InsooreBack#secret',
	grant_type_login: "password",
	gmap_default_start_point : {
		latitude: 41.900677,
		longitude: 12.504429,
		zoom: 13
	},
	max_users_for_account : 10,
	UseFakes : false,
	signup_url: "https://od.insoore.com/signup"
};
