export class City {
	constructor(
		public id : string = "",
		public ExternalId : string = "",
		public StaffomaticLocationName : string = "",
    public name : string = "...",
    public mappingId: string = "",
		public coord: any = {
			"lat": 0,
			"lng": 0,
			"zoom": 0
		}
	) {}

	 assignToObject(json: any) {
		this.id = json.id
    this.name = json.name
    this.mappingId = json.mapping_id
    this.coord = {
			"lat": json.coordinates.lat,
			"lng": json.coordinates.lng,
			"zoom": json.coordinates.zoom
		}

	}

	static fromJson(json:any) : City {
		let city = new City();
		city.assignToObject(json);
		return city;
	}


	static fromJsonArray(json:any) : City[] {
		console.log(json)
		return json.map(j => {
			return this.fromJson(j)
		})
	}


}



