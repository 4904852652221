import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from "@angular/core";
import { Router } from "@angular/router";
import { City } from "../../../Models/City";
import { Subscription, Observable } from "rxjs/Rx";
import { ValuesService } from "../../../Services/Values/values.service";
import { TableStructure } from "../../../Helpers/TableStructure";
import { CompanyService } from "../../../Services/Company/company.service";
import { LocationStrategy } from "@angular/common";
import { Inspector } from "../../../Models/Inspector";
import { ActivatedRoute } from "@angular/router";
import { LoaderService } from "../../../Ui/loader.service";
import { Inspection } from "../../../Models/Inspection";
import {
  Convert,
  Export,
  InspectionCompany,
  SummarizedInspectionStatus,
  InspectionStatus
} from "../../../Helpers/Utils";
import * as moment from "moment";

@Component({
  selector: "app-inspection-deleted",
  templateUrl: "./inspection-deleted.component.html",
  styleUrls: ["./inspection-deleted.component.scss"]
})
export class InspectionDeletedComponent implements OnInit {
  lat: number = 0;
  lng: number = 0;
  private map: any;
  zoom: number = 0;
  public tableStructure: any[] = TableStructure.InspectionArchive;
  public items: any[] = [];
  public meta: any = {};
  public inspectors: Inspector[] = [];
  public inspections: Inspection[] = [];
  sub: Subscription = new Subscription();
  public cities: City[] = [];
  public style: any[] = [];
  public currentCity: string = "";
  public id: string;
  public currentPage: number = 0;
  public date: Date;
  public filterActive: boolean = false;
  public dataEdit: any[];
  public type = "-1";
  public dateFrom = "";
  public dateTo = "";
  public cost: number = 0;
  public state = "";
  public totalItem: number = 0;
  public counterItem: number = 0;

  @Output() applyFilters = new EventEmitter();

  constructor(
    private loading: LoaderService,
    private changeDetector: ChangeDetectorRef,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private valuesService: ValuesService,
    private router: Router,
    private url: LocationStrategy
  ) {
    this.refreshUi();
  }

  ngOnInit() {
    this.getInspection(0);
  }

  apply(applyFilterActiveUpdate: boolean = true) {
    this.applyAllFilters(this.date, this.state, applyFilterActiveUpdate);
  }

  applyAllFilters(date, state, applyFilterActiveUpdate: boolean = true) {
    if (applyFilterActiveUpdate) this.filterActive = true;
    let filters = {
      date: date,
      state: state
    };

    this.saveFiltersToQueryString(filters);
    this.applyFilters.emit(filters);
  }

  getInspection(pageNumber: number = 0) {
    this.loading.displayLoader(true);
    this.sub.add(
      this.companyService
        .getAllInspectionList(
          pageNumber,
          this.state,
          this.dateFrom,
          this.dateTo,
          2,
          true
        )
        .finally(() => {
          //todo
          this.loading.displayLoader(false);
        })
        .subscribe(
          data => {
            this.inspections = data.inspections;
            this.meta = data.meta;
          },
          err => {
            //todo
            console.error(err);
          }
        )
    );
  }

  saveFiltersToQueryString(filters: any) {
    let currentRoute = this.url.path().split("?")[0];
    this.router.navigate([currentRoute], {
      queryParams: { date: filters.date, state: filters.state }
    });
  }

  refreshUi() {
    this.sub.add(
      Observable.timer(1000, 1000).subscribe(val => {
        // this.inspections = this.inspections.filter()
        this.changeDetector.detectChanges();
      })
    );
  }

  resetFilter() {
    this.filterActive = false;
    this.date = undefined;
    this.cities = undefined;
    this.state = undefined;
    this.apply(false);
  }

  public get detail_url(): string {
    let url = "../inspection/";
    return url;
  }

  checkButtonDisable() {
    if (this.date && this.currentCity != "") {
      let from = moment(this.date).unix();
      return true;
    } else return false;
  }

  updateFilters(event) {
    console.log(event);
    this.type = event.type;
    this.dateFrom = event.dateFrom;
    this.dateTo = event.dateTo;
    this.state = event.state;
    this.getInspection(this.currentPage);
  }

  nextPage() {
    if (!this.meta.hasNext) return;
    this.currentPage += 1;
    this.getInspection(this.currentPage);
  }

  prevPage() {
    if (!this.meta.hasPrevious) return;
    this.currentPage -= 1;
    this.getInspection(this.currentPage);
  }

  goToPage(page: number) {
    this.getInspection(page);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  downloadExcel() {
    this.loading.displayLoader(true);
    var pages = 0;
    this.dataEdit = [{}];
    this.sub.add(
      this.companyService
        .getFullInspectionList(
          "",
          pages,
          this.state,
          this.type,
          this.dateFrom,
          this.dateTo,
          2,
          true,
          ""
        )
        .subscribe(data => {
          data.inspections.forEach(item => {
            this.counterItem++;
            this.totalItem = data.inspections.length;

            let dataInizio;
            /*	if(item['schedule_date'] != null){
				dataInizio = Convert.unixTimestampToLocalDateString(item['schedule_date'])
			} else*/
            dataInizio = Convert.unixTimestampToLocalDateString(
              item["creation_date"]
            );

            let myItem = {
              Id: item["id"],
              //	"Data inizio": dataInizio,
              //"Data invio": Convert.unixTimestampToLocalDateString(item['closing_date']),
              "Data rilevazione": Convert.unixTimestampToLocalDateString(
                item["closing_date"]
              ),
              "Data Perizia": Convert.unixTimestampToLocalDateString(
                item["completion_date"]
              ),
              "Data invio a perito": Convert.unixTimestampToLocalDateString(
                item["commitment_date"]
              ),
              Targa: item["license_plate"],
              Stato: InspectionStatus.getInspectionStatusString(item["state"]),
              //"Costo": item['company_cost'],
              Company: InspectionCompany.getCompany(item["company_id"]),
              Indirizzo: item["address"],
              Insoorer: item["inspector_id"]
            };
            this.dataEdit.push(myItem);
            //console.log(this.dataEdit.length);
            //		console.log(counter)
            /*if(this.counterItem==999){
					pages=pages+1;
					this.getInspectionsRequest(pages)
				}*/
            //	console.log(data.inspections)
          });

          if (this.counterItem == this.totalItem) {
            this.loading.displayLoader(false);
            Export.exportAsExcelFile(this.dataEdit);
          }

          /*	if(counter == 999 && data.meta.totalPages!=pages){

				for (let i = 1; data.meta.totalPages<pages+1; i++) { 
					this.getInspectionsRequest(pages)
			 }
			 
			 Export.exportAsExcelFile(this.dataEdit);

			} else {
				Export.exportAsExcelFile(this.dataEdit);
			}*/
          //	pages++;
          //	counter=0;
          //	this.getInspectionsRequest(pages)
          //this.loading.displayLoader(false);
          //	} else if(counter>0 && data.meta.totalPages!=pages){

          //	}

          //  if(pages > 0){
          //  for (let i = 1; i<pages+1; i++) {
          //console.log("prova"+i);
          // this.getInspectionsRequest(i);
          // }
          // } else {
          //  Export.exportAsExcelFile(this.dataEdit);
          //  this.loading.displayLoader(false);
          // }
        })
    );

    // Export.exportAsExcelFile(this.dataEdit);
    // this.loading.displayLoader(false);
  }

  /*   getInspectionsRequest(page:number){
	    this.sub.add(this.companyService.getFullInspectionList("", page, this.state, this.type, this.dateFrom, this.dateTo, 2, true,"").subscribe((data) => {
       
       data.inspections.forEach(item => {  
				this.counterItem++;
       let myItem = {
         "Data": Convert.unixTimestampToLocalDateString(item['creation_date']),
         "Targa": item['license_plate'],
         "Costo": item['company_cost'],
         "Company": InspectionCompany.getCompany(item['company_id']),
         "Indirizzo":item['address']
       }
			 this.dataEdit.push(myItem);
			 
			 if(counterItem==999){
				page=page+1;
				this.getInspectionsRequest(page)
			}

       });

       if(data.meta.totalPages == page){
          Export.exportAsExcelFile(this.dataEdit);
          this.loading.displayLoader(false);
        }
       
     }));
     
    }*/
}
