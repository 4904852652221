import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs/Rx";
import { CompanyService } from "../../../Services/Company/company.service";
import { LoaderService } from "../../../Ui/loader.service";
import { TableStructure } from "../../../Helpers/TableStructure";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Company } from "../../../Models/Company";
import { ModalService } from "../../../Helpers/modal.service";
import { DashboardService } from "../../../Services/Dashboard/dashboard.service";

@Component({
  selector: "app-company-profile",
  templateUrl: "./company-profile.component.html",
  styleUrls: ["./company-profile.component.scss"]
})
export class CompanyProfileComponent implements OnDestroy {
  public items: any[] = [];
  public sub: Subscription = new Subscription();
  public meta: any = {};
  public tableStructure: any[] = TableStructure.CompanyCredits;
  public company: Company = new Company();
  public id: string = "";
  public creditToAdd: number = 0;
  public credits: number = 0;
  public currentPage: number = 0;
  public operators: any[] = [];
  addUserForm: FormGroup;

  constructor(
    public dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private router: Router,
    private loading: LoaderService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.getCompanyData();
    this.getCreditHistory(this.currentPage);
    this.getCompanyCredits();
    this.initializeForm();
  }

  ngOnInit() {
    this.getOperatorsData();
  }

  getCompanyData() {
    this.loading.displayLoader(true);
    this.sub.add(
      this.companyService
        .getCompany(this.route.parent.params.map(params => params["id"]))
        .finally(() => this.loading.displayLoader(false))
        .subscribe(
          model => {
            this.company = model;
            this.id = model.id;
          },
          err => {
            if (err.status == 404 || err.status == 500) {
              this.route.parent.params.subscribe(params => {
                this.router.navigateByUrl(
                  "home/notfound/company/" + params["id"]
                );
              });
            } else {
              /*  let error = err.json().data
            if (error.is_business_error) {
              this.modalService.simpleModal(`Si è verificato un errore (${error.error_code})`, `${error.error_message}`)
            } else {
              this.modalService.simpleModal(`Errore (${error.error_code})`, "Si è verificato un errore")
            } */
            }
          }
        )
    );
  }

  private performDelete(idUser: string) {
    this.loading.displayLoader(true); // enable spinner
    this.sub.add(
      this.dashboardService
        .deleteUserCompany(idUser, this.id)
        .finally(() => {
          this.loading.displayLoader(false); // disable spinner
        })
        .subscribe(
          data => {
            this.modalService.simpleModal(
              "Utente rimosso",
              "L'utente è stato rimosso con successo"
            );
            this.getOperatorsData();
          },
          err => {
            this.modalService.simpleModal(
              "Si è verificato un errore",
              "Si è verificato un errore durante l'elimnazione dell'utente"
            );
          }
        )
    );
  }

  public deleteCompanyUser(idUser: string) {
    this.modalService.confirmDialog(
      "Eliminazione utente",
      "Sei sicuro di voler eliminare questo utente?",
      () => {
        this.performDelete(idUser);
      }
    );
  }

  private getCompanyCredits() {
    this.loading.displayLoader(true);
    this.sub.add(
      this.companyService
        .getCompanyCredits(this.route.parent.params.map(params => params["id"]))
        .finally(() => {
          this.loading.displayLoader(false);
        })
        .subscribe(
          data => {
            this.credits = data;
          },
          err => {
            console.log(err);
          }
        )
    );
  }

  private getOperatorsData(): void {
    this.loading.displayLoader(true);
    this.companyService
      .getOperatorsList(this.route.parent.params.map(params => params["id"]))
      .finally(() => this.loading.displayLoader(false))
      .subscribe(data => {
        console.log(data.operators);
        this.operators = data.operators;
      });
  }

  nextPage() {
    if (!this.meta.hasNext) return;
    this.currentPage += 1;
    this.getCreditHistory(this.currentPage);
  }

  prevPage() {
    if (!this.meta.hasPrevious) return;
    this.currentPage -= 1;
    this.getCreditHistory(this.currentPage);
  }

  goToPage(page: number) {
    this.getCreditHistory(page);
  }

  add() {
    // this.creditToAdd = this.credit;
    this.modalService.confirmDialog(
      "Conferma",
      "Confermando verranno aggiunti " +
        this.creditToAdd +
        " crediti. Vuoi confermare?",
      () => {
        this.loading.displayLoader(true);
        this.sub.add(
          this.companyService
            .addCredits(
              this.route.parent.params.map(params => params["id"]),
              this.creditToAdd
            )
            .finally(() => {
              this.loading.displayLoader(false);
              this.getCreditHistory(this.currentPage);
            })
            .subscribe(
              data => {
                this.modalService.simpleModal(
                  "Operazione eseguita con successo",
                  "Sono stati aggiunti " +
                    this.creditToAdd +
                    " crediti all'azienda"
                );
                this.loading.displayLoader(false);
              },
              err => {
                var errorDetail = JSON.parse(err._body);
                this.modalService.simpleModal(
                  "Si è verificato un Errore",
                  "Si è verificato un errore. <br/><b>Codice: " +
                    errorDetail.data.error_code +
                    "</b><br/>" +
                    errorDetail.data.error_message
                );
                console.log(err);
              }
            )
        );
      }
    );
  }

  addUserCompany() {
    this.loading.displayLoader(true); // enable spinner
    this.sub.add(
      this.dashboardService
        .addUserCompany(
          this.addUserForm.value.name,
          this.addUserForm.value.surname,
          this.addUserForm.value.phone,
          this.addUserForm.value.email,
          this.id
        )
        .finally(() => {
          this.loading.displayLoader(false); // disable spinner
        })
        .subscribe(
          data => {
            this.modalService.simpleModal(
              "Utente creato con successo",
              "L'utente è stato creato."
            );
            this.getOperatorsData();
            this.initializeForm();
          },
          err => {
            let error = err.json().data;
            this.loading.displayLoader(false); // disable spinner
            if (error.is_business_error) {
              this.modalService.simpleModal(
                "Si è verificato un errore",
                error.error_message
              );
            } else {
              this.modalService.simpleModal(
                "Si è verificato un errore",
                error.error_code
              );
            }
          }
        )
    );
  }

  initializeForm() {
    this.addUserForm = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required])],
      surname: ["", Validators.compose([Validators.required])],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      phone: ["", Validators.compose([Validators.required])]
    });
  }

  getCreditHistory(page: number) {
    this.loading.displayLoader(true);
    this.sub.add(
      this.companyService
        .getCreditHistory(
          this.route.parent.params.map(params => params["id"]),
          page,
          10
        )
        .finally(() => {
          this.loading.displayLoader(false);
        })
        .subscribe(data => {
          this.loading.displayLoader(false);
          this.items = data.history;
          this.meta = data.meta;
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public setRole(id: string, roleType: string) {
    console.log(roleType);
    if (this.dashboardService.operator.isPermitted("CompaniesEdit")) {
      this.modalService.confirmDialog(
        "Sei sicuro di voler cambiare il ruolo di questo utente?",
        "Premi Conferma per continuare",
        () => {
          this.loading.displayLoader(true);
          this.sub.add(
            this.companyService
              .setRole(
                this.route.parent.params.map(params => params["id"]),
                id,
                roleType
              )
              .subscribe(
                data => {
                  this.loading.displayLoader(false);
                  this.modalService.simpleModal(
                    "Ruolo utente cambiato",
                    "Il ruolo dell'utente è stato cambiato con successo"
                  );
                  location.reload();
                },
                err => {
                  this.loading.displayLoader(false);
                  let error = err.json().data;
                  if (error.is_business_error) {
                    this.modalService.simpleModal(
                      `Si è verificato un errore (${error.error_code})`,
                      `${error.error_message}`
                    );
                  } else {
                    this.modalService.simpleModal(
                      `Errore (${error.error_code})`,
                      "Si è verificato un errore"
                    );
                  }
                  console.log(err);
                }
              )
          );
        }
      );
    } else {
      this.modalService.simpleModal(
        "Errore",
        "Non sei autorizzato ad eseguire questa operazione"
      );
    }
  }
}
