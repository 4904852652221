	import * as moment from 'moment';

export class Template {
	constructor(
		public id: string = "...",
		public name : string = "...",
		public description : string = "...",
		public price: string = "...",
		public inspector_revenue: string = "...",
public request_type: number = 0,
		public is_enabled_by_default: boolean = true,
		public can_be_scheduled: boolean = true,
        public allowed_services: any[] = []
	) {}

	assignToObject(json: any) {
		this.id = json.id;
		this.name = json.name
		this.description = json.description
		this.price = json.price
    this.inspector_revenue = json.inspector_revenue
    this.request_type =  json.request_type
    this.can_be_scheduled = json.can_be_scheduled;
    	this.allowed_services = json.allowed_services?json.allowed_services:[]
		//this.is_enabled_by_default = json.allowed_services.is_enabled_by_default?json.allowed_services:[]
	}

	static fromJson(json:any) : Template {
		let template = new Template();
		template.assignToObject(json);
		return template;
	}

	static fromJsonArray(json: any) : Template[] {
		return json.map(j => {
			return this.fromJson(j)
		})
	}

/*	public get button_name() {
		return this.roleId==1?"Rendi Master":"MASTER"
	}

	public get role_name() {
		return this.roleId==1?"Operatore":"Master"
	}

	public isPermitted(grant: string): boolean {
		if(this.available_grants.find(o => o.value === grant)) {
			return true
		}
		return false
	}*/

}
