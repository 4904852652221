import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs/Rx';
import { Summary } from '../../Models/Summary';

@Injectable()
export class CompanyMock {

	public static readonly CREATE_RESPONSE:any = {
		"code": 204,
		"data" : {}
	}

	public static readonly SINGLE_COMPANY: any = {
		"code" : 200,
		"data": {
			"id" : "851r3qij",
			"company_name" : "BaasBox srl",
			"main_account" : "Matteo Errera",
			"phone" : "06650000000",
			"email" : "m.errera@baasbox.com",
			"stats" : {
				"created_reports" : 453,
				"accepted_reports" : 23,
				"expired_reports" : 18,
				"rejected_reports" : 12
			}
		}
	}

	public static readonly CREDIT_HISTORY: any = {
		"code" : 200,
		"data" : {
			"history" : [
				{
					"id" : "9tuejiqa",
					"n_credits" : 1000,
					"emission_date" : "2017-07-27T15:19:21.000",
					"operator_name" : "Ivo Avido"
				},
				{
					"id" : "52qwre",
					"n_credits" : 550,
					"emission_date" : "2017-07-25T15:19:21.000",
					"operator_name" : "Pino Cammino"
				},
				{
					"id" : "5wrassa",
					"n_credits" : 700,
					"emission_date" : "2017-07-22T15:19:21.000",
					"operator_name" : "Salvo Errori"
				},
				{
					"id" : "6etdasa",
					"n_credits" : 900,
					"emission_date" : "2017-07-21T15:19:21.000",
					"operator_name" : "Maicol Sole"
				}
			],
			"currentPage" : 1,
			"hasNext" : false,
			"hasPrev" : false,
			"recordsPerPage": 10,
			"totalRecords" : 2,
			"totalPages" : 1
		}
	}

	public static readonly INSPECTION_TO_APPROVE_LIST: any = {
		"code" : 200,
		"data" : [
			{
				"id" : "sa0fijk21",
				"type" : 0,
				"creation_date" : "2017-07-21T15:19:21.000",
				"arrive_date" : null,
				"state" : 0,
				"vehicleDescription" : {
					"plate" : "BS921AA"
				}
			},
			{
				"id" : "2p1rjqwij",
				"type" : 0,
				"creation_date" : "2017-07-21T18:19:21.000",
				"arrive_date" : null,
				"state" : 0,
				"vehicleDescription" : {
					"plate" : "BS921AA"
				}
			},
			{
				"id" : "52qrwas12",
				"type" : 0,
				"creation_date" : "2017-07-21T19:19:21.000",
				"arrive_date" : null,
				"state" : 0,
				"vehicleDescription" : {
					"plate" : "BS921AA"
				}
			},
			{
				"id" : "63qtwaRqw2",
				"type" : 0,
				"creation_date" : "2017-07-21T20:19:21.000",
				"arrive_date" : null,
				"state" : 0,
				"vehicleDescription" : {
					"plate" : "BS921AA"
				}
			}
		],
		"currentPage" : 1,
		"hasNext" : false,
		"hasPrev" : false,
		"recordsPerPage": 10,
		"totalRecords" : 2,
		"totalPages" : 1
	}

	public static readonly SINGLE_INSPECTION_RESPONSE : any = {
		"code" : 200,
		"data": {
			"id": "DJS0y88XXo7O",
			"vehicleDescription": {
				"manufacturer": 0,
				"plate": "BS000EL",
				"color": 0
			},
			"latitude": 45.0,
			"longitude": 12.0,
			"state": 1,
			"creation_date": "2017-06-14T07:56:42.587Z",
			"arrive_date": "2017-06-14T08:03:11.253Z",
			"validityDeadlineDate": "2017-06-14T09:56:42.587Z",
			"mediaUploadDeadlineDate": "2017-06-14T13:56:42.587Z",
			"requesterId": "192e85c4-b75f-4903-a75f-cbd5ad5dc39c",
			"type": 0,
			"isBooked": false,
			"booking": null,
			"company_name" : "Baasbox srl",
			"operator_name" : "Matteo Errera",
			"insoorer" : "Luca Errera",


			"associatedInspection": {
				"requiredPhotos": {
					"rear-left": {
						"name": "BackLeft",
						"notShootReasonId": null,
						"photoType": "Required",
						"latitude": 0.0,
						"longitude": 0.0,
						"photoDateTime": "2017-06-14T09:51:23",
						"uploadDateTime": "2017-06-14T08:04:41.18",
						"wasUploaded": true,
						"hashMd5": "54E02669601885A18DB5463992D2C929",
						"description": null,
						"wasShoot": true,
						"inspectionId": "InspectionRequests.Domain.InspectionRequests.Inspections.InspectionId"
					},
					"front-left": {
						"name": "FrontLeft",
						"notShootReasonId": null,
						"photoType": "Required",
						"latitude": 0.0,
						"longitude": 0.0,
						"photoDateTime": "2017-06-14T09:51:23",
						"uploadDateTime": "2017-06-14T08:04:28.903",
						"wasUploaded": true,
						"hashMd5": "54E02669601885A18DB5463992D2C929",
						"description": null,
						"wasShoot": true,
						"inspectionId": "InspectionRequests.Domain.InspectionRequests.Inspections.InspectionId"
					},
					"rear-right": {
						"name": "BackRight",
						"notShootReasonId": 2,
						"photoType": "Required",
						"latitude": 0.0,
						"longitude": 0.0,
						"photoDateTime": null,
						"uploadDateTime": null,
						"wasUploaded": false,
						"hashMd5": null,
						"description": null,
						"wasShoot": false,
						"inspectionId": "InspectionRequests.Domain.InspectionRequests.Inspections.InspectionId"
					},
					"front-right": {
						"name": "FrontRight",
						"notShootReasonId": 3,
						"photoType": "Required",
						"latitude": 0.0,
						"longitude": 0.0,
						"photoDateTime": null,
						"uploadDateTime": null,
						"wasUploaded": false,
						"hashMd5": null,
						"description": null,
						"wasShoot": false,
						"inspectionId": "InspectionRequests.Domain.InspectionRequests.Inspections.InspectionId"
					},
					"top": {
						"name": "LatTop",
						"notShootReasonId": 1,
						"photoType": "Required",
						"latitude": 0.0,
						"longitude": 0.0,
						"photoDateTime": null,
						"uploadDateTime": null,
						"wasUploaded": false,
						"hashMd5": null,
						"description": null,
						"wasShoot": false,
						"inspectionId": "InspectionRequests.Domain.InspectionRequests.Inspections.InspectionId"
					},
					"right": {
						"name": "LatRight",
						"notShootReasonId": 2,
						"photoType": "Required",
						"latitude": 0.0,
						"longitude": 0.0,
						"photoDateTime": null,
						"uploadDateTime": null,
						"wasUploaded": false,
						"hashMd5": null,
						"description": null,
						"wasShoot": false,
						"inspectionId": "InspectionRequests.Domain.InspectionRequests.Inspections.InspectionId"
					},
					"left": {
						"name": "LatLeft",
						"notShootReasonId": null,
						"photoType": "Required",
						"latitude": 0.0,
						"longitude": 0.0,
						"photoDateTime": "2017-06-14T09:51:23",
						"uploadDateTime": "2017-06-14T08:04:34.997",
						"wasUploaded": true,
						"hashMd5": "54E02669601885A18DB5463992D2C929",
						"description": null,
						"wasShoot": true,
						"inspectionId": "InspectionRequests.Domain.InspectionRequests.Inspections.InspectionId"
					},
					"front": {
						"name": "FrontCenter",
						"notShootReasonId": null,
						"photoType": "Required",
						"latitude": 0.0,
						"longitude": 0.0,
						"photoDateTime": "2017-06-14T09:51:23",
						"uploadDateTime": "2017-06-14T08:03:48.913",
						"wasUploaded": true,
						"hashMd5": "54E02669601885A18DB5463992D2C929",
						"description": null,
						"wasShoot": true,
						"inspectionId": "InspectionRequests.Domain.InspectionRequests.Inspections.InspectionId"
					},
					"rear": {
						"name": "BackCenter",
						"notShootReasonId": null,
						"photoType": "Required",
						"latitude": 0.0,
						"longitude": 0.0,
						"photoDateTime": "2017-06-14T09:51:23",
						"uploadDateTime": "2017-06-14T08:04:44.78",
						"wasUploaded": true,
						"hashMd5": "54E02669601885A18DB5463992D2C929",
						"description": null,
						"wasShoot": true,
						"inspectionId": "InspectionRequests.Domain.InspectionRequests.Inspections.InspectionId"
					}
				},
				"contextPhotos": {
					"context-1": {
						"name": "context-1",
						"notShootReasonId": null,
						"photoType": "Context",
						"latitude": 0.0,
						"longitude": 0.0,
						"photoDateTime": "2017-06-14T09:51:23",
						"uploadDateTime": "2017-06-14T08:05:06.107",
						"wasUploaded": true,
						"hashMd5": "54E02669601885A18DB5463992D2C929",
						"description": "An optional description text for the image",
						"wasShoot": true,
						"inspectionId": "InspectionRequests.Domain.InspectionRequests.Inspections.InspectionId"
					}
				},

			},
			"requestRejectionReason": null,
			"address": "Via Marsala 29/h",
			"comments": "Questa è una prova"
		},
		"meta": {}

	}

	public static readonly PHOTO_URL : any = {
		"data" : {
			"url" : "http://magal.li/i/500/500"
		}
	}

	public static readonly NOT_SHOOTED_REASON_LIST : any = {
		"data":[{"id":1,"value":"There is not enough space/distance to take the picture"},{"id":2,"value":"There is one or more obstacles in between"},{"id":3,"value":"Vehicle side is near a wall"},{"id":99,"value":"Other"}],"meta":{}
	}

}
