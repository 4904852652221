import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs/Rx";
import { PaymentStatus } from "../../../Helpers/Utils";
import { PaymentsMock } from "../../Mock/Payments";
import { Payment } from "../../../Models/Payment";
import { NetworkingService } from "../../Networking/networking.service";

@Injectable()
export class PaymentService {
  constructor(private networking: NetworkingService) {}

  getSinglePayment(id: Observable<string>): Observable<Payment> {
    return id.flatMap(id => this.getPaymentById(id));
  }

  getPaymentById(id: string): Observable<Payment> {
    return this.networking
      .get("payments/" + id, this.networking.getAuthenticatedHeader())
      .map(data => {
        return Payment.fromJson(data.data);
      });
    /*
		return Observable.create((observer: Observer<any>) => {
			let payment = PaymentsMock.SINGLE_PAYMENT_RESPONSE;
			observer.next(payment);
			observer.complete();
		}).delay(1500).map(data => {
			return Payment.fromJson(data.data);
		});*/
  }

  getPaymentsList(
    status,
    pageNumber = 0,
    sort = 0,
    sortDescending = true
  ): Observable<any> {
    let data = {
      pageNumber: pageNumber,
      pageSize: 30,
      sortby: sort,
      isToSortDescending: sortDescending
    };
    return this.networking
      .get(
        "payments/search/" + status,
        this.networking.getAuthenticatedHeader(),
        "application/json",
        data
      )
      .map(data => {
        let payments = Payment.fromJsonArray(data.data);
        return {
          payments: payments,
          meta: Payment.getListMeta(data)
        };
      });
    /*
		return Observable.create((observer: Observer<any>) => {
			let payments;

			switch(status) {
				case PaymentStatus.ToApprove:
				payments = PaymentsMock.PAYMENT_LIST_TOAPPROVE_RESPONSE;
				break;
				case PaymentStatus.InPending:
				payments = PaymentsMock.PAYMENT_LIST_PENDING_RESPONSE;
				break;
				case PaymentStatus.Accepted:
				payments = PaymentsMock.PAYMENT_LIST_ACCEPTED_RESPONSE;
				break;
				case PaymentStatus.Rejected:
				payments = PaymentsMock.PAYMENT_LIST_REJECTED_RESPONSE;
				break;
			}
			observer.next(payments);
			observer.complete();
		}).delay(1500).map(data => {
			let payments = Payment.fromJsonArray(data.data.payments)
			return {
				"payments" : payments,
				"meta" : Payment.getListMeta(data)
			}
		});*/
  }

  acceptPayment(id: string): Observable<any> {
    return this.networking.post(
      "payments/accept/" + id,
      {},
      this.networking.getAuthenticatedHeader()
    );
  }

  bookPayment(id: string): Observable<any> {
    return this.networking.put(
      "payments/book/" + id,
      {},
      this.networking.getAuthenticatedHeader()
    );
  }

  rejectPayment(id: string, reason: string): Observable<any> {
    return this.networking.post(
      "payments/refuse/" + id + "?rejectionReason=" + reason,
      {},
      this.networking.getAuthenticatedHeader()
    );
  }
}
