import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { StoreItemService } from '../../store-item.service';
import { AuthService } from '../../Services/Authentication/auth.service';
import { Router } from '@angular/router';
import { ModalService } from '../../Helpers/modal.service';
import { LoaderService } from '../../Ui/loader.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
	private sub : Subscription = new Subscription();
	loginForm : FormGroup;
	private error: string;

	constructor(private authService: AuthService, private formBuilder:FormBuilder, private router: Router, private modalService: ModalService, private loading: LoaderService) {
		this.loginForm = formBuilder.group({
			email: ['', Validators.compose([Validators.required, Validators.email])],
			password: ['', Validators.compose([Validators.required])]
		});
	}

	login() {
		this.loading.displayLoader(true);
		this.sub.add(this.authService.login(this.loginForm.value.email, this.loginForm.value.password)
		.finally(() => this.loading.displayLoader(false))
		.subscribe(data => {
			//redirect to dashboard
			localStorage.setItem('access_token', data.access_token);
			this.router.navigateByUrl('home');
		}, err => {
			console.log(err);
			this.modalService.simpleModal("Errore", "Username o password errati")
		}));

	}

	keyDownFunction(event) {
	  if(event.keyCode == 13) {
		  this.login();
	  }
	}

	ngOnInit() {
		//swallow
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}


	}
