import { Injectable } from '@angular/core';
import { NetworkingService } from '../Networking/networking.service';
import { Observable } from 'rxjs/Rx';
import { Inspector } from '../../Models/Inspector';
import { CityInspection } from '../../Models/CityInspection';
import { CityInspectionSchedules } from '../../Models/CityInspectionSchedules';
import { DatePipe } from '@angular/common';

@Injectable()
export class TerritoryService {

  constructor(private networking: NetworkingService) { }

  getOnlineUserByCityId(id:string) : Observable<any> {
	  let data = {
		  "cityId" : id
	  }
	  return this.networking.get('cities/inspector', this.networking.getAuthenticatedHeader(), 'application/json', data)
	  .map(data => {
		  return Inspector.fromJsonArray(data.data)
	  })
  }

  getSessionByCity(id:string) : Observable<any> {
	 let data = {
		 "cityId" : id
	 }
	 return this.networking.get('cities/inspections', this.networking.getAuthenticatedHeader(), 'application/json', data)
	 .map(data => {
		console.log("123"+data.data);
		 return CityInspection.fromJsonArray(data.data)
	 })
  }


getScheduledByCity(id:string, date:string, pageNumber:number) : Observable<any> {
	
	let data = {};
	var today = new Date();
	var datePipe = new DatePipe("en-US");
	var formattedDate = "";
	
	if(date==""){
		let tomorrow = today.setDate(today.getDate() + 1);
		formattedDate = datePipe.transform(tomorrow, 'MM-dd-yyyy');
	} else {
	
		formattedDate = datePipe.transform(Date.parse(date+"T09:00:00"), 'MM-dd-yyyy');
	}
	
	return this.networking.get('requests/scheduled/' + id+"/"+formattedDate+"?pageNumber="+pageNumber+"&pageSize=100", this.networking.getAuthenticatedHeader(), 'application/json', data)
	.map(data => {
		
		return CityInspectionSchedules.fromJsonArray(data.data)
	})
}
 
}
