import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs/Rx';
import { UserMock } from '../Mock/UserMock';
import { Notification } from '../../Models/Notification';
import { NetworkingService } from '../Networking/networking.service';

@Injectable()
export class UserService {

	constructor(private networking: NetworkingService) { }

	getNotificationList(page: number, recordsPerPage: number) {
		let data = {
			"pageNumber" : page,
			"pageSize" : recordsPerPage,
		}

		return this.networking.get('notifications', this.networking.getAuthenticatedHeader(), 'application/json', data)
		.map(data => {
			let notifications = Notification.fromJsonArray(data.data)
			return {
				"notifications" : notifications,
				"meta" : Notification.getListMeta(data)
			}
		})
		/*
		return Observable.create((observer: Observer<any>) => {
			let notifications = UserMock.NOTIFICATION_LIST_RESPONSE;
			observer.next(notifications);
			observer.complete();
		}).delay(1500).map(data => {
			let notifications = Notification.fromJsonArray(data.data.notifications);
			return {
				"notifications" : notifications,
				"meta" : Notification.getListMeta(data)
			};
		});
		*/
	}

}