import { InspectorStatus , InspectionType } from '../Helpers/Utils';
import * as moment from 'moment';

export class CityInspectionSchedules {
	constructor(
		public id : string = "",
		public license_plate : string = "",
		public latitude: string = "",
		public longitude: string = "",
		public is_booked: string = "",
		public company: string = "",
		public request_type: number = 0,
		public schedule_date_number:number = 0,
		public schedule_date: string = "",
		public assigned_to: string = "",
		public address: string = ""
	) {}


	static fromJson(json:any) : CityInspectionSchedules {
		let inspection = new CityInspectionSchedules();
		inspection.id = json.id
		inspection.license_plate = json.vehicle_description.license_plate
		inspection.latitude = json.latitude
		inspection.company = json.company_name
		inspection.longitude = json.longitude
		inspection.is_booked = json.is_schedule_booked ? "Prenotata" : "Non prenontata";
		inspection.request_type = json.request_type
		inspection.schedule_date_number = json.schedule_date
		inspection.schedule_date = (moment.unix(json.schedule_date).format('DD/MM/YYYY | HH:mm')).toString();
		inspection.assigned_to = json.schedule_assigned_to
		inspection.address = json.address
		return inspection;
	}

	static fromJsonArray(json:any) : CityInspectionSchedules[] {
		return json.map(j => {
			return this.fromJson(j)
		})
	}

	public get type() {
		return InspectionType.getInspectionTypeString(this.request_type)
	}

	public get timer() {
		let deadline = this.schedule_date_number
		let now = Math.round(new Date().getTime() / 1000)
		return moment.utc((deadline-now)*1000).format('HH:mm:ss');
	}

	public get isPassed() {
		let deadline = this.schedule_date_number
		let now = Math.round(new Date().getTime() / 1000)
		return deadline<now
	}

	static getListMeta(json: any) {
		let meta = {
		  "zeroBasedPageIndex": json.meta.zeroBasedPageIndex,
		  "hasNext": json.meta.hasNext,
		  "hasPrevious": json.meta.hasPrevious,
		  "totalPages": json.meta.totalPages
		}
		return meta
	  }
}
