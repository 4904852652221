export class Operator {
	constructor(
		public id: string = "...",
		public first_name : string = "...",
		public last_name : string = "...",
		public phone_number: string = "...",
		public email: string = "...",
    public role: string = "...",
    public roleId: number = 0,
    public profileType: number = 0,
		public editBlocked: boolean = true,
		public available_grants: any[] = []
	) {}

	assignToObject(json: any) {
		this.id = json.id;
		this.first_name = json.first_name
		this.last_name = json.last_name
		this.phone_number = json.phone_number
    this.email = json.email
    this.roleId =  json.role
    this.role =  json.grant_profile?json.grant_profile.name:''
    this.profileType = json.profile_type;
		this.available_grants = json.grant_profile?json.grant_profile.available_grants_list:[]
	}

	static fromJson(json:any) : Operator {
		let operator = new Operator();
		operator.assignToObject(json);
		return operator;
	}

	static fromJsonArray(json: any) : Operator[] {
		return json.map(j => {
			return this.fromJson(j)
		})
	}

	public get button_name() {
		return this.roleId==1?"Rendi Master":"MASTER"
	}

	public get role_name() {
		let role:string;
		
		if(this.roleId==1)
			role = "Operatore";
		if(this.roleId==2)
			role = "Master";
		else if(this.roleId==3)
			role = "Supervisor";

		return role
	}

	public isPermitted(grant: string): boolean {
		if(this.available_grants.find(o => o.value === grant)) {
			return true
		}
		return false
	}

}
