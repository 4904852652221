export class Photo {
	constructor(
		public id : string,
		public name : string,
		public url : string,
		public was_uploaded : boolean,
	//	public was_shoot : boolean,
		public latitude: number,
		public longitude: number,
		public photo_date_time: number,
		public description: string,
		public notShootedReasonId: number,
		public service_id: string
	) {}
}
