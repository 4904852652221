import * as moment from "moment";
import { InspectorStatus, InspectorRegistrationStatus } from "../Helpers/Utils";
export class Inspector {
  constructor(
    public id: string = "",
    public inspector_id: string = "",
    public email: string = "",
    public first_name: string = "",
    public last_name: string = "",
    public name: string = "",
    public surname: string = "",
    public phone_number: string = "",
    public status = "",
    public availability = "",
    public latitude: number = 0,
    public longitude: number = 0,
    public latest_update_time: number = 0,
    public address: string = ""
  ) {}

  static fromJson(json: any): Inspector {
    let inspector = new Inspector();
    inspector.id = json.id || json.inspector_id;
    inspector.inspector_id = json.inspector_id;
    inspector.email = json.email;
    inspector.first_name = json.first_name;
    inspector.last_name = json.last_name;
    inspector.name = json.name;
    inspector.surname = json.surname;
    inspector.phone_number = json.phone_number || json.phone;
    inspector.availability = json.availability;
    inspector.latitude = json.latitude;
    inspector.longitude = json.longitude;
    inspector.latest_update_time = json.latest_update_time;
    inspector.status = json.current_state;
    return inspector;
  }

  public get parsed_latest_update_time(): string {
    if (this.latest_update_time > 0)
      return moment.unix(this.latest_update_time).format("DD/MM/YYYY | HH:mm");

    return "Non disponibile";
  }

  static fromJsonArray(json: any): Inspector[] {
    return json.map(j => {
      return this.fromJson(j);
    });
  }

  public get icon() {
    if (this.availability == InspectorStatus.Available) {
      return "assets/available.png";
    }
    return "assets/unavailable.png";
  }

  public get isInAPossbileStuckStatus(): boolean {
    return (
      this.status == InspectorRegistrationStatus.Registered &&
      (this.availability == InspectorStatus.Inprogress ||
        this.availability == InspectorStatus.Booked)
    );
  }
  public get parsedRegistrationStatus() {
    return InspectorRegistrationStatus.getStatusAsString(this.status);
  }

  public get parsed_address() {
    if (this.address) return this.address;

    return "ND";
  }
}
