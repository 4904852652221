import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs/Rx';
import { User } from '../../Models/User';
import { AuthMock } from '../Mock/Auth';
import { Router } from '@angular/router';
import { NetworkingService, Endpoints } from '../Networking/networking.service';
import { environment } from '../../../environments/environment';
import { ModalService } from 'app/Helpers/modal.service';


@Injectable()
export class AuthService {

  constructor(private router: Router, private networking: NetworkingService,private modalService: ModalService) { }

  login(username: string, password: string): Observable<any> {
    let data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);
    data.append('grant_type', environment.grant_type_login);
    data.append('client_id', environment.client_id);
    data.append('client_secret', environment.client_secret);
    return this.networking.post("/connect/token", data.toString(), [], 'application/x-www-form-urlencoded', Endpoints.auth)
  }

  restorePassword(email: string): Observable<any> {
    var data = {
      "email": email,
      "userType": 3
    }
    return this.networking.put("/api/Account/requestResetPassword", data, [], 'application/json', Endpoints.authapi).map(response => {
      return response;
    });
  }

  firstLogin(name: string, surname: string, email: string, password: string): Observable<any> {
    var data = {
      "name": name,
      "surname": surname,
      "email": email,
      "password": password
    }

    return this.networking.post("operators/firstLogin", data, [], 'application/json', Endpoints.default)
      .flatMap(data => {
        this.modalService.simpleModal("Attenzione!","Se si tratta della prima registrazione in assoluto sulla piattaforma insoore ricordati di confermare la registrazione tramite link nella mail ricevuta, altrimenti non sarà possibile effettuare l'accesso")
        return this.login(email, password);
      });


  }

}
