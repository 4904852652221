import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs/Rx";
import { TableStructure } from "../../../Helpers/TableStructure";
import { CompanyService } from "../../../Services/Company/company.service";
import {
  InspectionStatus,
  SummarizedInspectionStatus
} from "../../../Helpers/Utils";
import { LoaderService } from "../../../Ui/loader.service";
import { Route, ActivatedRoute } from "@angular/router";
import { ModalService } from "../../../Helpers/modal.service";

@Component({
  selector: "app-inspection-opened",
  templateUrl: "./inspection-opened.component.html",
  styleUrls: ["./inspection-opened.component.scss"]
})
export class InspectionOpenedComponent implements OnInit, OnDestroy {
  public tableStructure: any[] = TableStructure.Inspections;
  public items: any[] = [];
  public sub: Subscription = new Subscription();
  public id: string;
  public meta: any = {};
  public currentPage: number = 0;

  public type;
  public dateFrom = "";
  public dateTo = "";
  public sortDescending: boolean = true;

  constructor(
    private loading: LoaderService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  getInspectionList(page: number = 0, sort = 2) {
    this.loading.displayLoader(true);
    console.log(this.dateFrom);
    this.sub.add(
      this.companyService
        .getInspectionList(
          this.route.parent.params.map(params => params["id"]),
          page,
          SummarizedInspectionStatus.Opened,
          this.type,
          this.dateFrom,
          this.dateTo,
          sort,
          this.sortDescending,
          ""
        )
        .subscribe(
          data => {
            this.loading.displayLoader(false);
            this.items = data.inspections;
            this.meta = data.meta;
          },
          err => {
            this.loading.displayLoader(false);
            let error = err.json().data;
            if (error.is_business_error) {
              this.modalService.simpleModal(
                `Si è verificato un errore (${error.error_code})`,
                `${error.error_message}`
              );
            } else {
              this.modalService.simpleModal(
                `Errore (${error.error_code})`,
                "Si è verificato un errore"
              );
            }
          }
        )
    );
  }

  updateFilters(event) {
    this.type = event.type;
    this.dateFrom = event.dateFrom;
    this.dateTo = event.dateTo;
    this.getInspectionList(this.currentPage);
  }

  public get detail_url(): string {
    let url = "../inspection/";
    return url;
  }

  nextPage() {
    if (!this.meta.hasNext) return;
    this.currentPage += 1;
    this.getInspectionList(this.currentPage);
  }

  prevPage() {
    if (!this.meta.hasPrevious) return;
    this.currentPage -= 1;
    this.getInspectionList(this.currentPage);
  }

  sortBy(type: number) {
    this.sortDescending = !this.sortDescending;
    this.getInspectionList(this.currentPage, type);
  }

  goToPage(page: number) {
    this.getInspectionList(page);
  }

  ngOnInit() {
    this.getInspectionList();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
